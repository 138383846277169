<mat-card>
  
  <div class="row" style="margin-bottom: 1%;align-items: center;">
  <h2>Employee Nomination Details</h2>
  </div>
    <div class="row" style="margin-bottom: 1%;">
        <div class="col-md-4">
            <!-- <form [formGroup]="datepickerFormFromDate"> -->
            <div class="col-md-4 col-lg-4 col-sm-12" > 
              <!-- <mat-form-field appearance="outline" style="width: 300%;" ngDefaultControl> -->
                <div class="row col-md-12 col-lg-12 col-sm-12" > 
                  <div class="col-md-8 col-lg-8 col-sm-12" > 
                    <mat-label>FromDate</mat-label>
                    <input class="txtClass" matInput placeholder="dd/mm/yyyy"  [matDatepicker]="picker2">
                  
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12" > 
                    <mat-datepicker-toggle matSuffix [for]="picker2" style="margin-left: 50px;"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                </div>

                
              <!-- </mat-form-field> -->
            </div>
          <!-- </form> -->
          </div>
          <div class="col-md-4">
            <div class="col-md-4 col-lg-4 col-sm-12"> 
             <div class="row col-md-12 col-lg-12 col-sm-12" > 
                  <div class="col-md-8 col-lg-8 col-sm-12" > 
                    <mat-label>ToDate</mat-label>
                    <input class="txtClass" matInput placeholder="dd/mm/yyyy"  [matDatepicker]="picker10" >
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12" > 
                    <mat-datepicker-toggle matSuffix [for]="picker10" style="margin-left: 50px;"></mat-datepicker-toggle>
                    <mat-datepicker #picker10></mat-datepicker>
                  </div>
                </div>
                
            </div>  
          </div>
          <div class="col-md-4">
            <div class="col-md-4 col-lg-4 col-sm-12"> 
              <div class="row col-md-12 col-lg-12 col-sm-12" > 
                   <div class="col-md-12 col-lg-12 col-sm-12" > 
                     <mat-label>EmployeeCode</mat-label>
                     <input class="txtClass" matInput placeholder="EmployeeCode"  >
                   </div>
                   
                 </div>
                 
             </div>  
          </div>
    </div>
    <div class="row" style="margin-bottom: 1%;margin-top: 1%;">
      <div class="col-md-4">
        <div class="col-md-4 col-lg-4 col-sm-12"> 
          <div class="row col-md-12 col-lg-12 col-sm-12" > 
               <div class="col-md-12 col-lg-12 col-sm-12" > 
                 <mat-label>Business area</mat-label>
                <!--  <input class="txtClass" matInput placeholder="Business area"  > -->
                <mat-form-field appearance="fill">
                  <mat-label>select Business area</mat-label>
                  <select matNativeControl required>
                    <option value=""></option>
                    <option value="Test">Test</option>
                    <option value="Test1">Test1</option>
                    <option value="Test2">Test2</option>
                  </select>
                </mat-form-field>
                
               </div>
               
             </div>
             
         </div> 
      </div>
      <div class="col-md-4">
        <div class="col-md-4 col-lg-4 col-sm-12"> 
          <div class="row col-md-12 col-lg-12 col-sm-12" > 
               <div class="col-md-12 col-lg-12 col-sm-12" > 
                 <mat-label>Type Of Form</mat-label>
                 
                 <!--  <input class="txtClass" matInput placeholder="Business area"  > -->
                 <mat-form-field appearance="fill">
                   <mat-label>select Type Of Form</mat-label>
                   <select matNativeControl required>
                     <option value="PF">PF</option>
                     <option value="Gratuity">Gratuity</option>
                     <option value="Insurance">Insurance</option>
                     <option value="SA">SA</option>
                   </select>
                 </mat-form-field>
               </div>
               
             </div>
             
         </div> 
      </div>
      <div class="col-md-4">
        <button class="buttonABB">Search</button>
      </div>
    </div>
    <div class="row">      
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"  class="table tblabb" style="margin: 0px auto;">
             
          <ng-container matColumnDef="Id">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Id </th>
            <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.Id}} </td>
          </ng-container>
        
          <ng-container matColumnDef="EmployeeCode">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Employee Code </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; text-decoration: underline; color: blue; cursor: pointer;"> {{element.EmployeeCode}} </td>
          </ng-container>
        
          <ng-container matColumnDef="EmployeeName" >
            <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;"> Employee Name </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;"> {{element.EmployeeName}} </td>
          </ng-container>
      
          <ng-container matColumnDef="Status" >
            <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;"> Status </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;"> {{element.Status}} </td>
          </ng-container>
      
          <ng-container matColumnDef="SubmittedDate">
            <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;"> Submitted Date </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;"> {{element.SubmittedDate}} </td>
          </ng-container>     
              
      
          <tr class="transactionHeader" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="transactionBody"  mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">No data matching the filter ""</td>
          </tr>
        </table>
      </div><br>


</mat-card>