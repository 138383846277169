import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  MasterService } from 'src/app/Services/master.service';

import { Editor, Toolbar, toDoc } from 'ngx-editor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface PreambleMaster{
  iD: number;
  topic  : string;
  remarks  : string;
  status : boolean;
  isActive: boolean;
  category  : string;
  createdBy : number; 
  createdDate : Date;
  modifiedBy : number; 
  modifiedDate : Date;
}

@Component({
  selector: 'app-preamble',
  templateUrl: './preamble.component.html',
  styleUrls: ['./preamble.component.scss']
})
export class PreambleComponent implements OnInit {

  
  isIdVisible: boolean = false;
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['topic','remarks','status','action'];
  safeHtmlContent: SafeHtml;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, 
    private entityServ : MasterService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(); 
    this.getPreambleData();
  }
  openDialogBox(){
    this.isIdVisible = false;
    const dialogRef = this.dialog.open(AddPreambleDialog,{
      height: '600px',
      width: '550px',
    
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getPreambleData();
        }
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }

  getPreambleData(){
    this.entityServ.getPreambleData().subscribe((data:any)=>{
      this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(data);
      this.dataSource= new MatTableDataSource<PreambleMaster>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddPreambleDialog,{
     data,
     height: '600px',
      width: '550px',
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
       if(val){
         this.getPreambleData();
       }
     }
   })
 }

  delete(id: any) {  
    if (confirm("Are you sure you want to delete this")) {  
    this.entityServ.deletePreambleMaster(id).subscribe(() => {  
      this.snackBarLoader('Deleted Successfully','Done','1000', 'red-snackbar')
      this.getPreambleData();
    });  
   }  
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}




@Component ({
  selector: 'app-dialog-entity',
  templateUrl: './addPreambleDialog.html',
  styleUrls: ['./preamble.component.scss']
})

  export class AddPreambleDialog implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    createdByUserId : number;
    createdBy : number;
    createdDate : Date;
    preambleModel: PreambleMaster = {} as PreambleMaster;
    isSubmitted: boolean = false; 
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddPreambleDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private entityServ : MasterService) 
    {
      this.formInstance = _formBuilder.group({
        "remarks":['', Validators.required],
        "topic": ['', Validators.required],
        "status": true
      }); 
    }
    toolbar: Toolbar = [
      ['bold', 'italic'],
      ['underline'],
      ['ordered_list', 'bullet_list']
    ];
    topicEditor: Editor;
    remarksEditor: Editor;
   
  ngOnInit(): void {
    this.topicEditor = new Editor();
    this.remarksEditor = new Editor();

    this.preambleModel.status = true;
    if(this.data !=null && this.data !=undefined)
    {
      this.preambleModel.iD = this.data.preambleId;
      this.preambleModel.remarks = this.data.remarks;
      this.preambleModel.topic = this.data.topic;
      this.preambleModel.category = this.data.category;
      this.preambleModel.status = this.data.status;
      this.createdBy = this.data.createdBy;
      this.createdDate = this.data.createdDate;
      this.isIdVisible = true
    }
    var emailId = sessionStorage.getItem('LoginEmail');
    this.getUserId(emailId);
  }

  closepopup(){
    if(confirm("Are you sure to close this")) {
      this.dialogRef.close();
    }
  }
  save(){
    this.isSubmitted = true;
    if (this.formInstance.invalid) {
      return;
    }
    if(this.preambleModel.topic=="<p></p>"){
      return ;
    }
    if(this.preambleModel.remarks=="<p></p>"){
      return ;
    }
    this.preambleModel.createdBy = this.createdByUserId;
    this.entityServ.SavePreambleData(this.preambleModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  

  update(){
    this.isSubmitted = true;
    if (this.formInstance.invalid) {
      return;
    }
    if(this.preambleModel.topic=="<p></p>"){
      return ;
    }
    if(this.preambleModel.remarks=="<p></p>"){
      return ;
    }
    this.preambleModel.modifiedBy = this.createdByUserId;
    this.preambleModel.createdBy = this.createdBy;
    this.preambleModel.createdDate = this.createdDate;
    this.preambleModel.isActive = true;
    this.entityServ.SavePreambleData(this.preambleModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  resetForm() {  
    this.formInstance.reset(); 
    this.preambleModel.iD = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
  
  getUserId(emailId : string){
    this.entityServ.getUserId(emailId).subscribe((data:any)=>{
      this.createdByUserId = data[0].id;
    });
  }
}