<mat-card>
    <div > 
      <h4 class="col-md-8 Head" style="font-family: ABBvoice;">Designation Mapping</h4>
      <button mat-raised-button class="col-md-4 dialogButton" 
            type="submit" (click)="openDialogBox()" style="font-family: ABBvoice;" *ngIf="showAdd">Add</button>
    </div>



    <form class="ex-form" [formGroup]="formInstance">
      <div   class="content">
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12">
              <form class="example-form">
                <mat-form-field appearance="outline"> 
                  <mat-label style="font-family: ABBvoice;">Select Entity</mat-label>           
                  <mat-select required style=" font-family: ABBvoice;" [formControl]="selectEntityFormControl">
                    <mat-option *ngFor="let entity of entityList" [value]="entity" (click)="entitySelection(entity.id)">{{entity.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Business Area</mat-label>
            <mat-select required style="font-family: ABBvoice;" [disabled]="disableBusinessArea">  <!-- (selectionChange)="selectedValue($event)" -->
              <mat-select-trigger>
                <span *ngIf="showTrigger"> {{selectedBusinessAreaValue}} 
              </span>
              <span *ngIf="!showTrigger" style="color: grey;"> Select Business Area
              </span> </mat-select-trigger>
              <div class="select-container">
               
                  <mat-option value="Select All" (click)="businessAreaAllSelection()">Select All</mat-option>
                  <mat-option *ngFor="let business of businessList" [value]="business.id" (click)="businessAreaSelection(business.id, business.name)" >{{business.name}}</mat-option>
                
               
              </div>
              </mat-select>
              </mat-form-field>
            </div>
              
                  <div class="col-md-3 col-lg-4 col-sm-12">
                    <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="retrieve()">Retrieve</button> 
                    </div>  
          </div>
      </div>
    </form>


</mat-card>


<mat-card > 
  
        <div class="col-md-3 col-lg-3 col-sm-12">
          <div mat-dialog-actions >
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input [(ngModel)]="searchValueText">
              </mat-form-field>
            </div>
        </div>
    
      <div class="table-container">
        <div class="table-responsive">
       
         <table mat-table class="mat-elevation-z8" [dataSource]="dataSource" #empTbSort="matSort"
           class="table tblabb" style="margin: 0px auto;" matSort>

            <ng-container matColumnDef="id" >
                <th class="transactionHeader" mat-header-cell [hidden]="show" *matHeaderCellDef mat-sort-header>Id</th>
                <td class="transactionBody" mat-cell [hidden]="show" *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
          
            <ng-container matColumnDef="designationName">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Designation Name</th>
            <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.designationName }}</td>
            </ng-container>
       
            <ng-container matColumnDef="entityName">
                <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</th>
                <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.entityName }}</td>
            </ng-container>
        
            <ng-container matColumnDef="businessAreaName">
                <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Business Area Name</th>
                <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.businessAreaName }}</td>
            </ng-container>
            
           <ng-container matColumnDef="divisionName">
             <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Division Name </th>
             <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.divisionName }}</td>
           </ng-container>

           <ng-container matColumnDef="status">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.status ? 'Active' : 'InActive' }}</td>
          </ng-container>
    
            <ng-container matColumnDef="action">
              <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 20px;">Action</th>
              <td class="transactionBody" mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button >
                  <mat-icon mat-icon-button color='primary' (click)="loadResourceToEdit(element)" >edit</mat-icon>
                </button>
                <button mat-icon-button >
                  <mat-icon mat-icon-button color="warn" *ngIf='element.isDelete' (click)="delete(element.id)">delete</mat-icon>
                </button>
              </td>
            </ng-container>
  
           <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
           <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
     
           <tr class="mat-row" *matNoDataRow>
             <td class="mat-cell" colspan="4" style="font-family: ABBvoice;">No data matching the filter </td>
           </tr>
         </table>
         <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" aria-label="Select page of Business Resource"></mat-paginator>
        </div>
      </div>
  </mat-card>