/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
 */


import { FormControl } from "@angular/forms";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  constructor(public dialogRef: MatDialogRef<PopupComponent>,@Inject(MAT_DIALOG_DATA) public data: any ) 
  {
    
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  SourceActivity = new FormControl('');
  SourceActivityList: string[] = ['CAT + BAT'
    , 'Country specific', 'CAT + Country specific'];


    cpReference = new FormControl('');
    cpReferenceList: string[] = ['CAT + BAT'
      , 'Country specific', 'CAT + Country specific'];
  


      miReference = new FormControl('');
      miReferenceList: string[] = ['CAT + BAT'
        , 'Country specific', 'CAT + Country specific'];
    

        brReference = new FormControl('');
        brReferenceList: string[] = ['CAT + BAT'
          , 'Country specific', 'CAT + Country specific'];

          

  division = new FormControl('');
  divisionList: string[] = ['MOSE', 'MOIM', 'MOTR', 'MOSD', 'MOLM'];


  designations = new FormControl('');
  designationsList: string[] = ['Local SCM Manager', 'Central Supply Chain Manager', 'Local Division Controller', 'Local Division Manager', 'Local Division Sales Manager'];

  
}