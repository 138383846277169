
<h4 class="Head" style="font-family: ABBvoice;">Preamble</h4>
   
<div>
 <form class="example-form" [formGroup]="formInstance">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label>Id</mat-label>            
             <input matInput type="text" [(ngModel)]='preambleModel.iD'
             placeholder="Ex. Id" formControlName="iD">
         </mat-form-field>
     </div>   
     <div class="form-group row">
      <div class="col-md-8">
        <label for="topic" class="Head1" style="font-family: ABBvoice;">Topic *</label>
        <ngx-editor-menu [editor]="topicEditor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor [editor]="topicEditor" [(ngModel)]="preambleModel.topic" 
            formControlName="topic" [placeholder]="'Topic'"></ngx-editor>
            <div *ngIf="((formInstance.get('topic').invalid && !formInstance.get('topic').value )|| (this.preambleModel.topic=='<p></p>')) && 
          (formInstance.get('topic').dirty && formInstance.get('topic').touched || isSubmitted)"
          class="text-danger">
                Topic is required.
            </div>
      </div>
    </div>
<br>
    <div class="form-group row">
      <div class="col-md-8">
        <label for="remarks" class="Head1" style="font-family: ABBvoice;">Remarks *</label>
        <ngx-editor-menu [editor]="remarksEditor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor [editor]="remarksEditor" [(ngModel)]="preambleModel.remarks" formControlName="remarks"
          [placeholder]="'Remarks'"></ngx-editor>
          <div *ngIf="((formInstance.get('remarks').invalid && !formInstance.get('remarks').value )|| (this.preambleModel.remarks=='<p></p>')) && 
          (formInstance.get('remarks').dirty && formInstance.get('remarks').touched || isSubmitted)"
          class="text-danger">
          Remarks is required.
        </div>
      </div>
    </div><br>
     <div class="row">
      <div class="col-md-6">
        <mat-checkbox formControlName="status" [(ngModel)]='preambleModel.status'>
        <span style="font-family: ABBvoice;">Active</span> 
        </mat-checkbox>                   
      </div>  
     </div>
     <div class="row button">
       <div mat-dialog-actions align="center" class="action" >
         <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
          type="submit">Save</button>
         <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
          type="submit">Update</button>
         <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
       </div>
     </div>
  <br><br>
 </form> 
</div>


