import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule} from '@angular/common/http';
import { map } from 'rxjs/operators'
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface TableData {
  Id: number;
  FirstName : string;
  LastName : string;
  Email : string;
  Mobile : string;
  ShareType:string;
  CountryCode:string;
  IsActive:boolean;
  CreatedBy: number;
  CreatedDate:Date;
}

@Injectable({
  providedIn: "root"
})

export class ApiservicesService {
  rooturl= environment.rooturl ;

//  rooturl = 'https://localhost:7063/';   
 //  rooturl = 'https://dev.table-of-authority.in.abb.com/api/';  
//   rooturl = 'https://qa.table-of-authority.in.abb.com/api/';  
 // rooturl = 'https://stage.table-of-authority.in.abb.com/api/'; 





 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private http: HttpClient, private _snackBar:MatSnackBar) {

  }



  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));

 }
  gettoken(userName:any,password:any){
    var data = JSON.stringify ( {
      "username": userName,
      "password": password
    });


    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });

    return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
  }

  GetABBLogin(email:any)
  {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
   });
    return this.http.post(this.rooturl + 'api/Login/authenticate?email='+ email , {headers: reqHeader});
  }

 
  TableData: TableData[] = [];

  private requestDetails = new BehaviorSubject<any>({
        requestID: 0,
        ToMeetWith: '' ,
        MeetingMode: '',
        MeetingPurpose: '',
        MtgDateOpt1: null,
        MtgDateOpt2: null,
        MtgDateOpt3: null,

  });

  setRequestDetailsInfo(user: any) {
    this.requestDetails.next(user);
  }

  getRequestDetailsInfo() {
    return this.requestDetails.asObservable();
  }

  setVisitorDetailsInfo(user: any) {
    this.TableData.push(user);
  }
  getVisitorDetailsInfo() {
    return this.TableData;
  }

  ShowSnackBar(data: any,color: any)
  {
    this._snackBar.open(data,'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000,
      panelClass: [color]
  });

}

}   