import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ActivityMaster } from '../admin/activity-master/activity-master.component';
//import { Observable } from 'rxjs/internal/Observable';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityMasterService {
  rooturl= environment.rooturl ;
  // rooturl = 'https://localhost:7063/';    
    //rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
   // rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
   //  rooturl = 'https://stage.table-of-authority.in.abb.com/api/';   


    filterParams: { parameterName: string; parameterValue: any; }[];
    
    constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }

    createAuthorizationHeader(headers: HttpHeaders) {
      headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
     
   }
    gettoken(userName:any,password:any){
      var data = JSON.stringify ( {
        "username": userName,
        "password": password
      });
      
      console.log(data);
      var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
      return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
    }
    getEntityList(): Observable<any> {
      return this.http.get(this.rooturl + 'api/TOASearch/GetEntityList');
    } 
    getActivityList(busIDs): Observable<any> {
      if(busIDs=="")
        {
         busIDs=0
        }
      return this.http.get(this.rooturl + 'api/ActivityMaster/GetActivityList?busIDs='+ busIDs);
    }

    
    saveActivityData(data: ActivityMaster): Observable<any> {
      return this.http.post(this.rooturl + 'api/ActivityMaster/SaveActivityData',data);
    } 
   
    getActivityMaster (){
      return this.http.get(this.rooturl + 'api/ActivityMaster/GetActivityMaster');
    }
  
    deleteActivityMaster(data: ActivityMaster): Observable<any>{
      return this.http.post(this.rooturl + 'api/ActivityMaster/DeleteActivityMaster',data);
     }
  
     getUserId(emailId : string){
      return this.http.get(this.rooturl + 'api/ActivityMaster/GetUserDetails?emailId=' + emailId);
     }


/*****************************  Mapping Screen Data ******************************/


getBuisnessAreaList(entityID): Observable<any> {
  return this.http.get(this.rooturl + 'api/TOASearch/GetBuisnessAreaList?entityId='+entityID);
} 

getSearchActivityList(busIDs): Observable<any> {
  if(busIDs=="")
    {
     busIDs=0
    }
  return this.http.get(this.rooturl + 'api/ActivityMaster/GetActivityNotMappedWithList?busIDs='+ busIDs);
}


addActivityToBusinessArea(myData :any): Observable<any> {
  
  return this.http.post(this.rooturl + 'api/ActivityMaster/AddActivityToBusinessArea',myData);
   
} 

deleteActivityNode(myData :any): Observable<any> {
  
  return this.http.post(this.rooturl + 'api/ActivityMaster/DeleteActivityDataFromMapping',myData);
   
} 
getActivityHistoryTempTRNCount(): Observable<any> {
  
  return this.http.get(this.rooturl + 'api/ActivityDataMapping/GetActivityHistoryTempTRNCount');
   
} 

getActivityDetailsDraftData(myData :any): Observable<any> {
  
  return this.http.post(this.rooturl + 'api/ActivityDataMapping/GetActivityDetailsDraftData',myData);
   
} 
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ShowSnackBar(data: any,color: any)
  {
    this._snackBar.open(data,'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000,
      panelClass: [color]
    });
  }
/*****************************  Mapping Screen Data ******************************/
}
