
<h4 class="Head" style="font-family: ABBvoice;">Business Area Master</h4>
   
<div>
 <form class="example-form" [formGroup]="formInstance">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label style="font-family: ABBvoice;">Id</mat-label>            
             <input matInput type="text" [(ngModel)]='businessAreaModel.id'
             placeholder="Ex. Id" formControlName="id">
         </mat-form-field>
     </div>   
     <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label style="font-family: ABBvoice;">Business Area Name</mat-label>
          <input matInput type="text" [(ngModel)]='businessAreaModel.name'
                 placeholder="Ex. Name" required formControlName="name" [errorStateMatcher]="customErrorStateMatcher" style="font-family: ABBvoice;">
          <mat-error *ngIf="formInstance.get('name').hasError('specialCharacters')">Name should not contain special characters</mat-error>
          <mat-error *ngIf="formInstance.get('name').invalid && formInstance.get('name').hasError('required') && !formInstance.get('name').hasError('specialCharacters')">Name is required</mat-error>
        </mat-form-field>
      </div><br>
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
           <mat-label style="font-family: ABBvoice;">Business Area Code</mat-label>           
           <input matInput type="text" [(ngModel)]='businessAreaModel.code' [disabled]="isCodeDisabled"
            placeholder="Ex. Code" required formControlName="code" style="font-family: ABBvoice;" >
           <mat-error *ngIf="formInstance.controls['code'].errors">Code is required</mat-error>
         </mat-form-field>
       </div>   <br>
     </div>
     <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline"> 
              <mat-label style="font-family: ABBvoice;">Entity</mat-label>            
              <mat-select style="font-family: ABBvoice;" [(ngModel)]="businessAreaModel.entityId" 
               formControlName="entityControl" required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let entity of entitylst" [value]="entity.entityId">
                  {{entity.entityName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formInstance.controls['entityControl'].errors">Please choose Entity Name</mat-error>
            </mat-form-field>
        </div><br>
     </div>
     <div class="row">   
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
             <mat-label style="font-family: ABBvoice;">Description</mat-label>           
             <textarea matInput [(ngModel)]="businessAreaModel.description" placeholder="Ex. Description" formControlName="description" style="font-family: ABBvoice;"></textarea>
           </mat-form-field>
       </div>
       <div class="col-md-6">
             <mat-checkbox formControlName="status" [(ngModel)]='businessAreaModel.status'>
             <span style="font-family: ABBvoice;">Active</span> 
             </mat-checkbox>                   
       </div>      
     </div><br>
     
     <div class="row button">
       <div mat-dialog-actions align="center" class="action" >
         <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
          type="submit">Save</button>
         <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
          type="submit">Update</button>
         <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
       </div>
     </div>
  <br><br>
 </form> 
</div>


