import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
/* import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {  FormGroup,  FormBuilder,  Validators,  FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox'; */

export interface EmployeeDetails {
  Id : number;
  EmployeeCode: string;
  EmployeeName: string;  
  Status: string;
  SubmittedDate: Date;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  displayedColumns: string[] = ['EmployeeCode', 'EmployeeName' , 'Status', 'SubmittedDate'];
  dataSource!: MatTableDataSource<EmployeeDetails>;
  rows=[];

  ngOnInit(): void {
    this.onload();
  }

  onload(){
    this.dataSource= new MatTableDataSource<EmployeeDetails>(this.rows);
  }


}
