import { Component, OnInit, Inject, OnDestroy, Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BnNgIdleService } from 'bn-ng-idle';


import { LoadingService, LoadingOverlayRef } from './loading.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  Email : string;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private loadingService: LoadingService,
  private _snackBar: MatSnackBar, private bnIdle: BnNgIdleService,private authService: MsalService) {
    this.bnIdle.startWatching(600).subscribe((res) => {
      if(res) {

        this.Email =  window.atob(sessionStorage.getItem('loginame'));

        if(this.Email.includes('@in.abb.com') || this.Email.includes('abb.com'))
        {
          console.log('logoutABB');
          if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
              postLogoutRedirectUri: "/",
              mainWindowRedirectUri: "/"
            });
          } else {
            this.authService.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          }

          this._snackBar.open("Session Timeout, Please login once again",'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 2 * 1000,
            panelClass: ['red-snackbar']
          });
          setTimeout(() => {
            window.location.reload();  
          }, 500);
        }
        else{

          this._snackBar.open("Session Timeout, Please login once again",'Close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 2 * 1000,
            panelClass: ['red-snackbar']
        });
          setTimeout(() => {
            window.location.reload();  
          }, 500);
        }
      }
    })
  }
   
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loadingRef: LoadingOverlayRef;

    // This is a little hacky and related to change detection (ExpressionChangedAfterItHasBeenCheckedError).
    // More informations here:
    // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4

    Promise.resolve(null).then(() => loadingRef = this.loadingService.open());

    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse && loadingRef) {
        loadingRef.close();
            
      }
    })).pipe(catchError((err) => {
      if (loadingRef) {
        loadingRef.close();
      }

      var tokenExpiration =  sessionStorage.getItem("tokenExpiration");
      // let dateExpiry = this.datepipe.transform(tokenExpiration, 'yyyy-MM-dd HH:mm:ss');
      let newDate1 = new Date(tokenExpiration);
      let currentDate = new Date();
     
        this._snackBar.open("Something Went wrong",'Close', {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          duration: 2 * 1000,
          panelClass: ['red-snackbar']
        });
      
      
        throw err;
      
    }));

    
    
  }
}
