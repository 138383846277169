import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  rooturl= environment.rooturl ;
  // rooturl = 'https://localhost:7063/';  
  // rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
 //  rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
  //   rooturl = 'https://stage.table-of-authority.in.abb.com/api/';   


  filterParams: { parameterName: string; parameterValue: any; }[];
    
  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
   
 }
  gettoken(userName:any,password:any){
    var data = JSON.stringify ( {
      "username": userName,
      "password": password
    });
    
    console.log(data);
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
    return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
  }
  getSearchActivityList(busIDs): Observable<any> {
    if(busIDs=="")
      {
       busIDs=0
      }
    return this.http.get(this.rooturl + 'api/TOASearch/GetActivityList?busIDs='+ busIDs);
  }
  


  getSearchActivityDetails(myData :any): Observable<any> {
  
  return this.http.post(this.rooturl + 'api/TOASearch/GetActivityDetails',myData);
   
} 
  


  getBuisnessAreaList(entityID): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetBuisnessAreaList?entityId='+entityID);
  } 
  getEntityList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetEntityList');
  }  
  getDivisionListFromBusinessAreaID(busIDs): Observable<any> {
    if(busIDs=="")
      {
       busIDs=0
      }
    return this.http.get(this.rooturl + 'api/TOASearch/GetDivisionListFromBusinessAreaID?busIDs='+ busIDs);
  }  
  getDesignationListFromBusinessAreaIDandDivisionID(busIDs,divIDs): Observable<any> {
      if(busIDs=="")
      {
       busIDs=0
      }
      if(divIDs=="")
      {
       divIDs=0
      }

    return this.http.get(this.rooturl + 'api/TOASearch/getDesignationListFromBusinessAreaIDandDivisionID?busIDs='+ busIDs + "&divIDs="+divIDs);
  } 
  generatePdf(activityDTL:any, allBusIDs:any,selectedBusIDs){
    const data={activityDTL,allBusIDs,selectedBusIDs}
    return this.http.post(this.rooturl+ 'api/TOASearch/GeneratePDF',data,{responseType:'blob'});
  }

  generateExcel(){
    return this.http.post(this.rooturl+ 'api/TOASearch/GenerateExcel',{responseType:'blob'});
  }
  getPreambleList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetPreambleList');
  } 
  getReadingThisDocumentList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetReadingThisDocumentList');
  }  
  getNoteList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetNoteList');
  } 
  
  getCurrentVersion(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetCurrentVersion');
  }  
}

