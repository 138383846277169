import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataService {

  rooturl =environment.rooturl;

  // rooturl = 'https://localhost:7063/'; 
   //   rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
   // rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
    // rooturl = 'https://stage.table-of-authority.in.abb.com/api/';  
    
  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
   
 }
  gettoken(userName:any,password:any){
    var data = JSON.stringify ( {
      "username": userName,
      "password": password
    });
    
    console.log(data);
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
   
    return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
  }
  getBuisnessAreaList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/ActivityData/GetBuisnessAreaList');
  } 
  getCategoryList(): Observable<any> {
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));

    return this.http.get(this.rooturl + 'api/ActivityData/GetCategoryList', {headers: headers});
  }
  getActivityMasterDataListFromBusinessIDandCategoryID(busID,catID): Observable<any> {
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));

    return this.http.get(this.rooturl + 'api/ActivityData/GetActivityMasterDataListFromBusinessIDandCategoryID?busID='+busID+ "&catID="+catID, {headers: headers});
  } 
  getDesignationList(busID,catID): Observable<any> {
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));

    return this.http.get(this.rooturl + 'api/ActivityData/GetDesignationListFromBusinessAreaID?busID='+busID+ "&catID="+catID, {headers: headers});
  }
  ActivityDataSave(data:any){
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));
    return this.http.post(this.rooturl + 'Api/ActivityData/ActivityDataSave', data, {headers: headers});
    
  }
  CheckIfRefTextExists(busID,catID,refText){
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));

    return this.http.get(this.rooturl + 'api/ActivityData/CheckIfRefTextExists?busID='+busID+ "&catID="+catID+ "&refText="+refText, {headers: headers});
  }
  deleteActivityData(data): Observable<any>{
    var headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));
      return this.http.post(this.rooturl + 'api/ActivityData/DeleteActivityData',data, {headers: headers});
     }
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ShowSnackBar(data: any,color: any)
  {
    this._snackBar.open(data,'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000,
      panelClass: [color]
    });
  }
}
