import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  MasterService } from 'src/app/Services/master.service';


export interface EntityMaster{
  id: number;
  code  : string;
  name  : string;
  description  : string;
  entityName  : string;
  entityId  : number;
  status : boolean;
  isActive : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
}

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})

export class EntityComponent implements OnInit {

  isIdVisible: boolean = false;
  entitylst : any[] = [];
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['code','name','description','status','action'];
  isCodeDisabled: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, private entityServ : MasterService) { }
  
  ngOnInit(): void {
    this.getEntityMaster();
  }
  openDialogBox(){
    this.isIdVisible = false;
    const dialogRef = this.dialog.open(AddEntityDialog,{
      height: '500px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getEntityMaster();
        }
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  getEntityMaster(){
    this.entityServ.getEntityMaster().subscribe((data:any)=>{
      console.log('dataaa',data)
      this.dataSource= new MatTableDataSource<EntityMaster>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddEntityDialog,{
     data,
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
       if(val){
         this.getEntityMaster();
       }
     }
   })
 }

  delete(id: any) {  
    if (confirm("Are you sure you want to delete this")) {  
    this.entityServ.deleteEntityMaster(id).subscribe(() => {  
      this.snackBarLoader('Deleted Successfully','Done','1000', 'red-snackbar')
      this.getEntityMaster();
    });  
   }  
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}




@Component ({
  selector: 'app-dialog-entity',
  templateUrl: './addEntityDialog.html',
  styleUrls: ['./entity.component.scss']
})

  export class AddEntityDialog implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    createdByUserId : number;
    createdBy : number;
    createdDate : Date;
    isCodeDisabled :boolean =false;
    entityModel: EntityMaster= {} as EntityMaster;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddEntityDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private entityServ : MasterService) 
    {
      if(this.data !=null && this.data !=undefined)
        this.isCodeDisabled = true;
      this.formInstance = _formBuilder.group({
        // "id":0,
        
        "name": ['', [Validators.required, this.noSpecialCharacters()]], 
        "code":[{value:'', disabled : this.isCodeDisabled}, Validators.required],
        "description": [''],
        "status": true
      });
    }

  ngOnInit(): void {
    this.entityModel.status = true;
    if(this.data !=null && this.data !=undefined)
    {
      this.isCodeDisabled = true;
      this.entityModel.id = this.data.entityId;
      this.entityModel.name = this.data.entityName;
      this.entityModel.code = this.data.code;
      this.entityModel.description = this.data.description;
      this.entityModel.status = this.data.status;
      this.createdBy = this.data.createdBy;
      this.createdDate = this.data.createdDate;
      this.isIdVisible = true
    }
    var emailId = sessionStorage.getItem('LoginEmail');
    this.getUserId(emailId);
  }

  closepopup(){
    if(confirm("Are you sure to close this")) {
      this.dialogRef.close();
    }
  }
  save(){
    if (this.formInstance.invalid) {
      return;
    }
    this.entityModel.createdBy = this.createdByUserId;
    this.entityServ.saveEntityData(this.entityModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  

  update(){
    if (this.formInstance.invalid) {
      return;
    }
    this.entityModel.updatedBy = this.createdByUserId;
    this.entityModel.createdBy = this.createdBy;
    this.entityModel.createdDate = this.createdDate;
    console.log('the',this.entityModel)
    this.entityServ.saveEntityData(this.entityModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  resetForm() {  
    this.formInstance.reset(); 
    this.entityModel.id = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
  
  getUserId(emailId : string){
    this.entityServ.getUserId(emailId).subscribe((data:any)=>{
      this.createdByUserId = data[0].id;
    });
  }
  
  noSpecialCharacters(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
      if (specialCharacters.test(control.value)) {
        return { 'specialCharacters': true };
      }
      return null;
    };
  }
  
  customErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }
 
  }