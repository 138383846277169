
    
      <h4 class="col-md-8 Head" style="padding: 0px 0px 14px 14px;font-size: 20px;">
        Activity Master</h4>
   <div>
    <form class="example-form" [formGroup]="formInstance">
        <div hidden="true"  class="col-md-8 col-lg-6 col-sm-8">
            <mat-form-field appearance="outline"> 
                <mat-label>Id</mat-label>            
                <input matInput  type="text" [(ngModel)]='activityModel.activityId' 
                placeholder="Ex. Activityid" formControlName="activityId" >
            </mat-form-field>
        </div>   
        <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
          <mat-form-field appearance="outline"> 
              <mat-label>Id</mat-label>            
              <input matInput type="text" [(ngModel)]='activityModel.parentId'
              placeholder="Ex. ParentId" formControlName="parentId">
          </mat-form-field>
      </div>   
      <div class="row" *ngIf = "ischild">
        <div class="col-md-12"  style="font-family: ABBvoice;">
            <mat-label  style="font-weight:bold;"> Parent Activity: </mat-label>  
            <mat-label  innerHtml="{{activityModel.parentActivityName}}">  </mat-label>          
            
        </div> <br> 
      </div><br><br>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">  
              <mat-label> Activity</mat-label>           
              <textarea matInput type="text"  [(ngModel)]='activityModel.activity'
               placeholder="Ex. Activity" required formControlName="activity" cols="100" rows="5"  required></textarea>
              <mat-error *ngIf="formInstance.controls['activity'].errors">Activity is required</mat-error>
            </mat-form-field>
          </div> <br>
        </div>
        <div class="row">   
          <div class="col-md-6">
                <mat-checkbox formControlName="status" [(ngModel)]='activityModel.status'>
                <span style="font-family: ABBvoice;">Active</span> 
                </mat-checkbox>                   
          </div>      
        </div><br>
        
        <div class="row button">
          <div mat-dialog-actions align="center" class="action" >
            <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
             type="submit">Save</button>
            <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
             type="submit">Update</button>
            <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
          </div>
        </div>
     <br><br>
    </form> 
   </div>
   
 
  