
<h4 class="Head"style="font-family: ABBvoice;">Designation Mapping </h4>
   
<div>
 <form class="example-form" [formGroup]="formInstance">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label>Id</mat-label>            
             <input matInput type="text" [(ngModel)]='designationMappingModel.id'
             placeholder="Ex. Id" formControlName="id">
         </mat-form-field>
     </div>   
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
      <mat-form-field appearance="outline"> 
          <mat-label>Id</mat-label>            
          <input matInput type="text" [(ngModel)]='designationMappingModel.entityId'
          placeholder="Ex. Id" formControlName="entityId">
      </mat-form-field>
  </div>   
  <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
    <mat-form-field appearance="outline"> 
        <mat-label>Id</mat-label>            
        <input matInput type="text" [(ngModel)]='designationMappingModel.businessAreaId'
        placeholder="Ex. Id" formControlName="businessAreaId">
    </mat-form-field>
</div>  
     <div class="row">
      <div class="col-md-8" *ngIf="isSave">
       <mat-form-field appearance="outline"> 
           <mat-label style="font-family: ABBvoice;">Select Division Master</mat-label>            
           <mat-select style="font-family: ABBvoice;" [(ngModel)]="designationMappingModel.divisionId" 
            formControlName="divisionControl" [disabled]="divisionDisable">
            <mat-select-trigger> {{selectedDivisionValue}} </mat-select-trigger>
             <mat-option>--</mat-option>
             <mat-option value="Select All" (click)="divisionAllSelection()">Select All</mat-option>
             <mat-option *ngFor="let division of divisionlst" [value]="division.divisionId" (click)="divisionSelection(division.divisionId, division.divisionName)">
               {{division.divisionName}}
             </mat-option>
           </mat-select>
          
         </mat-form-field>
      </div> 
      <div class="col-md-8" *ngIf="!isSave">
        <mat-form-field appearance="outline"> 
            <mat-label style="font-family: ABBvoice;">Select Division Master</mat-label>            
            <mat-select style="font-family: ABBvoice;" [(ngModel)]="designationMappingModel.divisionId" 
             formControlName="divisionControl" [disabled]="divisionDisable">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let division of divisionlst" [value]="division.divisionId" (click)="divisionSelection(division.divisionId, division.divisionName)">
                {{division.divisionName}}
              </mat-option>
            </mat-select>
            
          </mat-form-field>
       </div> 
    </div><br>
     <div class="row">   
       <div class="col-md-8" *ngIf="isSave">
        <mat-form-field appearance="outline">
          <mat-label  style="font-family: ABBvoice;">Select Designation</mat-label>
          <mat-select style=" font-family: ABBvoice;" #selectDesignation (openedDesignationChange)="openedDesignationChange($event)" placeholder="Select Designation" [formControl]="selectDesignationFormControl" multiple>
            <mat-select-trigger>
              {{selectedDesignationValue.value? selectedDesignationValue.value.join(', '): ''}}
                          </mat-select-trigger>
            <div class="select-container">
            <mat-optgroup >
          <mat-form-field style="width:100%;">
            <input #searchDesignation autocomplete="off" placeholder="Type to Search..." aria-label="Search" matInput [formControl]="searchDesignationTextboxControl">
            <button [disableRipple]="true" *ngIf="searchDesignation.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearDesignationSearch($event)">
            <mat-icon >close</mat-icon>
          </button>
                 </mat-form-field>
            </mat-optgroup>
            <mat-optgroup *ngIf="(designationFilteredOptions | async).length == 0">
              <div>No results found!</div>
            </mat-optgroup>

            <mat-checkbox class="select-all" color="primary" [(ngModel)]="allSelectedDesignation" [ngModelOptions]="{ standalone: true }"
                 (change)="toggleAllSelectionDesignation()">Select All</mat-checkbox>
           <mat-option (onSelectionChange)="designationSelectionChange($event)" *ngFor="let designation of designationFilteredOptions | async" [value]="designation" (click)="designationSelection()">
                  {{designation.designationName}}
                </mat-option>
        </div>
        </mat-select>
        </mat-form-field>
       </div>
       <div class="col-md-8" *ngIf="!isSave">
        <mat-form-field appearance="outline">
          <mat-label style="font-family: ABBvoice;">Select Designation</mat-label>
         <mat-select #select style=" font-family: ABBvoice;" [(ngModel)]="designationMappingModel.designationId" formControlName="designationControl" [disabled]="designationDisable">
         <mat-option *ngFor="let designation of designationlst" (click)="designationSelectionUpdate(designation.designationId, designation.designationName)"  [value]="designation.designationId" >
               {{designation.designationName}}
         </mat-option>
         </mat-select>
                
         </mat-form-field> 
       </div>
          
     </div><br>
     <div class="col-md-8">
             <mat-checkbox formControlName="status" [(ngModel)]='designationMappingModel.status'>
             <span style="font-family: ABBvoice;">Active</span> 
             </mat-checkbox>                   
       </div>   
     <div class="row button">
       <div mat-dialog-actions align="center" class="action" >
         <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
          type="submit">Save</button>
         <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
          type="submit">Update</button>
         <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
       </div>
     </div>
  <br><br>
 </form> 
</div>


