<div class="row">
    <div>
      <h4 class="Head" style="font-family: ABBvoice;">User Role Map
        <span style="padding-left: 220px;"><mat-icon (click)="closepopup()" class="closeBtn" matTooltip="Close" aria-label="Example icon-button with a heart icon">close</mat-icon></span>
      </h4>
      
    </div>
</div>
<form class="example-form" [formGroup]="formInstance">
  <div class="content">
    <div class="row">
      <div class="col-md-6" [hidden]="true">
        <mat-form-field appearance="outline"> 
          <mat-label style="font-family: ABBvoice;">Id</mat-label>            
          <input matInput type="text" [(ngModel)]="userRoleMappingModel.userId"
          placeholder="Ex. Id" formControlName="userId">
        </mat-form-field>
      </div>   
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label style="font-family: ABBvoice;">Email Id</mat-label>
          <input matInput type="text" [(ngModel)]="userRoleMappingModel.email"
          [disabled]="isEmailDisabled" placeholder="Ex. Email" required formControlName="email" 
          style="font-family: ABBvoice;">
          <mat-error *ngIf="formInstance.controls['email'].errors">Please enter email</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="!isEditable">
        <button mat-raised-button class="buttonABB" (click)="retrieve()" color="primary" type="submit">Retrieve Details</button>
      </div> 
      <div class="col-md-6" *ngIf="isEditable">
        <mat-form-field appearance="outline">  
          <mat-label style="font-family: ABBvoice;">First Name</mat-label>           
          <input matInput type="text" [(ngModel)]="userRoleMappingModel.firstName"
          placeholder="Ex. First Name" required formControlName="firstName" style="font-family: ABBvoice;">
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="isEditable">
        <mat-form-field appearance="outline">  
          <mat-label style="font-family: ABBvoice;">Last Name</mat-label>           
          <input matInput type="text" [(ngModel)]="userRoleMappingModel.lastName"
          placeholder="Ex. Last Name" required formControlName="lastName" style="font-family: ABBvoice;">
        </mat-form-field>
      </div>
      <div class="col-md-6" [hidden]="true">
        <mat-form-field appearance="outline">
          <mat-label style="font-family: ABBvoice;">Mobile Number</mat-label>
          <input matInput type="text" [(ngModel)]="userRoleMappingModel.mobile"
          placeholder="Ex. mobile number" required formControlName="mobile" 
          style="font-family: ABBvoice;">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="isEditable">   
      <div class="col-md-6">
        <mat-checkbox formControlName="isActive" [disabled]="isDisabled" [(ngModel)]="userRoleMappingModel.isActive">
          <span style="font-family: ABBvoice;">Admin</span> 
        </mat-checkbox>                   
      </div>     
    </div>
    <div class="row button"  *ngIf="isEditable">
      <div mat-dialog-actions align="center" class="action">
        <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
        type="submit">Save</button>
        <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
        type="submit">Update</button>
        <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
      </div>
    </div>
  </div>
</form>

