
<h4 class="Head" style="font-family: ABBvoice;">Reading This Document</h4>
   
<div>
 <form class="example-form" [formGroup]="formInstance">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label>Id</mat-label>            
             <input matInput type="text" [(ngModel)]='readingDocumentModel.iD'
             placeholder="Ex. Id" formControlName="iD">
         </mat-form-field>
     </div>   
     <div class="form-group row">
      <div class="col-md-12">
        <label for="column" style="font-size:20px;font-family: ABBvoice;" >Column *</label>
        <ngx-editor-menu [editor]="columnEditor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor [editor]="columnEditor" formControlName="column"
        [(ngModel)]="readingDocumentModel.column" [placeholder]="'Column'"></ngx-editor>
          <div *ngIf="((formInstance.get('column').invalid || !formInstance.get('column').value  )|| (this.readingDocumentModel.column=='<p></p>')) && 
            (formInstance.get('column').dirty || formInstance.get('column').touched || isSubmitted)"
            class="text-danger">
            Column is required.
          </div>
      </div>
    </div><br>
    <div class="form-group row">
      <div class="col-md-12">
        <label for="description" style="font-size: 20px;font-family: ABBvoice;">Description *</label>
        <ngx-editor-menu [editor]="descriptionEditor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor [editor]="descriptionEditor" formControlName="description"
        [(ngModel)]="readingDocumentModel.description" [placeholder]="'Description'"></ngx-editor>
        <div *ngIf="((formInstance.get('description').invalid || !formInstance.get('description').value )|| (this.readingDocumentModel.description=='<p></p>')) && 
          (formInstance.get('description').dirty || formInstance.get('description').touched || isSubmitted)"
          class="text-danger">
          Description is required.
        </div>
      </div>
    </div><br>
    <div class="row">
    <div class="col-md-6">
      <mat-checkbox formControlName="status" [(ngModel)]='readingDocumentModel.status'>
      <span style="font-family: ABBvoice;">Active</span> 
      </mat-checkbox>                   
    </div>  
    </div>
    <div class="row button">
      <div mat-dialog-actions align="center" class="action" >
        <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
        type="submit">Save</button>
        <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
        type="submit">Update</button>
        <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
      </div>
    </div>
  <br><br>
 </form> 
</div>


