
<mat-card>
  <div > 
    <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;font-family: ABBvoice;">Entity Master</h4>
    <button mat-raised-button style="font-family: ABBvoice;" class="col-md-4 dialogButton" 
          type="submit" (click)="openDialogBox()">Add</button>
  </div>
</mat-card>


<mat-card > 

      <div class="col-md-3 col-lg-3 col-sm-12">
        <div mat-dialog-actions >
            <mat-form-field appearance="outline">
              <mat-label style="font-family: ABBvoice;">Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>
          </div>
      </div>
  
    <div class="table-container">
      <div class="table-responsive">
     
       <table mat-table class="mat-elevation-z8" [dataSource]="dataSource" #empTbSort="matSort"
         class="table tblabb" style="margin: 0px auto;" matSort>
         <ng-container matColumnDef="id" >
          <th class="transactionHeader" mat-header-cell [hidden]="show" *matHeaderCellDef mat-sort-header>Id</th>
          <td class="transactionBody" mat-cell [hidden]="show" *matCellDef="let element">{{ element.entityId }}</td>
        </ng-container>
     
         <ng-container matColumnDef="code">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Entity Code</th>
          <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.code }}</td>
        </ng-container>
     
         <ng-container matColumnDef="name">
           <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</th>
           <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.entityName }}</td>
         </ng-container>
      
         <ng-container matColumnDef="description">
           <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
           <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.description }}</td>
         </ng-container>
   
         <ng-container matColumnDef="status">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.status ? 'Active' : 'InActive' }}</td>
        </ng-container>
  
          <ng-container matColumnDef="action">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td class="transactionBody" mat-cell *matCellDef="let element; let i = index">
              <button mat-icon-button >
                <mat-icon mat-icon-button color='primary' (click)="loadResourceToEdit(element)" >edit</mat-icon>
              </button>
              <!-- <button mat-icon-button >
                <mat-icon mat-icon-button color="warn" (click)="delete(element.entityId)">delete</mat-icon>
              </button> -->
            </td>
          </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
   
         <tr class="mat-row" *matNoDataRow>
           <td class="mat-cell" colspan="4">No data matching the filter </td>
         </tr>
       </table>
       <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" aria-label="Select page of Business Resource"></mat-paginator>
      </div>
    </div>
</mat-card>