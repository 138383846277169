import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { UserRoleMappingModel } from '../admin/user-role-mapping/user-role-mapping.component';

@Injectable({
  providedIn: 'root'
})
export class UserRoleMapService {

  rooturl= environment.rooturl ;
   // rooturl = 'https://localhost:7063/';   

  // rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
 //   rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
    //  rooturl = 'https://stage.table-of-authority.in.abb.com/api/';   

  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }

  getRoleDetails (){
    return this.http.get(this.rooturl + 'api/UserRoleMap/GetRoleDetails');
  }
  getAllUserDetails (){
    return this.http.get(this.rooturl + 'api/UserRoleMap/GetAllUserDetails');
  }
  getUserDetailsByEmail(email : string){
    return this.http.get(this.rooturl + 'api/UserRoleMap/GetUserDetailsByEmail?email=' + email);
  }
  save(data : UserRoleMappingModel){
    return this.http.post(this.rooturl + 'api/UserRoleMap/SaveUserRoleMap',data);
  }
  update(data : any){
    return this.http.post(this.rooturl + 'api/UserRoleMap/SaveUserRoleMap',data);
  }
}
