<mat-card>
  <div class="row">
    <div class="col-md-11 col-lg-11 col-sm-11">

      <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;">
        Activity Master</h4>
    </div>
    <div class="col-md-1 col-lg-1 col-sm-1">
      <p class="text-center"><a routerLink="/ActivityMapping" title="Go back to mapping screen" mat-raised-button
          class="buttonABB" color="primary"
          style="margin-left: 5px;padding-left:10px;padding-right:10px;"><mat-icon>arrow_back</mat-icon>Back</a></p>

      <!-- <mat-icon>arrow_back_ios</mat-icon> -->
    </div>

  </div>

  <form class="ex-form" [formGroup]="formInstance">
    <div   class="content">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-sm-12">
        </div>
      </div>
    </div>
  </form>
</mat-card>


<mat-card>
  <div class="row">
    <div class="col-md-1 col-lg-0 col-sm-1"></div>
    <div class="col-md-11 col-lg-11 col-sm-11">


      <input [(ngModel)]="searchString" placeholder="Search Tree node" width="200px" />
      <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Collapse All"
        style="margin-left: 8px;margin-right: 8px;padding-left: 10px;padding-right: 10px;"
        (click)="tree.treeControl.collapseAll()"><mat-icon>expand_more</mat-icon></button>
      <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Expand All"
        style="margin-right: 8px;padding-left: 5px;padding-right: 5px;"
        (click)="tree.treeControl.expandAll()"><mat-icon>chevron_right</mat-icon></button>
      <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Create parent node"
        style="margin-right: 8px;padding-left: 5px;padding-right: 5px;"
        (click)="AddDialogBox('')"><mat-icon>add</mat-icon></button>




      <mat-tree #tree [dataSource]="dataSource_Tree" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [style.display]="filterLeafNode(node) ? 'none' : 'block'" [ngClass]="getColor(node)" >
        <!--   <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.text">
            <mat-icon class="mat-icon-rtl-mirror"></mat-icon>
          </button> -->
          
          {{node.text}}
          <button mat-icon-button matTooltip="Edit" [ngClass]="{'disable':node.disabled == true}"    (click)="loadResourceToEdit(node)">
            <mat-icon >edit</mat-icon>
          </button>

          <button mat-icon-button type="submit" matTooltip="Add Child node" [ngClass]="{'disable':node.disabled == true}" 
            (click)="AddDialogBox(node)"><mat-icon>add</mat-icon>
          </button>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"   [style.display]="filterParentNode(node) ? 'none' : 'block'"  >

          <div class="mat-tree-node" [ngClass]="getColor(node)">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.text">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.text}} 
            <button mat-icon-button matTooltip="Edit" 
            [ngClass]="{'disable':node.disabled == true}" 
               (click)="loadResourceToEdit(node)">
              <mat-icon >edit</mat-icon>
            </button>
            <button mat-icon-button type="submit" matTooltip="Add Child node" [ngClass]="{'disable':node.disabled == true}" 
              (click)="AddDialogBox(node)"><mat-icon>add</mat-icon></button>

          </div>
          <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>

  </div>
</mat-card>