import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  MasterService } from 'src/app/Services/master.service';
import { MatSelect } from '@angular/material/select';
import { tr } from 'date-fns/locale';
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";

export interface DesignationMapping{
  id: number;
  designationId  : number;
  designationName  : string;
  designationIds  : string;
  entityId : number,
  entityName  : string;
  divisionId : number;
  divisionName : string;
  businessAreaId : number,
  businessAreaName : string,
  status : boolean;
  isActive : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
  isDelete:boolean;
}

export interface BusinessAreaMST{
  id:number;
  name: string;
}
export class Entity{
  id:number;
  code:string;
  name: string;
  status: boolean;
}
@Component({
  selector: 'app-designation-mapping',
  templateUrl: './designation-mapping.component.html',
  styleUrls: ['./designation-mapping.component.scss']
})
export class DesignationMappingComponent implements OnInit {
  searchValueText: string = '';
  entityList: Entity[]=[];
  businessList: BusinessAreaMST[];
  selectEntityFormControl = new FormControl();
  businessFormControl = new FormControl();
 
  formInstance: any;
  selectedEntityValue = [];

  isIdVisible: boolean = false;
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['entityName','divisionName','businessAreaName','designationName','status','action'];
  showAdd: boolean=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedBusinessAreaValue: string;
  selectedBusinessAreaValues = [];
  disableBusinessArea:boolean=false;
  showTrigger: boolean=false;
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, 
              private DesignationServ : MasterService,private _formBuilder: FormBuilder,
              private activitymasterSer: MasterService) { 


                this.formInstance = _formBuilder.group({
                  "businessControl":['', Validators.required]//,
                 
                  /* searchString_new: new FormControl(''),
                  searchString: new FormControl('') */
                });
              }
  
  ngOnInit(): void {
    this.getEntity();
    //this.getDesignationMapping();
  }

  getEntity(){
    
    this.activitymasterSer.getEntityList().subscribe((data:any)=>{
      this.entityList= data;
      let entityID: number;
      if (this.entityList && this.entityList.length > 0) {
        this.entityList.forEach((e) => {
          if(e.code == 'IN01'){
            entityID= e.id;
            if (this.selectedEntityValue.indexOf(e) == -1) {
              this.selectedEntityValue.push(e);
            }
          }  
        });
      }
      this.selectEntityFormControl.patchValue(this.selectedEntityValue[0]);
      this.activitymasterSer.getBuisnessAreaList(entityID).subscribe((data:any)=>{
        this.businessList = data, () => console.log('Get all complete'); 
        if(this.businessList.length ==0){
          this.disableBusinessArea=true;
        }
        else{
          this.disableBusinessArea=false;
        }
      });
    });
  }
  entitySelection(entityID:number){
    
    this.businessList=[];
    this.businessFormControl.reset();
    //this.dataSource.data=[];
    this.dataSource=null;
    this.showTrigger=false;
    this.showAdd=false;
    this.activitymasterSer.getBuisnessAreaList(entityID).subscribe((data:any)=>{
      this.businessList = data, () => console.log('Get all complete'); 
       
      if(this.businessList.length ==0){
        this.disableBusinessArea=true;
      }else{
        this.disableBusinessArea=false;
      }
    });
  }


  businessAreaSelection(id,name) {
    if(this.dataSource!=null){
      this.dataSource.data=[];
    }
    //this.formInstance.value.businessControl = this.businessFormControl.value;
    if(id >0 ){
      this.showAdd=true;
      this.showTrigger=true;
    }
    this.businessFormControl.patchValue(id);
    this.selectedBusinessAreaValue= name;
    //this.getDesignationMapping(this.selectEntityFormControl.value.id,this.businessFormControl.value)
   this.searchValueText = '';

  }
  businessAreaAllSelection(){
    if(this.dataSource!=null){
      this.dataSource.data=[];
    }
    this.searchValueText = '';
    this.businessFormControl=new FormControl();
    this.selectedBusinessAreaValues=[];
    if (this.businessList && this.businessList.length > 0) {
      this.businessList.forEach((e) => {
        
      this.selectedBusinessAreaValues.push(e.id);
          
      });
    }
    this.businessFormControl.patchValue(this.selectedBusinessAreaValues);

    if(this.businessFormControl.value != null ){
      this.showAdd=true;
      this.showTrigger=true;
    }
   
    this.selectedBusinessAreaValue=  this.businessList.map(item=>item.name).join(', ');
  }

  openDialogBox(){
    this.isIdVisible = false;
    
    const dialogRef = this.dialog.open(AddDesignationMappingDialog,{
      data: {
        action:"Add",
        businessAreaId: this.businessFormControl.value,
        entityId : this.selectEntityFormControl.value.id
      }, 
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getDesignationMapping(this.selectEntityFormControl.value.id,this.businessFormControl.value);
        }
      }
    })
  }

  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  getDesignationMapping( entityId, buid){
    this.DesignationServ.getDesignationMapping(entityId, buid).subscribe((data:any)=>{
      this.dataSource= new MatTableDataSource<DesignationMapping>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  retrieve(){
     
    if(this.businessFormControl.value ==null ){
      this.activitymasterSer.ShowSnackBar("Please Choose Business Area",'rd-snackbar');
      return;
    }
    if(this.selectEntityFormControl.value ==null ){
      this.activitymasterSer.ShowSnackBar("Please Choose Category",'rd-snackbar');
      return;
    }
    else{
      this.getDesignationMapping(this.selectEntityFormControl.value.id,this.businessFormControl.value);
    }
   
  }
  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddDesignationMappingDialog,{
      data,
    }) 
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getDesignationMapping(this.selectEntityFormControl.value.id,this.businessFormControl.value);
        }
      }
    })
  }
  
  delete(id: any) {  
     
    console.log('dataaa',id)
    if (confirm("Are you sure you want to delete this")) {  
    this.DesignationServ.deleteDesignationMapping(id).subscribe(() => {  
      this.snackBarLoader('Deleted Successfully','Done','1000', 'red-snackbar')
      this.getDesignationMapping(this.selectEntityFormControl.value.id,this.businessFormControl.value);
    });  
   }  
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
export interface DesignationMST{
  designationId:number;
  designationName: string;
  isActive:boolean;
}

@Component ({
  selector: 'app-dialog-Designation- Mapping',
  templateUrl: './addDesignationMappingDialog.html',
  styleUrls: ['./designation-mapping.component.scss']
})

  export class AddDesignationMappingDialog implements OnInit {
   
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    isEditMode :boolean =false;
    ischecked :boolean =true;
    isShowDesignationDropdown:boolean=true;
    createdByUserId : number;
    action:string;
    createdBy : number;
    createdDate : Date;
    designationMappingModel: DesignationMapping= {} as DesignationMapping;
    entitylst : any[] =[];
    businesslst: any[] =[];
    designationlst: DesignationMST[]=[];
    divisionlst: any[] =[];
    filterValue_Designation = '';  
    businessAreaFilteredOptions= this.businesslst.slice();
    designationName:string;
    
    isMultipleSelected: boolean = false;
    isSave:boolean;
    @ViewChild('select') select: MatSelect;
    divisionDisable: boolean;
    designationDisable: boolean;

    selectedDivisionId= new FormControl([]);
    selectedDivisionValue: string;
    allSelectedDesignation: boolean;
    @ViewChild('selectDesignation') selectDesignation: MatSelect;
    isMultipleSelectedDesignation: boolean = false;
    
    @ViewChild('searchDesignation') searchDesignationTextBox: ElementRef;
    selectedDesignationId= new FormControl([]);
    selectedDesignationValue= new FormControl([]);
    selectDesignationFormControl = new FormControl([]);
    searchDesignationTextboxControl = new FormControl();
    selectedDesignationValues = [];
    designationFilteredOptions : Observable<DesignationMST[]>= of(this.designationlst);
    
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddDesignationMappingDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private designationServ : MasterService) 
    {
      this.formInstance = _formBuilder.group({
        "designationControl": ['', [Validators.required]], 
        /* "entityControl":['', Validators.required],
        "businessControl":['', Validators.required], */
        "divisionControl":['', Validators.required],
        "status": true,
      });
    }
    ngOnInit(): void {
      this.designationMappingModel.status = true;
      this.getEntityData();
      this.isSave=true;
      if(this.data !=null && this.data !=undefined)
      {
        if(this.data.action=="Add")
          {
            this.designationMappingModel.entityId = this.data.entityId;
            this.changeBusinessArea(this.data.businessAreaId);
            this.designationMappingModel.businessAreaId = this.data.businessAreaId;
          }
          else{
             
            this.isSave= false;
            if(this.data.divisionId == 0){
              this.divisionDisable= false;
            }else{
              this.divisionDisable= true;
            }
            
            if(this.data.designationId == 0){
              this.designationDisable=false;
            }else{
              this.designationDisable=true;
            }
            this.designationMappingModel.entityId = this.data.entityId;
           /*  this.changeEntity(this.data.entityId); */
            this.changeBusinessArea(this.data.businessAreaId);
            
            this.designationMappingModel.id = this.data.designationId;
            this.designationMappingModel.businessAreaId = this.data.businessAreaId;
            this.designationMappingModel.designationId = this.data.designationId;
            this.filterValue_Designation = this.data.designationId;
            this.designationMappingModel.divisionId = this.data.divisionId;
            this.designationMappingModel.status = this.data.status;
            /* this.createdBy = this.data.createdBy;
            this.createdDate = this.data.createdDate; */
            this.isIdVisible = true
            this.isShowDesignationDropdown=false;
            this.designationName=this.data.designationName;
          }
       
      
      }
      var emailId = sessionStorage.getItem('LoginEmail');
      this.getUserId(emailId);
    
    }
    designationSelectionUpdate(desID: number, desName: string){
       
      this.selectedDesignationId.value.push(desID);
      this.selectedDesignationValue.value.push(desName);
      this.formInstance.value.designationControl = this.selectedDesignationId.value;
    }
    getEntityData(){
      this.designationServ.getEntityMaster().subscribe((data:any)=>{
        this.entitylst = data,     
        error => console.log(error),  
         () => console.log('Get all complete');    
      });
    }

    changeEntity(entityId : number){
      this.designationServ.getBusinessAreaEntityMapping(entityId).subscribe((data:any)=>{
        this.businesslst = data,
        error => console.log(error),  
         () => console.log('Get all complete'); 
      });
    }
    addItem(designationId:number, designationName: string,isActive:boolean){
      const newItem: DesignationMST={designationId,designationName,isActive};
      this.designationlst.push(newItem);
    }
    changeBusinessArea (businessAreaId : number){
      
      this.designationServ.getDesignationBusinessMapping(businessAreaId.toString()).subscribe((data:any)=>{
        this.designationlst = data

        if(this.data.action!="Add"){
        this.addItem(this.data.designationId, this.data.designationName,true);
        }
        
        this.designationFilteredOptions = this.searchDesignationTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(designationName => this.applyfilter_designation(designationName))
       ),
        error => console.log(error),  
         () => console.log('Get all complete'); 
      });
      console.log(this.designationFilteredOptions);
      this.designationServ.getDivisionDetailsFromBusinessAreaId(businessAreaId.toString()).subscribe((data:any)=>{
        this.divisionlst = data
        if(this.divisionlst.length ==0){
          this.divisionDisable= true;
        }
      });
    }

    applyfilter_designation(designationName: string){
     const filterValue = designationName.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setDesignationSelectedValues();
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);
    this.selectDesignationFormControl.patchValue(this.selectedDesignationValues);
    this.selectDesignationFormControl.value.forEach(item=> {
      this.selectedDesignationId.value.push(item.designationId);
      this.selectedDesignationValue.value.push(item.designationName);
    });
    let filteredList = this.designationlst.filter(option => option.designationName.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
    }
    openedDesignationChange(e) {
      // Set search textbox value as empty while opening selectbox 
      this.searchDesignationTextboxControl.patchValue('');
      
    }
  
    clearDesignationSearch(event) {
      event.stopPropagation();
      this.searchDesignationTextboxControl.patchValue('');
    }
    designationSelectionChange(event) {
      if (event.isUserInput && event.source.selected == false) {
        let index = this.selectedDesignationValues.indexOf(event.source.value);
        this.selectedDesignationValues.splice(index, 1)
      }
    }
    setDesignationSelectedValues() {
      console.log('selectDesignationFormControl', this.selectDesignationFormControl.value);
      if (this.selectDesignationFormControl.value && this.selectDesignationFormControl.value.length > 0) {
        this.selectDesignationFormControl.value.forEach((e) => {
          if (this.selectedDesignationValues.indexOf(e) == -1) {
            this.selectedDesignationValues.push(e);
          }
        });
      }
    }
    selectedDesignationIdExists(id: number):boolean{
      return this.selectedDesignationId.value.some(item=>item === id);
    }
    selectedDesignationValueExists(value: string):boolean{
      return this.selectedDesignationValue.value.some(item=>item === value);
    }
    divisionSelection(divisionId, divisionName){
      this.selectedDivisionId= new FormControl([]);
      this.designationMappingModel.divisionId= divisionId;
      this.formInstance.value.divisionControl= divisionId;
      this.selectedDivisionValue= divisionName;
      this.selectedDivisionId.value.push(divisionId);
    }
    divisionAllSelection(){
      this.selectedDivisionId= new FormControl([]);
      this.selectedDivisionValue= this.divisionlst.map(item=>item.divisionName).join(', ');
      this.formInstance.value.divisionControl= this.divisionlst.map(item=>item.divisionId);
      this.formInstance.value.divisionControl.forEach(item=> {
        this.selectedDivisionId.value.push(item);
      });
    }
    designationSelection() {
     
      this.selectedDesignationId= new FormControl([]);
      this.selectedDesignationValue= new FormControl([]);
      if(this.selectedDesignationValues.length !=0){
        this.selectedDesignationValues.forEach(item=> {
          this.selectedDesignationId.value.push(item.designationId);
          this.selectedDesignationValue.value.push(item.designationName);
        });
       }
      this.selectDesignationFormControl.value.forEach(item=> {
        if(!this.selectedDesignationIdExists(item.designationId)){
          this.selectedDesignationId.value.push(item.designationId);
        }
        if(!this.selectedDesignationValueExists(item.designationName)){
          this.selectedDesignationValue.value.push(item.designationName);
        }
      });
      this.formInstance.value.designationControl = this.selectedDesignationId.value;
     
      console.log(this.businessAreaFilteredOptions);
    }
    toggleAllSelectionDesignation(){
      if (this.allSelectedDesignation) {
        this.selectDesignation.options.forEach((item: MatOption) => item.select());
        this.isMultipleSelectedDesignation = true;
        this.selectedDesignationValue= new FormControl([]);
         this.selectedDesignationValue= new FormControl([]);
        
         this.selectDesignationFormControl.value.forEach(item=> {
          this.selectedDesignationId.value.push(item.designationId);
          this.selectedDesignationValue.value.push(item.designationName);
        });
      
      this.formInstance.value.designationControl= this.selectedDesignationId.value;
      } else {
         this.selectDesignation.options.forEach((item: MatOption) => item.deselect()); 
         this.isMultipleSelectedDesignation = false;
         this.selectedDesignationId= new FormControl([]);
         this.selectedDesignationValue= new FormControl([]);
      }
    }
    closepopup(){
      if(confirm("Are you sure to close this")) {
        this.dialogRef.close();
      }
    }

    save(){
      
      if(this.formInstance.value.divisionControl=="" && this.divisionlst.length >0){
        this.snackBarLoader('Please Select Division','Done','1000', 'red-snackbar')
        return
      }
      if(this.formInstance.value.divisionControl==undefined && this.divisionlst.length >0){
        this.snackBarLoader('Please Select Division','Done','1000', 'red-snackbar')
        return
      }
      if(this.selectedDesignationId.value.length==0){
        this.snackBarLoader('Please Select Designation','Done','1000', 'red-snackbar')
        return
      }
      if(this.selectedDivisionId.value.length==0 && this.divisionlst.length >0){
        this.snackBarLoader('Please Select Division','Done','1000', 'red-snackbar')
        return
      }
      var designationId="0"
      var businessAreaId="0"
      var divisionId="0"
      var entityId=0
      this.formInstance.value.designationControl = this.selectedDesignationId.value;
      this.formInstance.value.divisionControl = this.selectedDivisionId.value;
      if(this.formInstance.value.designationControl.length>1){
        designationId=this.formInstance.value.designationControl.join(",");
      }
      else{
        designationId=this.formInstance.value.designationControl.toString();
      }
      if(this.divisionlst.length >0){
        if(this.formInstance.value.divisionControl.length>1 ){
          divisionId=this.formInstance.value.divisionControl.join(",");
        }else{
          divisionId=this.formInstance.value.divisionControl.toString();
        }
      }
      
     
      businessAreaId=this.designationMappingModel.businessAreaId.toString();
    
      entityId=this.designationMappingModel.entityId;
      //createdBy = this.createdByUserId;
        const myData = { designationId: designationId, 
          businessAreaId: businessAreaId, 
          divisionId: divisionId, 
          entityId: entityId,
          emailId : sessionStorage.getItem('LoginEmail'),
          status: this.designationMappingModel.status,
          isActive : true
         };
         console.log(myData);
      this.designationServ.saveDesignationMappingData(myData).subscribe({
        next: (val :any) => {
          this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
          this.resetForm();
          this.dialogRef.close(true);
        },
        error: (err: any) =>{
          this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
          this.resetForm();
        }
      }) 
    }
     
    update(){
      
      if(this.designationMappingModel.divisionId==undefined && this.divisionlst.length >0){
        this.snackBarLoader('Please Select Division.','Done','1000', 'red-snackbar')
        return
      }

      if(this.formInstance.value.divisionControl==0 && this.divisionlst.length >0  || this.designationMappingModel.divisionId==0 && this.divisionlst.length >0)
        {
          this.snackBarLoader('Please Select Division.','Done','1000', 'red-snackbar')
          return
        }

      if(this.formInstance.value.designationControl==""){
        this.snackBarLoader('Please Select Designation','Done','1000', 'red-snackbar')
        return
      }
      this.designationMappingModel.updatedBy = this.createdByUserId;
      this.designationMappingModel.createdBy = this.createdBy;
      this.designationMappingModel.createdDate = this.createdDate;
      var designationId="0"
      var businessAreaId="0"
      var divisionId="0"
      var entityId=0
    
      if(this.formInstance.value.businessControl!=""){

      //createdBy = this.createdByUserId;
        const myData = { designationId: this.designationMappingModel.id.toString(), 
          businessAreaId: this.data.businessAreaId.toString(), 
          divisionId: this.designationMappingModel.divisionId.toString(), 
          entityId: this.data.entityId,
          emailId : sessionStorage.getItem('LoginEmail').toString(),
          status: this.designationMappingModel.status,
          isActive : true
         };
         console.log(myData);
        this.designationServ.saveDesignationMappingData(myData).subscribe({
          next: (val :any) => {
            this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
            this.resetForm();
            this.dialogRef.close(true);
          },
          error: (err: any) =>{
            this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
            this.resetForm();
          }
        }) 
      }
    }
   

    snackBarLoader(content, action, duration, color){
      this.snackBar.open(content, action, {
        duration: 2000,
        verticalPosition: "top", 
        horizontalPosition: "center", 
        panelClass: [color]
      });
    }
   
    resetForm() {  
      this.formInstance.reset(); 
      this.designationMappingModel.id = 0;
      Object.keys(this.formInstance.controls).forEach(key => {
        this.formInstance.get(key).setErrors(null) ;
      });
      this.isIdVisible = false;
      this.dialogRef.close(true);
    }
    
    getUserId(emailId : string){
      this.designationServ.getUserId(emailId).subscribe((data:any)=>{
        this.createdByUserId = data[0].id;
      });
    }
}