
<h4 class="Head" style="font-family: ABBvoice;">Division Master</h4>
   
<form class="example-form" [formGroup]="formInstance">
 <div class="content">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label style="font-family: ABBvoice;">Id</mat-label>            
             <input style="font-family: ABBvoice;" matInput type="text" [(ngModel)]='divisionModel.id'
             placeholder="Ex. Id" formControlName="id">
         </mat-form-field>
     </div>   
     <div class="row">
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
           <mat-label style="font-family: ABBvoice;">Division Name</mat-label>           
           <input matInput type="text" [(ngModel)]='divisionModel.name' [errorStateMatcher]="customErrorStateMatcher"
            placeholder="Ex. Name" required formControlName="name" style="font-family: ABBvoice;">
            <mat-error *ngIf="formInstance.get('name').hasError('specialCharacters')">Name should not contain special characters</mat-error>
            <mat-error *ngIf="formInstance.get('name').invalid && formInstance.get('name').hasError('required') && !formInstance.get('name').hasError('specialCharacters')">Name is required</mat-error>
                  </mat-form-field>
       </div> <br>
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
           <mat-label style="font-family: ABBvoice;">Division Code</mat-label>           
           <input matInput type="text" [(ngModel)]='divisionModel.code'
            placeholder="Ex. Code" required formControlName="code" style="font-family: ABBvoice;">
           <mat-error *ngIf="formInstance.controls['code'].errors">Code is required</mat-error>
         </mat-form-field>
       </div>   <br>
     </div>
     <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline"> 
              <mat-label   style="font-family: ABBvoice;">Business Master</mat-label>            
              <mat-select style="font-family: ABBvoice;" [(ngModel)]="divisionModel.businessAreaId" 
               formControlName="divisionControl" required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let business of businesslst" [value]="business.businessAreaId">
                  {{business.businessAreaName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formInstance.controls['divisionControl'].errors">Please choose Business Master</mat-error>
            </mat-form-field>
        </div><br>
     </div>
     <div class="row">   
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
             <mat-label style="font-family: ABBvoice;">Description</mat-label>           
             <textarea matInput [(ngModel)]="divisionModel.description" placeholder="Ex. Description" formControlName="description" style="font-family: ABBvoice;"></textarea>
           </mat-form-field>
       </div>
       <div class="col-md-6">
             <mat-checkbox formControlName="status" [(ngModel)]='divisionModel.status'>
             <span style="font-family: ABBvoice;">Active</span> 
             </mat-checkbox>                   
       </div>      
     </div><br>
     
     <div class="row button">
       <div mat-dialog-actions align="center" class="action" >
         <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
          type="submit">Save</button>
         <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
          type="submit">Update</button>
         <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
       </div>
     </div>
  <br><br>
 </div>
</form> 



