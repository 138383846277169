<mat-card> 
    <div class="row">
      <div class="col-md-8 col-lg-8 col-sm-12"> 
        <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;">
          Search Activity Details 
          </h4> 
      </div>
      <div class="col-md-4" style="padding-top: 20px;padding-left: 40px;">
        <h6  style="font-size: 13px; font-family: ABBvoice; font-weight: bold;padding-left: 80px;">
          Version: {{latestVersion}}
          </h6> 
      </div>
    </div>
 
  <form class="ex-form" [formGroup]="formInstance">
   <div   class="content">
    <div class="row" >
      <div class="col-md-3 col-lg-3 col-sm-12">
        <form class="example-form">
          <mat-form-field appearance="outline"> 
            <mat-label style="font-family: ABBvoice;">Select Entity</mat-label>           
            <mat-select required style=" font-family: ABBvoice;" [formControl]="selectEntityFormControl">
              <mat-option *ngFor="let entity of entityList" [value]="entity" (click)="entityselection(entity.id)">{{entity.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label style="font-family: ABBvoice;">Select Business Area</mat-label>
    <mat-select #select required style=" font-family: ABBvoice;" (openedBusinessAreaChange)="openedBusinessAreaChange($event)" placeholder="Select Business Area" [formControl]="selectBusinessAreaFormControl" multiple>
      <mat-select-trigger>
        {{selectedBusinessAreaValue.value? selectedBusinessAreaValue.value.join(', '): ''}}
                    </mat-select-trigger>
      <div class="select-container">
      <mat-optgroup >
    <mat-form-field style="width:100%;">
      <input #searchBusinessArea autocomplete="off" (keydown)="handleInput($event)" placeholder="Type to Search..." aria-label="Search" matInput [formControl]="searchBusinessAreaTextboxControl">
      <button [disableRipple]="true" *ngIf="searchBusinessArea.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearBusinessAreaSearch($event)">
      <mat-icon >close</mat-icon>
    </button>
           </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="(businessAreaFilteredOptions | async).length == 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-checkbox class="select-all" color="primary" [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }"
               (change)="toggleAllSelection()">Select All</mat-checkbox>
   <mat-option (onSelectionChange)="businessAreaSelectionChange($event)" *ngFor="let business of businessAreaFilteredOptions | async" [value]="business" (click)="businessAreaSelection()">
          {{business.name}}
        </mat-option>
  </div>
  </mat-select>
           
        </mat-form-field>
        
        
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12">
        
          <mat-form-field appearance="outline" >
            <mat-label style="font-family: ABBvoice;">Select Division</mat-label>
            <mat-select style=" font-family: ABBvoice;" #selectDivision (openedDivisionChange)="openedDivisionChange($event)" placeholder="Select Division" [formControl]="selectDivisionFormControl" multiple>
              <mat-select-trigger>
                {{selectedDivisionValue.value? selectedDivisionValue.value.join(', '): ''}}
                            </mat-select-trigger>
              <div class="select-container">
              <mat-optgroup >
            <mat-form-field style="width:100%;">
              <input #searchDivision autocomplete="off" (keydown)="handleInput($event)" placeholder="Type to Search..." aria-label="Search" matInput [formControl]="searchDivisionTextboxControl">
              <button [disableRipple]="true" *ngIf="searchDivision.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearDivisionSearch($event)">
              <mat-icon >close</mat-icon>
            </button>
                   </mat-form-field>
              </mat-optgroup>
              <mat-optgroup *ngIf="(divisionFilteredOptions | async).length == 0">
                <div>No results found!</div>
              </mat-optgroup>
              <mat-checkbox class="select-all" color="primary" [(ngModel)]="allSelectedDivision" [ngModelOptions]="{ standalone: true }"
              (change)="toggleAllSelectionDivision()">Select All</mat-checkbox>
           <mat-option (onSelectionChange)="divisionSelectionChange($event)" *ngFor="let division of divisionFilteredOptions | async" [value]="division" (click)="divisionSelection()">
                  {{division.name}}
                </mat-option>
          </div>
          </mat-select>
          </mat-form-field>
        
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12">
       
          <mat-form-field appearance="outline">
            <mat-label  style="font-family: ABBvoice;">Select Designation</mat-label>
            <mat-select style=" font-family: ABBvoice;" #selectDesignation (openedDesignationChange)="openedDesignationChange($event)" placeholder="Select Designation" [formControl]="selectDesignationFormControl" multiple>
              <mat-select-trigger>
                {{selectedDesignationValue.value? selectedDesignationValue.value.join(', '): ''}}
                            </mat-select-trigger>
              <div class="select-container">
              <mat-optgroup >
            <mat-form-field style="width:100%;">
              <input #searchDesignation autocomplete="off" (keydown)="handleInput($event)" placeholder="Type to Search..." aria-label="Search" matInput [formControl]="searchDesignationTextboxControl">
              <button [disableRipple]="true" *ngIf="searchDesignation.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearDesignationSearch($event)">
              <mat-icon >close</mat-icon>
            </button>
                   </mat-form-field>
              </mat-optgroup>
              <mat-optgroup *ngIf="(designationFilteredOptions | async).length == 0">
                <div>No results found!</div>
              </mat-optgroup>
              <mat-checkbox class="select-all" color="primary" [(ngModel)]="allSelectedDesignation" [ngModelOptions]="{ standalone: true }"
                 (change)="toggleAllSelectionDesignation()">Select All</mat-checkbox>
           <mat-option (onSelectionChange)="designationSelectionChange($event)" *ngFor="let designation of designationFilteredOptions | async" [value]="designation" (click)="designationSelection()">
                  {{designation.name}}
                </mat-option>
          </div>
          </mat-select>
          </mat-form-field>
        
      </div>
    </div>
    <div class="row" style="margin-bottom: 1%;margin-top: 1%;">
      <div class="col-md-6"  style="display: flex; justify-content: left">
        
        
          
          <mat-form-field class="example-full-width" appearance="outline" >
            <input  id="txtTreeViewSelected"  placeholder="{{getSelectedItemsList}}" aria-label="Number" 
            matInput [matAutocomplete]="auto" [formControl]="searchValueFormControl"
            (keyup)="applyFilterForTree($event)" >         
            
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
              <mat-option disabled >Please select an item from below</mat-option>
              <mat-tree #tree [dataSource]="dataSource_Tree" [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <li class="mat-tree-node">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                        <mat-checkbox class="checklist-leaf-node" 
                        [checked]="checklistSelection.isSelected(node)"
                  (change)="todoItemSelectionToggle($event.checked,node)"
                  
              >{{node.text}}</mat-checkbox>                
                  </li>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
                  <li>
                    <div class="mat-tree-node">
                      <button mat-icon-button matTreeNodeToggle
                              [attr.aria-label]="'toggle ' + node.text">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>
                          <mat-checkbox 
                          [checked]="descendantsAllSelected(node)"
                          [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="todoItemSelectionToggle($event.checked,node)"
                                
                                >{{node.text}}</mat-checkbox>
                    </div>
                     <ul [class.example-tree-invisible]="treeControl.isExpanded(node)"> 
                      <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
            </mat-autocomplete>
            <!-- <button mat-raised-button  color="primary" style="margin-right:10px;height:30px;background-color: #FE000F;float:right" (click)="ClearSelection()">X</button> -->
            <button type="button" mat-button  matSuffix mat-icon-button aria-label="Clear"   (click)="ClearSelection()">
              <mat-icon *ngIf="showCloseIcon">close</mat-icon>
            </button>
          </mat-form-field> 
        
      </div>
    </div>
    <div class="row" mat-dialog-actions align="center" class="action">
      <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="Search()">Search</button> 
      <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="generateExcel()">Generate Excel</button> 
      <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="generatePDF()">Generate PDF</button> 
        <button  mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="reset()">Reset</button> 
      
    </div>
   </div>
  </form>
</mat-card>
<br>
<br>

<mat-card> 
  <div class="row">
    <div class="col-md-3 col-lg-3 col-sm-12">
      <div mat-dialog-actions >
          <mat-form-field appearance="outline">
            <mat-label style="font-family: ABBvoice;">Search</mat-label>
            <input style="font-family: ABBvoice;" matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input [(ngModel)]="searchValueText">
          </mat-form-field>
          
      </div>
    </div>
  <!--   <div class="col-md-9 col-lg-9 col-sm-12" style="float:right;">
      <mat-label style="font-family: ABBvoice;">Color Codes : </mat-label>
   
      <input style="background-color:#a9a9a9;width:60px;color:black" disabled   value="Level1" #input>
      <input style="background-color:#d2d2d2;width:60px;color:black" disabled   value="Level2" #input>
      <input style="background-color:#f0f0f0;width:60px;color:black" disabled   value="Level3" #input>
      <input style="background-color:white;width:60px;color:black" disabled   value="Level4" #input>
    </div> -->
  </div>
  <div class="row"> 
    
  <div class="table-responsive">    
    <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8"  class="table tblabb" #empTbSort="matSort" matSort style="margin: 0px auto;">
      
      <ng-container matColumnDef="ActivityId">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="activityId"> Id </th>
        <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;" > {{element.activityId}} </td>
      </ng-container>
    <!--   <ng-container matColumnDef="SourceOfActivity">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="sourceOfActivity"> Source of Activity </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.sourceOfActivity}} </td>
      </ng-container> -->
      <ng-container matColumnDef="CatBatReference">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="catBatReference"> CAT/ BAT Reference </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}" > {{element.catBatReference}} </td>
      </ng-container>
      <ng-container matColumnDef="CPReference">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="cpReference"> CP Reference </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;  " [innerHTML]="addHyperlink(element.cpReference)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td>   
      </ng-container>
      <ng-container matColumnDef="MIReference">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="miReference"> MI Reference </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.miReference)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td>  
      </ng-container>
   <!--    <ng-container matColumnDef="BRReference">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="brReference"> BR Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.brReference)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td>  
      </ng-container> -->
      <ng-container matColumnDef="Tool">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="tool"> Tool/System/Portal </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  {{element.tool}} </td>
      </ng-container>
      <ng-container matColumnDef="ApplicableToBusiness">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="applicableToBusiness"> Applicable to business </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}"> {{element.applicableToBusiness}} </td>
      </ng-container>
      <ng-container matColumnDef="Activity" style="min-width: 300px;">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: left !important;min-width: 300px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="activity"> Activity </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: left;min-width: 300px; " [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}"> {{element.activity}} </td>
      </ng-container>
      <ng-container matColumnDef="Responsible">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="responsible"> Responsible </th>
       <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.responsible)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td> 
      </ng-container>
    
      <ng-container matColumnDef="Accountable" >
        <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="accountable"> Accountable </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.accountable)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td> 
      </ng-container>
  
      <ng-container matColumnDef="Consult" >
        <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="consult"> Consult </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.consult)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td> 
      </ng-container>
  
      <ng-container matColumnDef="Inform" style="text-align: center;min-width: 150px;">
        <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;min-width: 150px;padding-left: 50px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="inform"> Inform </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 150px;  " [innerHTML]="addHyperlink(element.inform)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td> 
      </ng-container>     
      <ng-container matColumnDef="Remark" style="text-align: center;min-width: 300px;">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;min-width: 300px;padding-left: 116px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="remark"> Remark </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; " [innerHTML]="addHyperlink(element.remark)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td> 
      </ng-container>
      <ng-container matColumnDef="ActivityFormingPartOfLat">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="activityFormingPartOfLat"> Activity forming part of LAT? </th>
        <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}"> {{element.activityFormingPartOfLat}} </td>
      </ng-container>
   <ng-container matColumnDef="BusinessArea">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="BusinessArea"> BusinessArea </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}"> {{element.businessArea}} </td>
        </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" style="font-family: ABBvoice;">No data matching the Search </td>
      </tr>
   </table>
  </div>
        <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" aria-label="Select page of Business Resource"></mat-paginator>
 </div>

</mat-card> 


<div #dummyTable style="display: none;"> 
  <div class="table-responsive"> 
    <div class="table-responsive" *ngFor="let workShetTable of workSheetTables"> 
      <table [id]="workShetTable.tableId" mat-table [dataSource]="workShetTable.dataSource" class="mat-elevation-z8"  class="table tblabb"  style="margin: 0px auto;">
        <ng-container matColumnDef="BgColor">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BgColor </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.bgColor}} </td>
        </ng-container>
        <ng-container matColumnDef="FontColor">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> FontColor </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.fontColor}} </td>
        </ng-container>
        <ng-container matColumnDef="FontWeight">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> FontWeight </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.fontWeight}} </td>
        </ng-container>
        <ng-container matColumnDef="ActivityId">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Id </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.activityId}} </td>
        </ng-container>
        <ng-container matColumnDef="SourceOfActivity">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Source of Activity </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.sourceOfActivity}} </td>
        </ng-container>
        <ng-container matColumnDef="CatBatReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > CAT/ BAT Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.catBatReference}} </td>
        </ng-container>
        <ng-container matColumnDef="CPReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> CP Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.cpReference)">  </td>   
        </ng-container>
        <ng-container matColumnDef="MIReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> MI Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.miReference)">  </td>  
        </ng-container>
        <ng-container matColumnDef="BRReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BR Reference </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.brReference)">  </td>  
        </ng-container>
        <ng-container matColumnDef="Tool">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Tool/System/Portal </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; ">  {{element.tool}} </td>
        </ng-container>
        <ng-container matColumnDef="ApplicableToBusiness">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Applicable to business </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.applicableToBusiness}} </td>
        </ng-container>
        <ng-container matColumnDef="Activity" style="min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center !important;min-width: 300px;padding-left: 120px;" > Activity </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; "> {{element.activity}} </td>
        </ng-container>
        <ng-container matColumnDef="Responsible">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > Responsible </th>
         <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.responsible)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Accountable" >
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;" > Accountable </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.accountable)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Consult" >
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;" > Consult </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.consult)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Inform">
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;"> Inform </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.inform)">  </td> 
        </ng-container>     
        <ng-container matColumnDef="Remark" style="text-align: center;min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;min-width: 300px;padding-left: 120px;"> Remark </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; " [innerHTML]="addHyperlink(element.remark)">  </td> 
        </ng-container>
        <ng-container matColumnDef="ActivityFormingPartOfLat">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > Activity forming part of LAT? </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.activityFormingPartOfLat}} </td>
        </ng-container>
      <ng-container matColumnDef="BusinessArea">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BusinessArea </th>
            <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.businessArea}} </td>
          </ng-container>
          <ng-container matColumnDef="BusinessAreaCode">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BusinessAreaCode </th>
            <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.businessAreaCode}} </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns_Dummy"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns_Dummy"></tr>
      
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter </td>
        </tr>
      </table>
    </div>
</div>

</div> 
<div #preamble style="display: none;"></div>
<div #readDocument style="display: none;"></div>


