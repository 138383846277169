<mat-card>
    <div > 
      <h4 class="col-md-8 Head" style="font-family: ABBvoice;">Admin Role Mapping</h4>
      <button mat-raised-button style="font-family: ABBvoice;" class="col-md-4 dialogButton" 
      type="submit" (click)="openDialogBox()">Add</button>
    </div>
</mat-card>


<mat-card > 
  
    <div class="col-md-3 col-lg-3 col-sm-12">
      <div mat-dialog-actions >
          <mat-form-field appearance="outline">
            <mat-label style="font-family: ABBvoice;">Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
          </mat-form-field>
        </div>
    </div>

  <div class="table-container">
    <div class="table-responsive">
   
     <table mat-table class="mat-elevation-z8" [dataSource]="dataSource" #empTbSort="matSort"
       class="table tblabb" style="margin: 0px auto;" matSort>

        <ng-container matColumnDef="userId" >
            <th class="transactionHeader" mat-header-cell [hidden]="show" *matHeaderCellDef mat-sort-header>Id</th>
            <td class="transactionBody" mat-cell [hidden]="show" *matCellDef="let element">{{ element.userId }}</td>
        </ng-container>
      
        <ng-container matColumnDef="firstName">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>
   
        <ng-container matColumnDef="lastName">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
            <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>
    
        <ng-container matColumnDef="email">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        
       <!-- <ng-container matColumnDef="mobile">
         <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
         <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.mobile }}</td>
       </ng-container> -->

       <ng-container matColumnDef="isActive">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header>IsAdmin</th>
        <td class="transactionBody" mat-cell *matCellDef="let element">{{ element.isActive ? 'Yes' : 'No' }}</td>
      </ng-container>

        <ng-container matColumnDef="action">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 20px;">Action</th>
          <td class="transactionBody" mat-cell *matCellDef="let element; let i = index">
            <button mat-icon-button >
              <mat-icon mat-icon-button color='primary' (click)="loadResourceToEdit(element)" >edit</mat-icon>
            </button>
          </td>
        </ng-container>

       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
 
       <tr class="mat-row" *matNoDataRow>
         <td class="mat-cell" colspan="4" style="font-family: ABBvoice;">No data matching the filter </td>
       </tr>
     </table>
     <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" aria-label="Select page of Business Resource"></mat-paginator>
    </div>
  </div>
</mat-card>