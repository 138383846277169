
      <h4 class="Head" style="font-family: ABBvoice;">Entity Master</h4>
   
    <form class="example-form" [formGroup]="formInstance">
      <div  class="content">
        <div class="row">
          <div hidden="true" class="col-md-6">
              <mat-form-field appearance="outline"> 
                  <mat-label style="font-family: ABBvoice;">Id</mat-label>            
                  <input matInput type="text" [(ngModel)]='entityModel.id'
                  placeholder="Ex. Id" formControlName="id">
              </mat-form-field>
          </div>   
          <div class="col-md-6">
            <mat-form-field appearance="outline">  
              <mat-label style="font-family: ABBvoice;">Entity Name</mat-label>           
              <input matInput type="text" [(ngModel)]='entityModel.name' [errorStateMatcher]="customErrorStateMatcher"
               placeholder="Ex. Name" required formControlName="name" style="font-family: ABBvoice;">
               <mat-error *ngIf="formInstance.get('name').hasError('specialCharacters')">Name should not contain special characters</mat-error>
               <mat-error *ngIf="formInstance.get('name').invalid && formInstance.get('name').hasError('required') && !formInstance.get('name').hasError('specialCharacters')">Name is required</mat-error>
            </mat-form-field>
          </div> <br>
          <div class="col-md-6">
            <mat-form-field appearance="outline">  
              <mat-label style="font-family: ABBvoice;">Entity Code</mat-label>           
              <input matInput type="text" [(ngModel)]='entityModel.code' [disabled]="isCodeDisabled"
               placeholder="Ex. Code" required formControlName="code" style="font-family: ABBvoice;" >
              <mat-error *ngIf="formInstance.controls['code'].errors">Code is required</mat-error>
            </mat-form-field>
          </div>   <br>
        </div>
        <div class="row">   
          <div class="col-md-6">
            <mat-form-field appearance="outline">  
                <mat-label style="font-family: ABBvoice;">Description</mat-label>           
                <textarea matInput [(ngModel)]="entityModel.description" placeholder="Ex. Description" formControlName="description" style="font-family: ABBvoice;"></textarea>
              </mat-form-field>
          </div>
          <div class="col-md-6">
                <mat-checkbox formControlName="status" [(ngModel)]='entityModel.status'>
                <span style="font-family: ABBvoice;">Active</span> 
                </mat-checkbox>                   
          </div>      
        </div><br>       
        <div class="row button">
          <div mat-dialog-actions align="center" class="action" >
            <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
             type="submit">Save</button>
            <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
             type="submit">Update</button>
            <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
          </div>
        </div>
       <br><br>
      </div>
    </form>  
 
  