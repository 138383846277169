<mat-card>
  <div class="row">
    <div class="col-10">
      <h4
      class
      ="col-md-12 Head"
      style
      ="padding: 0px 0px 20px 20px;font-size: 16px; font-family: ABBvoice;">Data Mapping for Business Area:<b>{{businessName}}</b>; Activity: <b>{{activityName}}</b></h4>
    </div>
    
        <div class="col-2" style="text-align: right;">
      <mat-icon (click)="close()" class="closeBtn" matTooltip="Close" aria-label="Example icon-button with a heart icon">close</mat-icon>
    </div>


    <form class="ex-form"  [formGroup]="formInstance">
      <div class="content">

      <!--   <div class="example-action-buttons">
          
        </div>
        <br> -->
        <div class="row button" >
          <div mat-dialog-actions align="left" class="action" >
            <button mat-raised-button class="buttonABB" color="primary"
            style="font-family: ABBvoice;margin-left:10px;margin-right:10px;" (click)="accordion.openAll()">Expand
            All</button>
          <button mat-raised-button class="buttonABB" color="primary" style="font-family: ABBvoice;"
            (click)="accordion.closeAll()">Collapse All</button>
          </div>
        </div>
        <br>
        <mat-accordion class="example-headers-align" multi>

 <!--********************** Sr Number**************** -->
 <mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title style="font-family: ABBvoice;">
      Serial Number
    </mat-panel-title>

    <mat-panel-description>

      <!-- <mat-icon>account_circle</mat-icon> -->
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field appearance="outline">
    <!--  <mat-label style="font-family: ABBvoice;">Source Of Activity</mat-label> -->
    <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedSrNumberOfActivityControl">
      <mat-option *ngFor="let srNumber of SrNumberList" [value]="srNumber.id">
        {{srNumber.refText}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-expansion-panel>


          <!--********************** Source Of Activity**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Source Of Activity
              </mat-panel-title>

              <mat-panel-description>

                <!-- <mat-icon>account_circle</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <!--  <mat-label style="font-family: ABBvoice;">Source Of Activity</mat-label> -->
              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedSourceOfActivityControl">
                <mat-option *ngFor="let sourceOfActivity of sourceOfActivityLst" [value]="sourceOfActivity.id">
                  {{sourceOfActivity.refText}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-expansion-panel>
          <!--********************** CAT/ BAT reference**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                CAT/ BAT reference
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>map</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">

              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedCatBatReferenceControl">
                <mat-option *ngFor="let catBatReference of catBatReferenceLst" [value]="catBatReference.id">
                  {{catBatReference.refText}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </mat-expansion-panel>
          <!--********************** Tool / System / Portal**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Tool / System / Portal
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <mat-label style="font-family: ABBvoice;"></mat-label>
              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedToolControl">
                <mat-option *ngFor="let tool of toolLst" [value]="tool.id">
                  {{tool.refText}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </mat-expansion-panel>

          <!--**********************  Applicable to business**************** -->


          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Applicable to business
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <mat-label style="font-family: ABBvoice;"></mat-label>
              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedApplicableToBusinessControl">
                <mat-option *ngFor="let applicableToBusiness of applicableToBusinessLst"
                  [value]="applicableToBusiness.id">
                  {{applicableToBusiness.refText}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </mat-expansion-panel>

          <!--**********************  Activity forming part of LAT?**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Activity forming part of LAT?
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">

              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectedActivityFormingPartControl">
                <mat-option *ngFor="let activityFormingPartOfLat of activityFormingPartOfLatLst"
                  [value]="activityFormingPartOfLat.id">
                  {{activityFormingPartOfLat.refText}}
                </mat-option>
              </mat-select>

            </mat-form-field>

          </mat-expansion-panel>

          <!--**********************  CP Reference**************** -->

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                CPReference
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <!--    <mat-label style="font-family: ABBvoice;">Select CPReference</mat-label> -->
              <mat-select #select required style=" font-family: ABBvoice;"
                (openedCPReferenceChange)="openedCPReferenceChange($event)" placeholder="Select CP Reference"
                [formControl]="selectCPReferenceFormControl" multiple>
                <mat-select-trigger>
                  {{getSelectedCPReference()}}
                </mat-select-trigger>
                <div class="select-container">
                  <mat-optgroup>
                    <mat-form-field style="width:100%;">
                      <input #searchCPReference autocomplete="off" placeholder="Type to Search..." aria-label="Search"
                        matInput [formControl]="searchCPReferenceTextboxControl">
                      <button [disableRipple]="true" *ngIf="searchCPReference.value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="clearCPReferenceSearch($event)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-optgroup>
                  <mat-optgroup *ngIf="(CPReferenceFilteredOptions | async).length == 0">
                    <div>No results found!</div>
                  </mat-optgroup>
                  <mat-option (onSelectionChange)="CPReferenceSelectionChange($event)"
                    *ngFor="let CPReference of CPReferenceFilteredOptions | async" [value]="CPReference">
                    {{CPReference.refText}}

                  </mat-option>
                </div>
              </mat-select>

            </mat-form-field>

          </mat-expansion-panel>


          <!--**********************  MI Reference**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                MIReference
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <mat-select #select required style=" font-family: ABBvoice;"
                (openedMIReferenceChange)="openedMIReferenceChange($event)" placeholder="Select MI Reference"
                [formControl]="selectMIReferenceFormControl" multiple>
                <mat-select-trigger>
                  {{getSelectedMIReference()}}
                </mat-select-trigger>
                <div class="select-container">
                  <mat-optgroup>
                    <mat-form-field style="width:100%;">
                      <input #searchMIReference autocomplete="off" placeholder="Type to Search..." aria-label="Search"
                        matInput [formControl]="searchMIReferenceTextboxControl">
                      <button [disableRipple]="true" *ngIf="searchMIReference.value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="clearMIReferenceSearch($event)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-optgroup>
                  <mat-optgroup *ngIf="(MIReferenceFilteredOptions | async).length == 0">
                    <div>No results found!</div>
                  </mat-optgroup>
                  <mat-option (onSelectionChange)="MIReferenceSelectionChange($event)"
                    *ngFor="let MIReference of MIReferenceFilteredOptions | async" [value]="MIReference">
                    {{MIReference.refText}}
                  </mat-option>
                </div>
              </mat-select>

            </mat-form-field>

          </mat-expansion-panel>


          <!--**********************  BR Reference**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                BR Reference
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">

              <mat-select #select required style=" font-family: ABBvoice;"
                (openedBRReferenceChange)="openedBRReferenceChange($event)" placeholder="Select BR Reference"
                [formControl]="selectBRReferenceFormControl" multiple>
                <mat-select-trigger>
                  {{getSelectedBRReference()}}
                </mat-select-trigger>
                <div class="select-container">
                  <mat-optgroup>
                    <mat-form-field style="width:100%;">
                      <input #searchBRReference autocomplete="off" placeholder="Type to Search..." aria-label="Search"
                        matInput [formControl]="searchBRReferenceTextboxControl">
                      <button [disableRipple]="true" *ngIf="searchBRReference.value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="clearBRReferenceSearch($event)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-optgroup>
                  <mat-optgroup *ngIf="(BRReferenceFilteredOptions | async).length == 0">
                    <div>No results found!</div>
                  </mat-optgroup>
                  <mat-option (onSelectionChange)="BRReferenceSelectionChange($event)"
                    *ngFor="let BRReference of BRReferenceFilteredOptions | async" [value]="BRReference">
                    {{BRReference.refText}}
                  </mat-option>
                </div>
              </mat-select>

            </mat-form-field>

          </mat-expansion-panel>

          <!--**********************  Responsible**************** -->


          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Responsible
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Responsible(Links)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedresponsibleWithLinksChange)="openedresponsibleWithLinksChange($event)"
                  placeholder="Select Responsible(Links)" [formControl]="selectresponsibleWithLinksFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedresponsibleWithLinks()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchresponsibleWithLinks autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchresponsibleWithLinksTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchresponsibleWithLinks.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearresponsibleWithLinksSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(responsibleWithLinksFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="responsibleWithLinksSelectionChange($event)"
                      *ngFor="let responsibleWithLinks of responsibleWithLinksFilteredOptions | async"
                      [value]="responsibleWithLinks">
                      {{responsibleWithLinks.refText}}

                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Responsible(Plain Text)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedresponsibleWithPlainTextChange)="openedresponsibleWithPlainTextChange($event)"
                  placeholder="Select Responsible(Plain Text)" [formControl]="selectresponsibleWithPlainTextFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedresponsibleWithPlainText()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchresponsibleWithPlainText autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchresponsibleWithPlainTextTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchresponsibleWithPlainText.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearresponsibleWithPlainTextSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(responsibleWithPlainTextFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="responsibleWithPlainTextSelectionChange($event)"
                      *ngFor="let responsibleWithPlainText of responsibleWithPlainTextFilteredOptions | async"
                      [value]="responsibleWithPlainText">
                      {{responsibleWithPlainText.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Responsible(Designation)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedresponsibleWithDesignationChange)="openedresponsibleWithDesignationChange($event)"
                  placeholder="Select Responsible(Designation)"
                  [formControl]="selectresponsibleWithDesignationFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedresponsibleWithDesignation()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchresponsibleWithDesignation autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchresponsibleWithDesignationTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchresponsibleWithDesignation.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearresponsibleWithDesignationSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(responsibleWithDesignationFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="responsibleWithDesignationSelectionChange($event)"
                      *ngFor="let responsibleWithDesignation of responsibleWithDesignationFilteredOptions | async"
                      [value]="responsibleWithDesignation">
                      {{responsibleWithDesignation.refText}}

                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
          </mat-expansion-panel>

          <!--**********************  Accountable**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Accountable
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">

              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Accountable(Links)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedaccountableWithLinksChange)="openedaccountableWithLinksChange($event)"
                  placeholder="Select Accountable(Links)" [formControl]="selectaccountableWithLinksFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedaccountableWithLinks()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchaccountableWithLinks autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchaccountableWithLinksTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchaccountableWithLinks.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearaccountableWithLinksSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(accountableWithLinksFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="accountableWithLinksSelectionChange($event)"
                      *ngFor="let accountableWithLinks of accountableWithLinksFilteredOptions | async"
                      [value]="accountableWithLinks">
                      {{accountableWithLinks.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Accountable(Plain Text)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedaccountableWithPlainTextChange)="openedaccountableWithPlainTextChange($event)"
                  placeholder="Select Accountable(Plain Text)" [formControl]="selectaccountableWithPlainTextFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedaccountableWithPlainText()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchaccountableWithPlainText autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchaccountableWithPlainTextTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchaccountableWithPlainText.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearaccountableWithPlainTextSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(accountableWithPlainTextFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="accountableWithPlainTextSelectionChange($event)"
                      *ngFor="let accountableWithPlainText of accountableWithPlainTextFilteredOptions | async"
                      [value]="accountableWithPlainText">
                      {{accountableWithPlainText.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Accountable(Designation)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedaccountableWithDesignationChange)="openedaccountableWithDesignationChange($event)"
                  placeholder="Select Accountable(Designation)"
                  [formControl]="selectaccountableWithDesignationFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedaccountableWithDesignation()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchaccountableWithDesignation autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchaccountableWithDesignationTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchaccountableWithDesignation.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearaccountableWithDesignationSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(accountableWithDesignationFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="accountableWithDesignationSelectionChange($event)"
                      *ngFor="let accountableWithDesignation of accountableWithDesignationFilteredOptions | async"
                      [value]="accountableWithDesignation">
                      {{accountableWithDesignation.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>

          </mat-expansion-panel>

          <!--**********************  Consult**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Consult
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Consult(Links)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedconsultWithLinksChange)="openedconsultWithLinksChange($event)"
                  placeholder="Select Consult(Links)" [formControl]="selectconsultWithLinksFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedconsultWithLinks()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchconsultWithLinks autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchconsultWithLinksTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchconsultWithLinks.value" matSuffix mat-icon-button
                          aria-label="Clear" (click)="clearconsultWithLinksSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(consultWithLinksFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="consultWithLinksSelectionChange($event)"
                      *ngFor="let consultWithLinks of consultWithLinksFilteredOptions | async"
                      [value]="consultWithLinks">
                      {{consultWithLinks.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Consult(Plain Text)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedconsultWithPlainTextChange)="openedconsultWithPlainTextChange($event)"
                  placeholder="Select Consult(Plain Text)" [formControl]="selectconsultWithPlainTextFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedconsultWithPlainText()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchconsultWithPlainText autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchconsultWithPlainTextTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchconsultWithPlainText.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearconsultWithPlainTextSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(consultWithPlainTextFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="consultWithPlainTextSelectionChange($event)"
                      *ngFor="let consultWithPlainText of consultWithPlainTextFilteredOptions | async"
                      [value]="consultWithPlainText">
                      {{consultWithPlainText.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Consult(Designation)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedconsultWithDesignationChange)="openedconsultWithDesignationChange($event)"
                  placeholder="Select Consult(Designation)" [formControl]="selectconsultWithDesignationFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedconsultWithDesignation()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchconsultWithDesignation autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchconsultWithDesignationTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchconsultWithDesignation.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearconsultWithDesignationSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(consultWithDesignationFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="consultWithDesignationSelectionChange($event)"
                      *ngFor="let consultWithDesignation of consultWithDesignationFilteredOptions | async"
                      [value]="consultWithDesignation">
                      {{consultWithDesignation.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>


          </mat-expansion-panel>

          <!--**********************  Inform**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Inform
              </mat-panel-title>
              <mat-panel-description>


                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Inform(Links)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedinformWithLinksChange)="openedinformWithLinksChange($event)" placeholder="Select Inform(Links)"
                  [formControl]="selectinformWithLinksFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedinformWithLinks()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchinformWithLinks autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchinformWithLinksTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchinformWithLinks.value" matSuffix mat-icon-button
                          aria-label="Clear" (click)="clearinformWithLinksSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(informWithLinksFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="informWithLinksSelectionChange($event)"
                      *ngFor="let informWithLinks of informWithLinksFilteredOptions | async" [value]="informWithLinks">
                      {{informWithLinks.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Inform(Plain Text)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedinformWithPlainTextChange)="openedinformWithPlainTextChange($event)"
                  placeholder="Select Inform(Plain Text)" [formControl]="selectinformWithPlainTextFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedinformWithPlainText()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchinformWithPlainText autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchinformWithPlainTextTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchinformWithPlainText.value" matSuffix mat-icon-button
                          aria-label="Clear" (click)="clearinformWithPlainTextSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(informWithPlainTextFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="informWithPlainTextSelectionChange($event)"
                      *ngFor="let informWithPlainText of informWithPlainTextFilteredOptions | async"
                      [value]="informWithPlainText">
                      {{informWithPlainText.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Inform(Designation)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedinformWithDesignationChange)="openedinformWithDesignationChange($event)"
                  placeholder="Select Inform(Designation)" [formControl]="selectinformWithDesignationFormControl"
                  multiple>
                  <mat-select-trigger>
                    {{getSelectedinformWithDesignation()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchinformWithDesignation autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchinformWithDesignationTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchinformWithDesignation.value" matSuffix
                          mat-icon-button aria-label="Clear" (click)="clearinformWithDesignationSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(informWithDesignationFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="informWithDesignationSelectionChange($event)"
                      *ngFor="let informWithDesignation of informWithDesignationFilteredOptions | async"
                      [value]="informWithDesignation">
                      {{informWithDesignation.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>


          </mat-expansion-panel>

          <!--**********************  Remark**************** -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title style="font-family: ABBvoice;">
                Remark
              </mat-panel-title>
              <mat-panel-description>

                <!-- <mat-icon>date_range</mat-icon> -->
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- <div class="row" style="display: none;">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Remark(Links)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedremarkWithLinksChange)="openedremarkWithLinksChange($event)" placeholder="Select Remark(Links)"
                  [formControl]="selectremarkWithLinksFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedremarkWithLinks()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchremarkWithLinks autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchremarkWithLinksTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchremarkWithLinks.value" matSuffix mat-icon-button
                          aria-label="Clear" (click)="clearremarkWithLinksSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(remarkWithLinksFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="remarkWithLinksSelectionChange($event)"
                      *ngFor="let remarkWithLinks of remarkWithLinksFilteredOptions | async" [value]="remarkWithLinks">
                      {{remarkWithLinks.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Select Remark(Plain Text)</mat-label>
                <mat-select #select style=" font-family: ABBvoice;"
                  (openedremarkWithPlainTextChange)="openedremarkWithPlainTextChange($event)"
                  placeholder="Select Remark(Plain Text)" [formControl]="selectremarkWithPlainTextFormControl" multiple>
                  <mat-select-trigger>
                    {{getSelectedremarkWithPlainText()}}
                  </mat-select-trigger>
                  <div class="select-container">
                    <mat-optgroup>
                      <mat-form-field style="width:100%;">
                        <input #searchremarkWithPlainText autocomplete="off" placeholder="Type to Search..."
                          aria-label="Search" matInput [formControl]="searchremarkWithPlainTextTextboxControl">
                        <button [disableRipple]="true" *ngIf="searchremarkWithPlainText.value" matSuffix mat-icon-button
                          aria-label="Clear" (click)="clearremarkWithPlainTextSearch($event)">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(remarkWithPlainTextFilteredOptions | async).length == 0">
                      <div>No results found!</div>
                    </mat-optgroup>
                    <mat-option (onSelectionChange)="remarkWithPlainTextSelectionChange($event)"
                      *ngFor="let remarkWithPlainText of remarkWithPlainTextFilteredOptions | async"
                      [value]="remarkWithPlainText" matTooltip={{remarkWithPlainText.refText}}>
                      {{remarkWithPlainText.refText}}
                    </mat-option>
                  </div>
                </mat-select>

              </mat-form-field>
            </div> -->
            <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
              <mat-form-field appearance="outline"> 
                  <mat-label>Id</mat-label>            
                  <input matInput type="text" [formControl]="remarkId"
                   formControlName="ID">
              </mat-form-field>
          </div>   
            <div class="row" >
              <ngx-editor-menu [editor]="remarksEditor" [toolbar]="toolbar" ></ngx-editor-menu>
              <ngx-editor [editor]="remarksEditor" [formControl]="remarkText" ></ngx-editor>
               <!--  [(ngModel)]="remarkText" -->



            </div>
          </mat-expansion-panel>
        </mat-accordion>





      </div>
      <br>

      <div class="row button">
        <div mat-dialog-actions align="center" class="action">
          <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px"
            (click)="Submit()">Create Draft</button>
        </div>
      </div>
      <br><br>
    </form>
  </div>
</mat-card>
<mat-card>

  <div class="row">

    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" class="table tblabb" #empTbSort="matSort"
        matSort style="margin: 0px auto;">

        <ng-container matColumnDef="ActivityId">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="activityId"> Id </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;">
            {{element.activityId}} </td>
        </ng-container>
           <ng-container matColumnDef="SourceOfActivity">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="sourceOfActivity"> Source of Activity </th>
        <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.sourceOfActivity}} </td>
      </ng-container> 
        <ng-container matColumnDef="CatBatReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="catBatReference"> CAT/ BAT Reference </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.catBatReference}} </td>
        </ng-container>
        <ng-container matColumnDef="CPReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="cpReference"> CP Reference </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;  "
            [innerHTML]="addHyperlink(element.cpReference)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>
        <ng-container matColumnDef="MIReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="miReference"> MI Reference </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [innerHTML]="addHyperlink(element.miReference)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>
           <ng-container matColumnDef="BRReference">
        <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;" mat-sort-header="brReference"> BR Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.brReference)" [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">  </td>  
      </ng-container> 
        <ng-container matColumnDef="Tool">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="tool"> Tool/System/Portal </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.tool}} </td>
        </ng-container>
        <ng-container matColumnDef="ApplicableToBusiness">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="applicableToBusiness"> Applicable to business </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.applicableToBusiness}} </td>
        </ng-container>
        <ng-container matColumnDef="Activity" style="min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: left !important;min-width: 300px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="activity"> Activity </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: left;min-width: 300px; "
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.activity}} </td>
        </ng-container>
        <ng-container matColumnDef="Responsible">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="responsible"> Responsible </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [innerHTML]="addHyperlink(element.responsible)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>

        <ng-container matColumnDef="Accountable">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="accountable"> Accountable </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [innerHTML]="addHyperlink(element.accountable)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>

        <ng-container matColumnDef="Consult">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="consult"> Consult </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center; "
            [innerHTML]="addHyperlink(element.consult)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>

        <ng-container matColumnDef="Inform" style="text-align: center;min-width: 150px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;min-width: 150px;padding-left: 50px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="inform"> Inform </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;min-width: 150px;  "
            [innerHTML]="addHyperlink(element.inform)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>
        <ng-container matColumnDef="Remark" style="text-align: center;min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;min-width: 300px;padding-left: 116px;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="remark"> Remark </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; "
            [innerHTML]="addHyperlink(element.remark)"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
          </td>
        </ng-container>
        <ng-container matColumnDef="ActivityFormingPartOfLat">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="activityFormingPartOfLat"> Activity forming part of LAT? </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.activityFormingPartOfLat}} </td>
        </ng-container>
        <ng-container matColumnDef="BusinessArea" >
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef
            style="text-align: center;background-color:#ff000f;color:white;font-size:16px;font-family: ABBvoice;"
            mat-sort-header="businessArea"> BusinessArea </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"
            [ngStyle]="{'background-color': element.bgColor ,'color': element.fontColor,'font-weight':element.fontWeight}">
            {{element.businessArea}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Please select the filter for data. </td>
        </tr>
      </table>
    </div>
    <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
      aria-label="Select page of Business Resource"></mat-paginator>
  </div>

</mat-card>