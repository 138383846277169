<!-- <div style="height: auto; font-weight: bold;"><h2 class="Head1">Forgot Password</h2></div> -->

<div class="row">
  <div class="col-10">
    <h2 class="Head1">Report Issue</h2>
  </div>
  <div class="col-2" style="text-align: right;"> 
    <mat-icon (click)="close()" class="closeBtn" matTooltip="Close" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </div> 
</div> 
<div class="row" style="padding-top: 3%;">
    <div class="col-12">
        <form class="example-form">
            <mat-form-field class="example-full-width" appearance="outline" ngDefaultControl >
              <mat-label>Feedback</mat-label>
              <textarea matInput maxlength="250" [(ngModel)]="feedback" required></textarea>
            
            </mat-form-field>
          </form>
  
    </div>
    <div class="col-12" style="padding-left: 36% ;">
        <button type="submit" class="buttonABB" (click)="onsubmit()">Submit</button>
    </div>
  </div>
