<form class="ex-form" #myForm="ngForm" [formGroup]="formInstance">
<mat-card> 
  <!--   <div > 
      <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;">
        Search Activity Details</h4>
    </div> -->

    <div class="row">
      <div class="col-md-10 col-lg-10 col-sm-10">
  
        <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;">
         Mapping Activity Details</h4>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-2">
        <p class="text-center"><a routerLink="/ActivityMaster" title="Create new Activity" mat-raised-button
            class="buttonABB" color="primary"
            style="margin-left: 5px;padding-left:10px;padding-right:10px;"><mat-icon>add</mat-icon>Create activity</a></p>
           
        <!-- <mat-icon>arrow_back_ios</mat-icon> -->
      </div>
  
    </div>
    <div class="row" *ngIf="showHistory"> 
      <div class="col-md-2 col-lg-2 col-sm-2" style="padding-left: 970px;">
        <p class="text-center"><a (click)="createHistoryClick()" title="Create History" mat-raised-button
            class="buttonABB" color="primary"
            style="margin-left: 5px;padding-left:10px;padding-right:10px;">Publish</a></p>
           
        
      </div>
    </div>


   
     <div  class="content">
      <div class="row" >
        <div class="col-md-3 col-lg-3 col-sm-12">
          <form class="example-form">
            <mat-form-field appearance="outline"> 
              <mat-label style="font-family: ABBvoice;">Select Entity</mat-label>           
              <mat-select required style=" font-family: ABBvoice;" [formControl]="selectEntityFormControl">
                <mat-option *ngFor="let entity of entityList" [value]="entity" (click)="entitySelection(entity.id)">{{entity.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label style="font-family: ABBvoice;">Select Business Area</mat-label>
        <mat-select required style="font-family: ABBvoice;" (selectionChange)="selectedValue($event)"  [formControl]="businessFormControl">
       <mat-option *ngFor="let business of businessList" [value]="business.id" (click)="businessAreaSelection()" >{{business.name}}</mat-option>
      </mat-select>
     
      

          </mat-form-field>
        </div>
        <div *ngIf="showHistory">
          <mat-icon class="download_icon" title="Download draft data to Excel" (click)="exportExcel()">get_app</mat-icon> 
        </div>
      </div>

<!--Start==> Activity List in Dropdown with checkbox -->
    <!--   <div class="row" style="margin-bottom: 1%;margin-top: 1%;">
        <div class="col-md-6"  style="display: flex; justify-content: left">
          <form class="example-form">
            <mat-form-field class="example-full-width" appearance="outline" >
              <input  id="txtTreeViewSelected"  placeholder="{{getSelectedItemsList}}" aria-label="Number" 
              matInput [matAutocomplete]="auto" [(ngModel)]="searchValue"
              (keyup)="applyFilterForTree($event)" > 
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                <mat-option disabled >Please select an item from below</mat-option>
                <mat-tree #tree [dataSource]="dataSource_Tree" [treeControl]="treeControl" class="example-tree">
                  
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node">
                      
                      <button mat-icon-button disabled></button>
                          <mat-checkbox class="checklist-leaf-node" 
                          [checked]="checklistSelection.isSelected(node)"
                    (change)="checklistSelection.toggle(node);"
                    
                >{{node.text}}</mat-checkbox>                
                    </li>
                  </mat-tree-node>
                  
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
                    <li>
                      <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.text">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                            <mat-checkbox 
                            [checked]="descendantsAllSelected(node)"
                            [indeterminate]="descendantsPartiallySelected(node)"
                                  (change)="todoItemSelectionToggle($event.checked,node)"
                                  
                                  >{{node.text}}</mat-checkbox>
                      </div>
                       <ul [class.example-tree-invisible]="treeControl.isExpanded(node)"> 
                        <ng-container matTreeNodeOutlet></ng-container>
                      </ul>
                    </li>
                  </mat-nested-tree-node>
                </mat-tree>
              </mat-autocomplete>
             
              <button type="button" mat-button  matSuffix mat-icon-button aria-label="Clear"   (click)="ClearSelection()">
                <mat-icon >close</mat-icon>
              </button>
            </mat-form-field> 
          </form>
        </div>
      </div> -->
<!--End==> Activity List in Dropdown with checkbox -->


      <div class="row" mat-dialog-actions align="center" class="action">
<!--           <button  mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="reset()">Reset</button>  -->
<!-- <p class="text-center"><a routerLink="/ActivityMaster">Create an Activity</a></p> -->
      </div>
     </div>
    
  </mat-card>
  <br>
<mat-card>
  <div class="row">
     
          <!-- <div class="col-md-12 col-lg-10 col-sm-12"> -->
            <div class="col-md-6 col-lg-6 col-sm-8" >
              <mat-card>
              <div > 
                  <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 16px;">
                    Select Activity to map with Buisness Area</h4>
                </div>
    
            <div class="row">
              <div class="col-md-7 col-lg-7 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Search activity!</mat-label>
              <input  matInput type="text" width="60px" formControlName="searchString_new" />
            </mat-form-field>
          </div>
          <div class="col-md-5 col-lg-5 col-sm-12">
              <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Collapse All"
              style="margin-left: 8px;margin-right: 8px;padding-left: 10px;padding-right: 10px;"
              (click)="tree_new.treeControl.collapseAll()"><mat-icon>expand_more</mat-icon></button>
            <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Expand All"
              style="margin-right: 8px;padding-left: 5px;padding-right: 5px;"
              (click)="tree_new.treeControl.expandAll()"><mat-icon>chevron_right</mat-icon></button>
              <button mat-raised-button class="buttonABB"  color="primary" matTooltip="Map activity"
              style="margin-left: 5px;padding-left:10px;padding-right:10px;"
              (click)="addActivityToBusinessArea()"><mat-icon>add</mat-icon>Map</button>
              <!-- <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="addActivityToBusinessArea()">+</button> -->
            </div>
          </div>
              <mat-tree #tree_new [dataSource]="dataSource_Tree_new" [treeControl]="treeControl_new" class="example-tree">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle  
                  [style.display]="filterLeafNode_new(node) ? 'none' : 'block'" >
                    <li class="mat-tree-node">
                      <!-- use a disabled button to provide padding for tree leaf -->
                      <button mat-icon-button disabled></button>
                          <mat-checkbox class="checklist-leaf-node" 
                          [checked]="checklistSelection.isSelected(node)"
                    (change)="checklistSelection.toggle(node);"  ><span style="font-family: ABBvoice;">{{node.text}}</span></mat-checkbox>                
                    </li>
                  </mat-tree-node>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" 
                  [style.display]="filterParentNode_new(node) ? 'none' : 'block'" >
                    <li>
                      <div class="mat-tree-node" >
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.text">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl_new.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                            <mat-checkbox 
                            [checked]="descendantsAllSelected(node)"
                            [indeterminate]="descendantsPartiallySelected(node)"
                           (change)="todoItemSelectionToggle(node)" 
                           
                            ><span style="font-family: ABBvoice;">{{node.text}}</span></mat-checkbox>
                      </div>
                       <ul [class.example-tree-invisible]="!treeControl_new.isExpanded(node)"> 
                        <ng-container matTreeNodeOutlet></ng-container>
                      </ul>
                    </li>
                  </mat-nested-tree-node>
                </mat-tree>
  
  
  
                
     <!--  (change)="todoItemSelectionToggle($event.checked,node)"  -->
     
              </mat-card>
          </div>
          
          <!-- ********************************************************************************************* -->
        
        <div class="col-md-6 col-lg-6 col-sm-8"  >
          <mat-card> 
            <div > 
              <h4 class="Head" style="padding: 0px 0px 20px 20px;font-size: 16px;">
                Activities mapped with Business Area: <span style="font-weight: bold;">{{selectedData.text}}</span>
                
              </h4>
            </div>
          <!-- <input [(ngModel)]="searchString" placeholder="Search Tree node" width="100px" />  -->
          <div class="row">
            <div class="col-md-7 col-lg-7 col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Search activity!</mat-label>
            <input  matInput type="text" placeholder="Search Tree node" formControlName="searchString" />
          </mat-form-field>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12">
          <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Collapse All"
          style="margin-left: 8px;margin-right: 8px;padding-left: 10px;padding-right: 10px;"
          (click)="tree.treeControl.collapseAll()"><mat-icon>expand_more</mat-icon></button>
        <button mat-raised-button class="buttonABB" mat-icon-button color="primary" matTooltip="Expand All"
          style="margin-right: 8px;padding-left: 5px;padding-right: 5px;"
          (click)="tree.treeControl.expandAll()"><mat-icon>chevron_right</mat-icon></button>
        </div>
      </div>
         
        
  
          <!-- <mat-option disabled >Please select an item from below dsfsd</mat-option> -->
       
  <mat-tree #tree [dataSource]="dataSource_Tree" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle style="font-family: ABBvoice;"
    [style.display]="filterLeafNode(node) ? 'none' : 'block'"  [ngClass]="getColor(node)">
      {{node.text}} <button mat-icon-button  (click)="openActivityDataMapping(node)" >
        <mat-icon>edit</mat-icon>
      </button> 
       <button mat-icon-button (click)="DeleteNode(node)" >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild"   style="font-family: ABBvoice;"
    [style.display]="filterParentNode(node) ? 'none' : 'block'" >
       
      <div class="mat-tree-node" style="font-family: ABBvoice;" [ngClass]="getColor(node)">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.text">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.text}} <button mat-icon-button  (click)="openActivityDataMapping(node)" >
          <mat-icon>edit</mat-icon>
        </button>
      
        <button mat-icon-button (click)="DeleteNode(node)" > 
          <mat-icon>delete</mat-icon>
        </button>
        <!-- <button mat-icon-button (click)="loadResourceToEdit(node)" >
          <mat-icon>Delete</mat-icon>
        </button> -->
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"    role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
  </mat-nested-tree-node>
  </mat-tree> 
  <hr>
  <div style="font-family: ABBvoice;color:red;"  *ngIf="isShowNote"  >
    <h1>Note:</h1> A red node indicates that the node has been mapped to a business area but has not yet been assigned data. Sub-activity nodes will not be available on search screen until data is allocated to all parent nodes.
   </div>
</mat-card>
      </div>
      
    </div>
  </mat-card>
</form>

<div #dummyTable > 
  <div class="table-responsive"> 
    <div class="table-responsive" *ngFor="let workShetTable of workSheetTables"> 
      <table [id]="workShetTable.tableId" mat-table [dataSource]="workShetTable.dataSource" class="mat-elevation-z8"  class="table tblabb"  style="margin: 0px auto;">
        <ng-container matColumnDef="BgColor">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BgColor </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.bgColor}} </td>
        </ng-container>
        <ng-container matColumnDef="FontColor">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> FontColor </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.fontColor}} </td>
        </ng-container>
        <ng-container matColumnDef="FontWeight">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> FontWeight </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.fontWeight}} </td>
        </ng-container>
        <ng-container matColumnDef="ActivityId">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Id </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.activityId}} </td>
        </ng-container>
        <ng-container matColumnDef="SourceOfActivity">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Source of Activity </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.sourceOfActivity}} </td>
        </ng-container>
        <ng-container matColumnDef="CatBatReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > CAT/ BAT Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.catBatReference}} </td>
        </ng-container>
        <ng-container matColumnDef="CPReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> CP Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.cpReference)">  </td>   
        </ng-container>
        <ng-container matColumnDef="MIReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> MI Reference </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.miReference)">  </td>  
        </ng-container>
        <ng-container matColumnDef="BRReference">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BR Reference </th>
            <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.brReference)">  </td>  
        </ng-container>
        <ng-container matColumnDef="Tool">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Tool/System/Portal </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; ">  {{element.tool}} </td>
        </ng-container>
        <ng-container matColumnDef="ApplicableToBusiness">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> Applicable to business </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; "> {{element.applicableToBusiness}} </td>
        </ng-container>
        <ng-container matColumnDef="Activity" style="min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center !important;min-width: 300px;padding-left: 120px;" > Activity </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; "> {{element.activity}} </td>
        </ng-container>
        <ng-container matColumnDef="Responsible">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > Responsible </th>
         <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.responsible)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Accountable" >
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;" > Accountable </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.accountable)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Consult" >
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;" > Consult </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.consult)">  </td> 
        </ng-container>
      
        <ng-container matColumnDef="Inform">
          <th class="transactionHeader"  mat-header-cell *matHeaderCellDef style="text-align: center;"> Inform </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center; " [innerHTML]="addHyperlink(element.inform)">  </td> 
        </ng-container>     
        <ng-container matColumnDef="Remark" style="text-align: center;min-width: 300px;">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;min-width: 300px;padding-left: 120px;"> Remark </th>
          <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center;min-width: 300px; " [innerHTML]="addHyperlink(element.remark)">  </td> 
        </ng-container>
        <ng-container matColumnDef="ActivityFormingPartOfLat">
          <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;" > Activity forming part of LAT? </th>
          <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.activityFormingPartOfLat}} </td>
        </ng-container>
      <ng-container matColumnDef="BusinessArea">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BusinessArea </th>
            <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.businessArea}} </td>
          </ng-container>
          <ng-container matColumnDef="BusinessAreaCode">
            <th class="transactionHeader" mat-header-cell *matHeaderCellDef style="text-align: center;"> BusinessAreaCode </th>
            <td class="transactionBody" mat-cell *matCellDef="let element" style="text-align: center;"> {{element.businessAreaCode}} </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns_Dummy"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns_Dummy"></tr>
      
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter </td>
        </tr>
      </table>
    </div>
</div>

</div> 