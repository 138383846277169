import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; 
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AVM';
  
  // enableProdMode();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // constructor(private bnIdle: BnNgIdleService,private _snackBar: MatSnackBar) { // initiate it in your component constructor
  //   this.bnIdle.startWatching(60).subscribe((res) => {
  //     if(res) {
  //         console.log("session expired");

  //         sessionStorage.removeItem("userToken");
  //         this._snackBar.open("Session Timeout, Please login once again",'Close', {
  //           horizontalPosition: this.horizontalPosition,
  //           verticalPosition: this.verticalPosition,
  //           duration: 2 * 1000,
  //           panelClass: ['red-snackbar']
  //       });
  //         setTimeout(() => {
  //           window.location.reload();  
  //         }, 500);
  //     }
  //   })
  // }
}
