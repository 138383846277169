import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterService } from 'src/app/Services/master.service';


export interface DivisonMaster{
  id: number;
  code  : string;
  name  : string;
  description  : string;
  businessAreaName  : string;
  businessAreaId : number;
  status : boolean;
  isActive : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
}


@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss']
})
export class DivisionComponent implements OnInit {

 
  isIdVisible: boolean = false;
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['code','name','description','businessAreaName','status','action'];
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, private divisionServ : MasterService) { }
  
  ngOnInit(): void {
    this.getDivisonMaster();
  }

  openDialogBox(){
    this.isIdVisible = false;
    const dialogRef = this.dialog.open(AddDivisionDialog,{
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getDivisonMaster();
        }
      }
    })
  }

  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  getDivisonMaster(){
    this.divisionServ.getDivisionMaster().subscribe((data:any)=>{
      this.dataSource= new MatTableDataSource<DivisonMaster>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddDivisionDialog,{
     data,
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
       if(val){
         this.getDivisonMaster();
       }
     }
   })
 }

  delete(data: any) {  
    if (confirm("Are you sure you want to delete this")) {  
    this.divisionServ.deleteDivisonMaster(data).subscribe(() => {  
      this.snackBarLoader('Deleted Successfully','Done','1000', 'red-snackbar')
      this.getDivisonMaster();
    });  
   }  
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}




@Component ({
  selector: 'app-dialog-entity',
  templateUrl: './addDivisionDialog.html',
  styleUrls: ['./division.component.scss']
})

  export class AddDivisionDialog implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    createdByUserId : number;
    createdBy : number;
    createdDate : Date;
    businesslst : any[] = [];
    divisionModel: DivisonMaster= {} as DivisonMaster;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddDivisionDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private divisionServ : MasterService) 
    {
      this.formInstance = _formBuilder.group({
        "name": ['', [Validators.required, this.noSpecialCharacters()]], 
        "code":['', Validators.required],
        "description": [''],
        "status": true,
        "divisionControl":['', Validators.required],
      });
    }

  ngOnInit(): void {
    this.divisionModel.status = true;
    if(this.data !=null && this.data !=undefined)
    {
      this.divisionModel.id = this.data.id;
      this.divisionModel.name = this.data.name;
      this.divisionModel.code = this.data.code;
      this.divisionModel.description = this.data.description;
      this.divisionModel.status = this.data.status;
      this.divisionModel.businessAreaId = this.data.businessAreaId;
      this.divisionModel.businessAreaName = this.data.businessAreaName;
      this.createdBy = this.data.createdBy;
      this.createdDate = this.data.createdDate;
      this.isIdVisible = true
    }
    
    console.log('add',this.divisionModel)
    console.log('add data',this.data)
    var emailId = sessionStorage.getItem('LoginEmail');
    this.getUserId(emailId);
    this.getBusinessName();
  }

  
  noSpecialCharacters(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
      if (specialCharacters.test(control.value)) {
        return { 'specialCharacters': true };
      }
      return null;
    };
  }
  
  closepopup(){
    if(confirm("Are you sure to close this")) {
      this.dialogRef.close();
    }
  }

  save(){
    if (this.formInstance.invalid) {
      return;
    }
    console.log('add',this.divisionModel)
    this.divisionModel.createdBy = this.createdByUserId;
    this.divisionServ.saveDivisonData(this.divisionModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  

  update(){
    if (this.formInstance.invalid) {
      return;
    }
    this.divisionModel.updatedBy = this.createdByUserId;
    this.divisionModel.createdBy = this.createdBy;
    this.divisionModel.createdDate = this.createdDate;
    this.divisionServ.saveDivisonData(this.divisionModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  resetForm() {  
    this.formInstance.reset(); 
    this.divisionModel.id = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
  
  getUserId(emailId : string){
    this.divisionServ.getUserId(emailId).subscribe((data:any)=>{
      this.createdByUserId = data[0].id;
    });
  }

  getBusinessName(){
    this.divisionServ.getBusinessName().subscribe((data:any)=>{
      this.businesslst = data,
      error => console.log(error),  
       () => console.log('Get all complete'); 
    });
  }
 
  customErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }
}