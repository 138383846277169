import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FlatTreeControl } from "@angular/cdk/tree";
import { NestedTreeControl } from "@angular/cdk/tree";
import { Component,Inject, Injectable, OnInit, ViewChild,ElementRef, HostListener } from "@angular/core";
import {MatTreeFlatDataSource,MatTreeFlattener, MatTreeNestedDataSource} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of } from "rxjs";
import { ActivityMasterService } from "src/app/Services/activity-master.service";
import { SelectionModel } from "@angular/cdk/collections";
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  id:number;
  parentId: string;
  text: string;
  parentActivityName: string;
  level: number;
  expandable: boolean;
  selected?: boolean;
  status:boolean;
  isDeleted:boolean;
  disabled:boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id:number;
  text: string;
  parentId: string;
  parentActivityName: string;
  level: number;
  expandable: boolean;
  selected?: boolean;
  children: TodoItemFlatNode[];
  status:boolean;
  isDeleted:boolean;
  disabled:boolean;
}







@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor(private activitymasterSer: ActivityMasterService) {
    this.initialize();
  }

  initialize() {

    this.activitymasterSer.getActivityMaster().subscribe((data1: any[])=>{
      const data = this.treeConstruct(data1);
    this.dataChange.next(data);
    })  
  }

  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
  constructTree(constructedTree, treeObj, assigned) {
    if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({text: name} as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.text = name;
    this.dataChange.next(this.data);
  }
}





@Component({
  selector: 'app-activity-master',
  templateUrl: './activity-master.component.html',
  styleUrls: ['./activity-master.component.css'],
  providers: [ChecklistDatabase]
})
//Start ******************************************** ActivityMasterComponent Component
export class ActivityMasterComponent implements OnInit {
  isIdVisible: boolean = false;
  formInstance: any;
  searchString = ''

   /** Map from flat node to nested node. This helps us finding the nested node to be modified */
   flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

   /** Map from nested node to flattened node. This helps us to keep the same object for selection */
   nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
 
   /** A selected parent node to be inserted */
   selectedParent: TodoItemFlatNode | null = null;
 
   treeControl: NestedTreeControl<TodoItemFlatNode>;
   dataSource_Tree: MatTreeNestedDataSource<TodoItemNode>; 
 

   
  constructor(private snackBar: MatSnackBar,private _database: ChecklistDatabase,private activitymasterSer: ActivityMasterService,public dialog: MatDialog,) { 

      
      this.treeControl = new NestedTreeControl<TodoItemNode>(node => node.children);
      this.dataSource_Tree = new MatTreeNestedDataSource<TodoItemNode>();


    this.activitymasterSer.getActivityMaster().subscribe((data1: any[])=>{
      const data = this.treeConstruct(data1);
      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
    })  

  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.text === '';
  hasChild = (_: number, node: TodoItemNode) => !!node.children && node.children.length > 0;


 

  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
    if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  // search filter logic start
  filterLeafNode(node: TodoItemFlatNode): boolean {
    if (!this.searchString) {
      return false
    }
    return node.text.toLowerCase()
      .indexOf(this.searchString?.toLowerCase()) === -1
    
  }

  filterParentNode(node: TodoItemFlatNode): boolean {

    if (
      !this.searchString ||
      node.text.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
        -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.text
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }
  // search filter logic end


  getColor(node: TodoItemFlatNode): string  {
    return node.status === false || node.disabled === true ?  'red':'black' ;
  }



  ngOnInit(): void {
  }

  loadResourceToEdit(data : any){
    data.action="Edit";
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddActivityDialog,{
     data,height:'800px',width:'600px'
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
       if(val){
         this.getActivityMaster();
       }
     }
   })
 }

 snackBarLoader(content, action, duration, color){
  this.snackBar.open(content, action, {
    duration: 2000,
    verticalPosition: "top", 
    horizontalPosition: "center", 
    panelClass: [color]
  });
}

getActivityMaster(){
  this.activitymasterSer.getActivityMaster().subscribe((data1: any[])=>{
    console.log(data1);
    const data = this.treeConstruct(data1);
    this.dataSource_Tree.data = data;
    this.treeControl.dataNodes = data;
  })  
}

AddDialogBox(data : any){
  if(data!="")
    {
      data.action="Add";
    }
  
    this.isIdVisible = false;
  const dialogRef = this.dialog.open(AddActivityDialog,
    {data,
    height: '450px',
    width: '600px'
  });
  dialogRef.afterClosed().subscribe({
    next: (val) => {
      if(val){
        this.getActivityMaster();
      }
    }
  })
}


}
//End ********************** ActivityMasterComponent Component


/* ************************ Activity Master Popup for Activity creation ************************ */

export interface ActivityMaster{
  activityId: number;
  activity  : string;
  parentId  : number;
  parentActivityName: string;
  status : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
  action:string;
}


@Component ({
  selector: 'app-dialog-entity',
  templateUrl: './addActivityDialog.html',
  styleUrls: ['./activity-master.component.css'],
  providers: [ChecklistDatabase]
})

  export class AddActivityDialog implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    ischild :boolean =true;
    parentId: number;
    createdByUserId : number;
    createdBy : number;
    createdDate : Date;
    activityModel: ActivityMaster= {} as ActivityMaster;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddActivityDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private activityServ : ActivityMasterService) 
    {
      this.formInstance = _formBuilder.group({
        // "id":0,
        "activity":['', Validators.required],
        "status": true
      });
    }

  ngOnInit(): void {
    this.activityModel.status = true;
    if(this.data.parentActivityName == null || this.data.parentActivityName == "" ){
      this.ischild = false;
    }
    if(this.data=="")
      {
        this.activityModel.activityId = 0;
        this.activityModel.parentId = 0;
        this.isIdVisible = false;
      }
    else if(this.data !=null && this.data !=undefined)
    {
      if(this.data.action=='Add')
        {
          this.activityModel.activityId = 0;
          this.activityModel.parentId = this.data.id;
          this.isIdVisible = false;
        }
        else
        {
          this.activityModel.activityId = this.data.id;
          this.activityModel.parentId = this.data.parentId;
          this.activityModel.parentActivityName = this.data.parentActivityName;
          this.activityModel.activity = this.data.text;
          this.activityModel.status = this.data.status;
          this.createdBy = this.data.createdBy;
          this.createdDate = this.data.createdDate;
          this.isIdVisible = true;
        }
        
     
    }
    var emailId = sessionStorage.getItem('LoginEmail');
    this.getUserId(emailId);
  }

  closepopup(){
    if(confirm("Are you sure to close this")) {
      this.dialogRef.close();
    }
  }
  save(){
    if (this.formInstance.invalid) {
      return;
    }
    this.activityModel.createdBy = this.createdByUserId;
    this.activityServ.saveActivityData(this.activityModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
        
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  

  update(){
    if (this.formInstance.invalid) {
      return;
    }
    this.activityModel.updatedBy = this.createdByUserId;
    this.activityModel.createdBy = this.createdBy;
    this.activityModel.createdDate = this.createdDate;
    console.log('the',this.activityModel)
    this.activityServ.saveActivityData(this.activityModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  resetForm() {  
    this.formInstance.reset(); 
    this.activityModel.activityId = 0;
    this.activityModel.parentId = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
  
  getUserId(emailId : string){
    this.activityServ.getUserId(emailId).subscribe((data:any)=>{
      this.createdByUserId = data[0].id;
    });
  }

 
  }
