
<h4 class="Head" style="font-family: ABBvoice;">Designation Master</h4>
<mat-checkbox *ngIf="!isIdVisible"  formControlName="status" (change)="onCheckboxChange($event)" style="padding-left: 70% !important;">
  <span style="font-family: ABBvoice;">Is BulkUpload</span> 
</mat-checkbox>  
<br><br>
<div *ngIf="bulkUpload">
  
   <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" style="padding-left: 8% !important;font-family: ABBvoice;">
   <mat-icon class="download_sample_icon" title="Download sample file" (click)="exportExcel()">get_app</mat-icon>
   <br>
   <span style="font-size: 10px; padding-left: 10%;">(.xlsx,.xls)</span>
   <br><br>
   <div class="row button">
    <div mat-dialog-actions align="center" class="action" >
      <button mat-raised-button class="buttonABB" color="primary" style="font-family: ABBvoice;" type="submit" (click)="uploadDesignationMaster()">Upload</button>
      <button mat-raised-button type="button" class="buttonABB" (click)="cancelForm()">Cancel</button>
    </div>
   </div>
   
</div> 
<div *ngIf="!bulkUpload">
 <form class="example-form" [formGroup]="formInstance">
     <div hidden="true"  class="col-md-6 col-lg-3 col-sm-6">
         <mat-form-field appearance="outline"> 
             <mat-label style="font-family: ABBvoice;">Id</mat-label>            
             <input style="font-family: ABBvoice;" matInput type="text" [(ngModel)]='designationModel.id'
             placeholder="Ex. Id" formControlName="id">
         </mat-form-field>
     </div>   
     <div class="row">
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
           <mat-label style="font-family: ABBvoice;">Designation Name</mat-label>           
           <input style="font-family: ABBvoice;" matInput type="text" [(ngModel)]='designationModel.name'  [errorStateMatcher]="customErrorStateMatcher"
            placeholder="Ex. Name" required formControlName="name">
            <mat-error *ngIf="formInstance.get('name').hasError('specialCharacters')">Name should not contain special characters</mat-error>
            <mat-error *ngIf="formInstance.get('name').invalid && formInstance.get('name').hasError('required') && !formInstance.get('name').hasError('specialCharacters')">Name is required</mat-error>
          </mat-form-field>
       </div> <br>
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
           <mat-label style="font-family: ABBvoice;">Designation Code</mat-label>           
           <input style="font-family: ABBvoice;" matInput type="text" [(ngModel)]='designationModel.code' [disabled]="isCodeDisabled"
            placeholder="Ex. Code" required formControlName="code">
           <mat-error *ngIf="formInstance.controls['code'].errors">Code is required</mat-error>
         </mat-form-field>
       </div>   <br>
     </div>
     <div class="row">   
       <div class="col-md-6">
         <mat-form-field appearance="outline">  
             <mat-label style="font-family: ABBvoice;">Description</mat-label>           
             <textarea style="font-family: ABBvoice;" matInput [(ngModel)]="designationModel.description" placeholder="Ex. Description" formControlName="description"></textarea>
           </mat-form-field>
       </div>
       <div class="col-md-6">
             <mat-checkbox formControlName="status" [disabled]="isMapped" [(ngModel)]='designationModel.status'>
             <span style="font-family: ABBvoice;">Active</span> 
             </mat-checkbox>                   
       </div>      
     </div><br>
     
     <div class="row button">
       <div mat-dialog-actions align="center" class="action" >
         <button mat-raised-button class="buttonABB" *ngIf="!isIdVisible" (click)="save()" color="primary" style="margin-right:10px" 
          type="submit">Save</button>
         <button mat-raised-button class="buttonABB" *ngIf="isIdVisible" (click)="update()" color="primary" style="margin-right:10px" 
          type="submit">Update</button>
         <button mat-raised-button type="button" class="buttonABB" (click)="resetForm()">Cancel</button>
       </div>
     </div>
  <br><br>
 </form> 
</div>


