import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { SideNavComponent } from './sidenav/side-nav.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ReportComponent } from './admin/report/report.component';
import { TreeviewComponent } from './admin/treeview/treeview.component';
import { SearchComponent } from   './admin/search/search.component';
import { BusinessAreaComponent } from './admin/business-area/business-area.component';
import { DivisionComponent } from './admin/division/division.component';
import { DesignationComponent } from './admin/designation/designation.component';
import { ActivityMasterComponent } from './admin/activity-master/activity-master.component';
import { ActivityMappingComponent } from './admin/activitymapping/activitymapping.component';
import { EntityComponent } from './admin/entity/entity.component';
import { ActivityDataComponent } from './admin/activity-data/activity-data.component';
import { ActivityDataMappComponent } from './admin/activity-data-mapp/activity-data-mapp.component';
import { PreambleComponent } from './admin/preamble/preamble.component';
import { ReadingThisDocumentComponent } from './admin/reading-this-document/reading-this-document.component';
import { HistoryComponent } from   './admin/history/history.component';
import { DesignationMappingComponent } from './admin/designation-mapping/designation-mapping.component';
import { UserRoleMappingComponent } from './admin/user-role-mapping/user-role-mapping.component';

const routes: Routes = [
 
  {
    path: 'SideNav',
    component: SideNavComponent,
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
  },
  
  {
    path: 'Treeview',
    component: TreeviewComponent,
  },

  {
    path: 'Report',
    component: ReportComponent,
  },
  {
    path: 'Search',
    component: SearchComponent,
  },
  {
    path: 'Designation',
    component: DesignationComponent,
  },
  {
    path: 'Division',
    component: DivisionComponent,
  },
  {
    path: 'Entity',
    component: EntityComponent,
  },
  {
    path: 'BusinessArea',
    component: BusinessAreaComponent,
  },
  {
    path: 'ActivityData',
    component: ActivityDataComponent,
  },
  {
    path: 'ActivityMaster',
    component: ActivityMasterComponent,
  },
  {
    path: 'ActivityMapping',
    component: ActivityMappingComponent,
  },
  {
    path: 'Preamble',
    component: PreambleComponent,
  },
  {
    path: 'ReadingDocument',
    component: ReadingThisDocumentComponent,
  },
  {
    path: 'ActivityDataMapping',
    component: ActivityDataMappComponent,
  },
  {
    path: 'History',
    component: HistoryComponent,
  },
  {
    path: 'DesignationMapping',
    component: DesignationMappingComponent,
  },
  {
    path: 'UserRoleMapping',
    component: UserRoleMappingComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
