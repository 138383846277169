import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  rooturl= environment.rooturl ;

   //rooturl = 'https://localhost:7063/'; 
  // rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
  // rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
   //  rooturl = 'https://stage.table-of-authority.in.abb.com/api/';   


  filterParams: { parameterName: string; parameterValue: any; }[];
    
  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
   
 }
  gettoken(userName:any,password:any){
    var data = JSON.stringify ( {
      "username": userName,
      "password": password
    });
    
    console.log(data);
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
    return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
  }

  getHistoryList(busIDs): Observable<any> {
    if(busIDs=="")
      {
       busIDs=0
      }
    return this.http.get(this.rooturl + 'api/ActivityHistory/GetHistoryList?busIDs='+busIDs);
  } 
  currentActivitySaveToHistoryTemp(busID,createdBy): Observable<any> {
    return this.http.get(this.rooturl + 'api/ActivityHistory/CreateHistory?busID='+ busID + "&createdBy="+createdBy);
  } 
  currentActivityMoveToHistory(): Observable<any> {
    return this.http.get(this.rooturl + 'api/ActivityHistory/CurrentActivityMoveToHistory');
  }  
  
  getSearchHistoryDetails(myData :any): Observable<any> {
  
    return this.http.post(this.rooturl + 'api/ActivityHistory/GetHistoryDetails',myData);
     
  } 
 
  generatePdf(activityDTL:any, allBusIDs:any,selectedBusIDs){
    const data={activityDTL,allBusIDs,selectedBusIDs}
    return this.http.post(this.rooturl+ 'api/ActivityHistory/GeneratePDF',data,{responseType:'blob'});
  }
}

