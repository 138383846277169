import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EntityMaster } from '../admin/entity/entity.component';
import { Observable } from 'rxjs';
import { BusinessAreaMST } from '../admin/search/search.component';
import { BusinessAreaMaster } from '../admin/business-area/business-area.component';
import { DesignationMaster } from '../admin/designation/designation.component';
import { DivisonMaster } from '../admin/division/division.component';
import { PreambleMaster } from '../admin/preamble/preamble.component';
import { ReadingDocumentMaster } from '../admin/reading-this-document/reading-this-document.component';
import { DesignationMapping } from '../admin/designation-mapping/designation-mapping.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  rooturl= environment.rooturl ;
  //   rooturl = 'https://localhost:7063/';   

  // rooturl = 'https://dev.table-of-authority.in.abb.com/api/';   
 //   rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
    //  rooturl = 'https://stage.table-of-authority.in.abb.com/api/';   

  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }


  // Entity Master

  saveEntityData(data: EntityMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/EntityMaster/SaveEntityData',data);
  } 
 
  getEntityMaster (){
    return this.http.get(this.rooturl + 'api/EntityMaster/GetEntityMaster');
  }

  deleteEntityMaster(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/EntityMaster/DeleteEntityMaster?entityId=' + id);
   }

   getUserId(emailId : string){
    return this.http.get(this.rooturl + 'api/EntityMaster/GetUserDetails?emailId=' + emailId);
   }

   // Business Area Master
   
   saveBusinessAreaMasterData(data: BusinessAreaMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/BusinessAreaMaster/SaveBusinessAreaMasterData',data);
  } 
 
  getBusinessAreaMaster (){
    return this.http.get(this.rooturl + 'api/BusinessAreaMaster/GetBusinessAreaMaster');
  }
  getBusinessName(){
    return this.http.get(this.rooturl + 'api/BusinessAreaMaster/GetBusinessName');
  }
  deleteBusinessAreaMaster(data: BusinessAreaMST): Observable<any>{
    return this.http.post(this.rooturl + 'api/BusinessAreaMaster/DeleteBusinessAreaMaster',data);
   }

   // Designation Master

  saveDesignationData(data: DesignationMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/DesignationMaster/SaveDesignationData',data);
  } 
 
  getDesignationMaster (){
    return this.http.get(this.rooturl + 'api/DesignationMaster/GetDesignationMaster');
  }

  deleteDesignationMaster(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/DesignationMaster/DeleteDesignationMaster?designationId=' + id);
   }


  //  Divison Master

  
  saveDivisonData(data: DivisonMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/Division/SaveDivisonMasterData',data);
  } 
 
  getDivisionMaster (){
    return this.http.get(this.rooturl + 'api/Division/GetDivisionMaster');
  }

  deleteDivisonMaster(data: DivisonMaster): Observable<any>{
    return this.http.post(this.rooturl + 'api/Division/DeleteDivisonMaster',data);
   }

   //Preamble

  getPreambleData(){
    return this.http.get(this.rooturl + 'api/Preamble/GetPreambleData');
  }
  
  SavePreambleData(data: PreambleMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/Preamble/SavePreambleData',data);
  } 
 
  deletePreambleMaster(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/Preamble/DeletePreambleMaster?id=' + id);
  }

  //Reading document data

  getReadingData(){
    return this.http.get(this.rooturl + 'api/ReadingThisDocument/GetReadingThisDocumentData');
  }
  
  saveReadingDocumentData(data: ReadingDocumentMaster): Observable<any> {
    return this.http.post(this.rooturl + 'api/ReadingThisDocument/SaveDocumentData',data);
  } 
 
  deleteReadingDocumentMaster(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/ReadingThisDocument/DeleteDocumentData?documentId=' + id);
  }

  // Designation Mapping

  
  saveDesignationMappingData(data: any): Observable<any> {
    return this.http.post(this.rooturl + 'api/DesignationMap/SaveDesignationMappingData',data);
  } 
 
  getDesignationMapping (entityId : number,buid:string){
    return this.http.get(this.rooturl + 'api/DesignationMap/GetDesignationMapData?entityId=' + entityId +'&buid='+buid);
  }

  deleteDesignationMappingMaster(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/DesignationMap/DeleteDesignationMaster?designationId=' + id);
   }

   getBusinessAreaEntityMapping(entityId : number){
    return this.http.get(this.rooturl + 'api/DesignationMap/GetBusinessAreaEntityMapping?entityId=' + entityId);
   }
   
   getDesignationBusinessMapping(businessAreaId : string){
    return this.http.get(this.rooturl + 'api/DesignationMap/GetDesignationBusinessMapping?businessAreaId=' + businessAreaId);
   }
   
   getDivisionDetailsFromBusinessAreaId(businessAreaId : string){
    return this.http.get(this.rooturl + 'api/DesignationMap/GetDivisionDetailsFromBusinessAreaId?businessAreaId=' + businessAreaId);
   }
   deleteDesignationMapping(id: number): Observable<any>{
    return this.http.get(this.rooturl + 'api/DesignationMap/DeleteDesignationMapping?id=' + id);
   }

   getEntityList(): Observable<any> {
    return this.http.get(this.rooturl + 'api/TOASearch/GetEntityList');
  } 
getBuisnessAreaList(entityID): Observable<any> {
return this.http.get(this.rooturl + 'api/TOASearch/GetBuisnessAreaList?entityId='+entityID);
}

downloadExcel(): Observable<Blob> {
  return this.http.get('assets/documents/DesignationMST.xlsx', { responseType: 'blob' });
}
SaveDesignationMasterBulkData(data){
  return this.http.post(this.rooturl + 'api/DesignationMaster/SaveDesignationMasterBulkData',data);
} 
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
verticalPosition: MatSnackBarVerticalPosition = 'top';
ShowSnackBar(data: any,color: any)
  {
    this._snackBar.open(data,'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000,
      panelClass: [color]
    });
  }
}
