import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FlatTreeControl } from "@angular/cdk/tree";
import { NestedTreeControl } from "@angular/cdk/tree";
import { Component,Inject, Injectable, OnInit, ViewChild,ElementRef, HostListener } from "@angular/core";
import {MatTreeFlatDataSource,MatTreeFlattener, MatTreeNestedDataSource} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of } from "rxjs";
import { ActivityMasterService } from "src/app/Services/activity-master.service";
import { SelectionModel } from "@angular/cdk/collections";
import { SearchService } from "src/app/Services/search.service";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatOptionSelectionChange} from '@angular/material/core';
import { map, startWith } from "rxjs/operators";
import { MatSelectChange } from "@angular/material/select";
 import { ActivityDataMappComponent } from '../activity-data-mapp/activity-data-mapp.component'; 
import { Router } from '@angular/router';
import { HistoryService } from 'src/app/Services/history.service';
import * as XLSX from 'xlsx-js-style';
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  id:number;
  parentId: string;
  text: string;
  level: number;
  expandable: boolean;
  isChecked?: boolean;
  status:boolean;
  isUsed:boolean;
  isData:boolean;
  

}
export class ActivityListDetails {
  ActivityId : number;
  SourceOfActivity: string;
  CatBatReference: number;
  CPReference: JSON;
  MIReference: JSON;
  BRReference: JSON;
  Tool: string;
  ApplicableToBusiness: string;
  Activity: string;
  Responsible: JSON;
  accountable: JSON;  
  Consult: JSON;
  Inform: JSON;
  Remark: JSON;
  ActivityFormingPartOfLat: string;
  BusinessAreaID: number;
  BusinessArea: string;
  businessAreaCode: string;
  BgColor:string;
  FontColor:string;
  FontWeight:string;
}
/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  id:number;
  text: string;
  parentId: string;
  level: number;
  expandable: boolean;
  isChecked?: boolean;
  children: TodoItemFlatNode[];
  status:boolean;
  isUsed:boolean;
  isData:boolean;
}


export interface BusinessAreaMST{
  id:number;
  name: string;
  code:string;
}

export class Entity{
  id:number;
  code:string;
  name: string;
  status: boolean;
}

export interface WorkSheetTableConfig{
  displayedColumns: string[];
  dataSource: MatTableDataSource<ActivityListDetails>;
  tableId: string;
}

export class Preamble{
  id:number;
  topic:string;
  remarks: string;
}

export class ReadingThisDocument{
  id:number;
  column:string;
  description: string;
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  get data(): TodoItemNode[] { return this.dataChange.value; }
  dataSource_Preamble : MatTableDataSource<Preamble>;
  dataSource_Reading : MatTableDataSource<ReadingThisDocument>;
  dataChange_new = new BehaviorSubject<TodoItemNode[]>([]);
  get data_new(): TodoItemNode[] { return this.dataChange_new.value; }

  constructor(private activitymasterSer: ActivityMasterService) {
    this.initialize();
  }

  initialize() {

   /*  this.activitymasterSer.getActivityList('').subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
    this.dataChange.next(data);
    })   */
    
    
  }

  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
if (treeObj.parentId == null) {
      treeObj.children = [];
      
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({text: name} as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.text = name;
    this.dataChange.next(this.data);
  }
}





@Component({
  selector: 'app-activitymapping',
  templateUrl: './activitymapping.component.html',
  styleUrls: ['./activitymapping.component.scss'],
  providers: [ChecklistDatabase]
})
export class ActivityMappingComponent implements OnInit {

  businessList: BusinessAreaMST[]=[];
  
  @ViewChild('select') select: MatSelect;
  allSelected=false;
  isMultipleSelected: boolean = false;
  formEntity: FormGroup;
  searchValue : string  = ''; 
  selectedEntityValue = [];
  entityList: Entity[]=[];
  selectEntityFormControl = new FormControl();
  businessFormControl = new FormControl();
  //selectedDesignationId= new FormControl([]);
  getSelectedItemsList   = "";
  formInstance: any;
  @ViewChild('tree') tree;
  @ViewChild('tree_new') tree_new;
  @ViewChild('txtBusinessunit') txtBusinessunit: ElementRef; 
  isShowNote:boolean= false;
  workSheetTables: WorkSheetTableConfig[]=[];
  selectedData: { value: string; text: string } = {
    value: "",
    text: ""
  };
  combined_workbook: XLSX.WorkBook;
  displayedColumns_Preamble: string[] = ['id','topic','remarks'];
  displayedColumns_Reading: string[] = ['id','column','description'];
  dataSource_Preamble : MatTableDataSource<Preamble>;
  dataSource_Reading : MatTableDataSource<ReadingThisDocument>;
  dummyDataSource: MatTableDataSource<ActivityListDetails>;
  displayedColumns_Dummy: string[] = ['BgColor','FontColor','FontWeight','BusinessArea','SourceOfActivity','CatBatReference','CPReference','MIReference','BRReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat'];
  isIdVisible: boolean = false;
  editDisable:boolean=true;
  showHistory: boolean= false;
  showToolTip: string="Please Click on Create History button before editing"
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  get data(): TodoItemNode[] { return this.dataChange.value; }
  dataChange_new = new BehaviorSubject<TodoItemNode[]>([]);
  get data_new(): TodoItemNode[] { return this.dataChange_new.value; }

   /** Map from flat node to nested node. This helps us finding the nested node to be modified */
   flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

   /** Map from nested node to flattened node. This helps us to keep the same object for selection */
   nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
 
   /** A selected parent node to be inserted */
   selectedParent: TodoItemFlatNode | null = null;
 
   /** The new item's name */
   newItemName = '';
 
   treeControl: NestedTreeControl<TodoItemFlatNode>;
   treeControl_new: NestedTreeControl<TodoItemFlatNode>;
 /*   treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>; */
 
   dataSource_Tree: MatTreeNestedDataSource<TodoItemNode>; 
   dataSource_Tree_new: MatTreeNestedDataSource<TodoItemNode>; 
   /** The selection for checklist */
   checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
   
  constructor(private router: Router,private snackBar: MatSnackBar,private _database: ChecklistDatabase,private _formBuilder: FormBuilder,private activitymasterSer: ActivityMasterService,public dialog: MatDialog,
    private historyServ: HistoryService,private searchServ: SearchService
  ) { 

  /*   this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren); */
      
      this.treeControl = new NestedTreeControl<TodoItemNode>(node => node.children);
      this.dataSource_Tree = new MatTreeNestedDataSource<TodoItemNode>();


      this.treeControl_new = new NestedTreeControl<TodoItemNode>(node => node.children);
      this.dataSource_Tree_new = new MatTreeNestedDataSource<TodoItemNode>();

      
      this.searchServ.getPreambleList().subscribe(preambleData=>{
        this.dataSource_Preamble= new MatTableDataSource<Preamble>(preambleData);
        this.searchServ.getReadingThisDocumentList().subscribe(readingData=>{
          this.dataSource_Reading= new MatTableDataSource<ReadingThisDocument>(readingData);
          console.log(this.dataSource_Reading.filteredData);
        });
      });
     

    this.formInstance = _formBuilder.group({
      "businessControl":['', Validators.required],
     
      searchString_new: new FormControl(''),
      searchString: new FormControl('')
    });
  

  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.text === '';
  hasChild = (_: number, node: TodoItemNode) => !!node.children && node.children.length > 0;


 

  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }


  // search filter logic start
//************************************Start Serches for New activities
  filterLeafNode_new(node: TodoItemFlatNode): boolean {
  
    //console.log(this.businessFormControl.value);
    if (!this.formInstance.value.searchString_new) {
      return false
    }
    return node.text.toLowerCase()
      .indexOf(this.formInstance.value.searchString_new?.toLowerCase()) === -1
    
  }

  filterParentNode_new(node: TodoItemFlatNode): boolean {

    if (
      !this.formInstance.value.searchString_new ||
      node.text.toLowerCase().indexOf(this.formInstance.value.searchString_new?.toLowerCase()) !==
        -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.text
            .toLowerCase()
            .indexOf(this.formInstance.value.searchString_new?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }

//************************************End Serches for New activities



//************************************Start Serches for existing activities
  filterLeafNode(node: TodoItemFlatNode): boolean {
    if (!this.formInstance.value.searchString) {
      return false
    }
    return node.text.toLowerCase()
      .indexOf(this.formInstance.value.searchString?.toLowerCase()) === -1
    
  }

  filterParentNode(node: TodoItemFlatNode): boolean {

    if (
      !this.formInstance.value.searchString ||
      node.text.toLowerCase().indexOf(this.formInstance.value.searchString?.toLowerCase()) !==
        -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.text
            .toLowerCase()
            .indexOf(this.formInstance.value.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }
  //************************************End Serches for existing activities
  // search filter logic end

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl_new.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl_new.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }



  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl_new.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

 


  /* todoItemSelectionToggle(checked, node) {
  
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl_new.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(node)
      : this.checklistSelection.deselect(node);
 
    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(node));
    this.checkAllParentsnew(node);
   
 
    node.selected = checked;
    if (node.children) {
      node.children.forEach(x => {
        this.todoItemSelectionToggle(checked, x);
        
      });
    }
    this.checkAllParentsnew(node);
  
  } */

  setParent(data, parent) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach(x => {
        this.setParent(x, data);
      });
    }
  }
  


   checkAllParentsnew(node) {
    if (node.parent) {
      const descendants = this.treeControl_new.getDescendants(node.parent);
      node.parent.selected = descendants.every(child => child.isChecked);
      node.parent.indeterminate = descendants.some(child => child.isChecked);
      this.checkAllParentsnew(node.parent);
    }
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }




  ngOnInit(): void {
    
    this.getEntity();
  }
  getEntity(){
    
    this.activitymasterSer.getEntityList().subscribe((data:any)=>{
      this.entityList= data;
      let entityID: number;
      if (this.entityList && this.entityList.length > 0) {
        this.entityList.forEach((e) => {
          if(e.code == 'IN01'){
            entityID= e.id;
            if (this.selectedEntityValue.indexOf(e) == -1) {
              this.selectedEntityValue.push(e);
            }
          }  
        });
      }
      this.selectEntityFormControl.patchValue(this.selectedEntityValue[0]);
      this.activitymasterSer.getBuisnessAreaList(entityID).subscribe((data:any)=>{
        this.businessList = data, () => console.log('Get all complete'); 
      });
    });
  }
  entitySelection(entityID:number){
    this.businessList=[];

    this.businessFormControl.reset();
this.selectedData.text='';

    this.dataSource_Tree.data=[];
    this.dataSource_Tree_new.data=[];
    this.activitymasterSer.getBuisnessAreaList(entityID).subscribe((data:any)=>{
      this.businessList = data, () => console.log('Get all complete'); 
    });
  }
  
  getColor(node: TodoItemFlatNode): string  {
    return node.isData === false ?  'red':'black' ;
  }
  /* getNodeColor(node: TodoItemFlatNode): string  {
    return node.isData === false ?  'red':'black' ;
  } */
  
  selectedValue(event: MatSelectChange) {
    this.selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
    console.log(this.selectedData);
  }

  
  
  businessAreaSelection() {
    this.editDisable=true;
    this.showHistory= false;
    this.formInstance.value.businessControl = this.businessFormControl.value;
    if(this.formInstance.value.businessControl>0){
      this.activitymasterSer.getActivityHistoryTempTRNCount().subscribe((data:any)=>{
       if(data >0){
        this.showHistory= true;
       }
      });
    }
    if(this.formInstance.value.businessControl.length > 1){
      console.log('len',this.formInstance.value.businessControl.length);
      this.isMultipleSelected = true;
      } 
      else{
        this.isMultipleSelected = false;
      }   

    this.allSelected=false;
    //this.getSelectedItemsList   = this.formInstance.value.businessControl.innerText.trim();
    this.checklistSelection.clear();
     /* this.txtBusinessunit='Select Activity111!' ; */
     var busIDs="0"
     var divIDs="0"
     var designationIDs="0"
     var activityIDs="0"
     if(this.formInstance.value.businessControl!="")
      busIDs=this.formInstance.value.businessControl.toString();//"1,2,3,4,5,6,7,8";
     const myData = { busIDs: busIDs, 
       divIDs: divIDs, 
       designationIDs: designationIDs, 
       activityIDs: activityIDs };
       

 

    this.activitymasterSer.getActivityList(this.formInstance.value.businessControl).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      this.isShowNote=true;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
        this.setParent(this.dataSource_Tree.data[x], null);
      });
      this.dataChange.next(data);
    })  

    this.activitymasterSer.getSearchActivityList(this.formInstance.value.businessControl).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
      this.dataSource_Tree_new.data = data;
      this.treeControl_new.dataNodes = data;
      for (let i = 0; i < this.treeControl_new.dataNodes.length; i++) {
        if (this.treeControl_new.dataNodes[i].isChecked == true) {
          this.treeControl_new.expand(this.treeControl_new.dataNodes[i])
        }
      }

      Object.keys(this.dataSource_Tree_new.data).forEach(x => {
        this.setParent(this.dataSource_Tree_new.data[x], null);
      });
      this.dataChange_new.next(data);
    })
   

  }
  addHyperlink(Data: string) {
        
    const jsonObject = JSON.parse(Data);
    var retValText = ""; 

    var retVal = "";
    for(var key in jsonObject) {    
        retValText=jsonObject[key].Text.replace('; ', ';<br/>'); 
                  
    }
    
    return retValText;
  }
 /*  exportExcel(){
    const table = document.getElementById('draftData');
    const workSheet_CommonData: XLSX.WorkSheet= XLSX.utils.table_to_sheet(table);
    workSheet_CommonData['!cols']=[{width:30},{width:40},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40}];
    workSheet_CommonData['!rows']=[{hpx:15},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30}];
    
    // Get the rows and cells from the HTML table
  const rows = table.querySelectorAll('tr');
  rows.forEach((row, rowIndex) => {
    const cells = row.querySelectorAll('td, th');
    cells.forEach((cell, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
      // Assign cell value from HTML table
      const cellValue = cell.textContent?.trim() || '';

      // Update the worksheet cell

      if(workSheet_CommonData[cellAddress].l == undefined){
        workSheet_CommonData[cellAddress] = {
          v: cellValue,
          t: 's', // Set cell type to string
          s: { numFmt: '@' } // Ensure cell is treated as text in Excel
        };
      }
      else{
        workSheet_CommonData[cellAddress] = {
          v: cellValue,
          t: 's', // Set cell type to string
          s: { numFmt: '@' } ,// Ensure cell is treated as text in Excel
          l: workSheet_CommonData[cellAddress].l
        };
      }
    });
  });
  const header_Style_Divisions= {
    font:{
      bold: true,
      color:{rgb:"FFFFFF"},
      name: 'ABBvoice',
    },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: '1', // any truthy value here
    },
    fill:{
      fgColor:{rgb:"ff000f"}
    },
    border: {
      right: {
        style: 'thin',
        color: '000000',
      },
      bottom: {
        style: 'thin',
        color: '000000',
      },
      top: {
        style: 'thin',
        color: '000000',
      },
      left: {
        style: 'thin',
        color: '000000',
      },
    },
  }
  for (var i in workSheet_CommonData) {

    workSheet_CommonData['A1'].s= header_Style_Divisions;
    workSheet_CommonData['B1'].s= header_Style_Divisions
    workSheet_CommonData['C1'].s=header_Style_Divisions;
    workSheet_CommonData['D1'].s=header_Style_Divisions
    workSheet_CommonData['E1'].s=header_Style_Divisions
    workSheet_CommonData['F1'].s=header_Style_Divisions
    workSheet_CommonData['G1'].s=header_Style_Divisions
    workSheet_CommonData['H1'].s=header_Style_Divisions
    workSheet_CommonData['I1'].s=header_Style_Divisions
    workSheet_CommonData['J1'].s=header_Style_Divisions
    workSheet_CommonData['K1'].s=header_Style_Divisions
    workSheet_CommonData['L1'].s=header_Style_Divisions
    workSheet_CommonData['M1'].s=header_Style_Divisions
    workSheet_CommonData['N1'].s=header_Style_Divisions
    workSheet_CommonData['O1'].s=header_Style_Divisions
   

    if (typeof workSheet_CommonData[i] != 'object') continue;
    
    let cell = XLSX.utils.decode_cell(i);
    workSheet_CommonData[i].s = {
      wch: 50,
      // styling for all cells
      font: {
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    };
    
    if (cell.c == 6) {
      // first column
      workSheet_CommonData[i].s.numFmt = 'DD-MM-YYYY'; // for dates
      workSheet_CommonData[i].z = 'DD-MM-YYYY';
    } else {
      workSheet_CommonData[i].s.numFmt = '00'; // other numbers
    }

    if (cell.r == 0) {
      // first row
      workSheet_CommonData[i].s.border.bottom = {
        // bottom border
        style: 'thin',
        color: '000000',
      };
    }
  }
 this.applyBgColorFontColorFontweightForColumns(workSheet_CommonData, this.dummyDataSource.data);
    workSheet_CommonData['M1'].s=header_Style_Divisions;
    workSheet_CommonData['N1'].s=header_Style_Divisions;
    workSheet_CommonData['O1'].s=header_Style_Divisions;
    
    if(this.dummyDataSource.data.length == 0){
      XLSX.utils.sheet_add_aoa(workSheet_CommonData,[['No records found']], {origin : 'A2'});
      workSheet_CommonData['A2'].s= {
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
      }
  }
  const combined_wb: XLSX.WorkBook= XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(combined_wb, workSheet_CommonData,'Sheet 1');
  XLSX.writeFile(combined_wb, `TOA_Draft.xlsx`);
} */
  executeafterDelay(){
    const currentDateTime= new Date();
  const dateString= currentDateTime.toISOString().replace(/[:.]/g,'-');
  const tabledata_Preamble= this.dataSource_Preamble.filteredData.map(row=>[row.id, row.topic,row.remarks]);
  const dataPreamble=[
    ["","","Generated on:"+dateString],
    ["Preamble - an introduction to the contents, structure and flow of the TOA"],
    ["","",""],
    ["ID","Column","Description"],
    ...tabledata_Preamble
  ];
  const workSheet_Preamble: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataPreamble);
  const header_Style= {
    font:{
      bold: true,
      color:{rgb:"FFFFFF"},
      name: 'ABBvoice',
    },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: '1', // any truthy value here
    },
    fill:{
      fgColor:{rgb:"ff000f"}
    },
    border: {
      right: {
        style: 'thin',
        color: '000000',
      },
      bottom: {
        style: 'thin',
        color: '000000',
      },
      top: {
        style: 'thin',
        color: '000000',
      },
      left: {
        style: 'thin',
        color: '000000',
      },
    },
  }
  const header_Style_Divisions= {
    font:{
      bold: true,
      color:{rgb:"FFFFFF"},
      name: 'ABBvoice',
    },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: '1', // any truthy value here
    },
    fill:{
      fgColor:{rgb:"ff000f"}
    },
    border: {
      right: {
        style: 'thin',
        color: '000000',
      },
      bottom: {
        style: 'thin',
        color: '000000',
      },
      top: {
        style: 'thin',
        color: '000000',
      },
      left: {
        style: 'thin',
        color: '000000',
      },
    },
  }
  workSheet_Preamble['!cols']=[{width:10},{width:30},{width:120}];
  workSheet_Preamble['!rows']=[{hpx:15},{hpx:30},{hpx:30}];
  
  for (var i in workSheet_Preamble) {
    workSheet_Preamble['C1'].s={
      border:{},
      font:{
        bold: true,
        color:{rgb:"000000"},
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'right',
        horizontal: 'right',
        wrapText: '1', // any truthy value here
      },
    },
    workSheet_Preamble['A2'].s={
      border:{},
      font:{
        bold: true,
        color:{rgb:"000000"},
        name: 'ABBvoice',
        underline:true
      },
    },
    workSheet_Preamble['A1'].s={
      border:{}
    },
    workSheet_Preamble['B1'].s={
      border:{}
    },
    workSheet_Preamble['A3'].s={
      border:{}
    },
    workSheet_Preamble['B3'].s={
      border:{}
    },
    workSheet_Preamble['C3'].s={
      border:{}
    },
    workSheet_Preamble['A4'].s=header_Style;
    workSheet_Preamble['B4'].s=header_Style;
    workSheet_Preamble['C4'].s=header_Style;
    if (typeof workSheet_Preamble[i] != 'object') continue;
    let cell = XLSX.utils.decode_cell(i);
    workSheet_Preamble[i].s = {
      wch: 50,
      // styling for all cells
      font: {
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    };

    if (cell.c == 6) {
      // first column
      workSheet_Preamble[i].s.numFmt = 'DD-MM-YYYY'; // for dates
      workSheet_Preamble[i].z = 'DD-MM-YYYY';
    } else {
      workSheet_Preamble[i].s.numFmt = '00'; // other numbers
    }

    if (cell.r == 0) {
      // first row
      workSheet_Preamble[i].s.border.bottom = {
        // bottom border
        style: 'thin',
        color: '000000',
      };
    }
  }

  const combined_wb: XLSX.WorkBook= XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(combined_wb, workSheet_Preamble, 'Preamble');


  const tabledata_Reading= this.dataSource_Reading.filteredData.map(row=>[row.id, row.column,row.description]);
  console.log(tabledata_Reading);
  const dataReading=[
    ["Reading this document"],
    ["Listed below is the description of the column headers in the TOA sheet. It guides the users to interpret the columns and how the information therein is populated."],
    ["","",""],
    ["ID","Column","Description"],
    ...tabledata_Reading
  ];
  const workSheet_Reading: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataReading);
  workSheet_Reading['!cols']=[{width:10},{width:30},{width:120}];
  workSheet_Reading['!rows']=[{hpx:15},{hpx:30},{hpx:30}];
  
  for (var i in workSheet_Reading) {
    workSheet_Reading['A1'].s={
      border:{},
      font:{
        bold: true,
        color:{rgb:"000000"},
        name: 'ABBvoice',
        underline:true
      },
    },
    workSheet_Reading['A2'].s={
      border:{},
      font:{
        bold: true,
        color:{rgb:"000000"},
        name: 'ABBvoice',
        underline:true
      },
    },
    workSheet_Reading['A3'].s={
      border:{}
    },
    workSheet_Reading['B3'].s={
      border:{}
    },
    workSheet_Reading['C3'].s={
      border:{}
    },
    workSheet_Reading['A4'].s=header_Style;
    workSheet_Reading['B4'].s=header_Style;
    workSheet_Reading['C4'].s=header_Style;
    if (typeof workSheet_Reading[i] != 'object') continue;
    let cell = XLSX.utils.decode_cell(i);
    workSheet_Reading[i].s = {
      wch: 50,
      // styling for all cells
      font: {
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    };

    if (cell.c == 6) {
      // first column
      workSheet_Reading[i].s.numFmt = 'DD-MM-YYYY'; // for dates
      workSheet_Reading[i].z = 'DD-MM-YYYY';
    } else {
      workSheet_Reading[i].s.numFmt = '00'; // other numbers
    }

    if (cell.r == 0) {
      // first row
      workSheet_Reading[i].s.border.bottom = {
        // bottom border
        style: 'thin',
        color: '000000',
      };
    }
  }

  XLSX.utils.book_append_sheet(combined_wb, workSheet_Reading, 'Reading this document');
  for(let w = 0; w < this.workSheetTables.length; w++) 
    {
      debugger;
    const table = document.getElementById(this.workSheetTables[w].tableId);
    const workSheet_CommonData: XLSX.WorkSheet= XLSX.utils.table_to_sheet(table);
    workSheet_CommonData['!cols']=[{width:30},{width:40},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40},{width:60},{width:40},{width:40},{width:40},{width:40}];
    workSheet_CommonData['!rows']=[{hpx:15},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30},{hpx:30}];
    
    // Get the rows and cells from the HTML table
  const rows = table.querySelectorAll('tr');
  rows.forEach((row, rowIndex) => {
    const cells = row.querySelectorAll('td, th');
    cells.forEach((cell, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
      // Assign cell value from HTML table
      const cellValue = cell.textContent?.trim() || '';

      // Update the worksheet cell

      if(workSheet_CommonData[cellAddress].l == undefined){
        workSheet_CommonData[cellAddress] = {
          v: cellValue,
          t: 's', // Set cell type to string
          s: { numFmt: '@' } // Ensure cell is treated as text in Excel
        };
      }
      else{
        workSheet_CommonData[cellAddress] = {
          v: cellValue,
          t: 's', // Set cell type to string
          s: { numFmt: '@' } ,// Ensure cell is treated as text in Excel
          l: workSheet_CommonData[cellAddress].l
        };
      }
    });
  });
  for (var i in workSheet_CommonData) {

    workSheet_CommonData['A1'].s= header_Style_Divisions;
    workSheet_CommonData['B1'].s= header_Style_Divisions
    workSheet_CommonData['C1'].s=header_Style_Divisions;
    workSheet_CommonData['D1'].s=header_Style_Divisions
    workSheet_CommonData['E1'].s=header_Style_Divisions
    workSheet_CommonData['F1'].s=header_Style_Divisions
    workSheet_CommonData['G1'].s=header_Style_Divisions
    workSheet_CommonData['H1'].s=header_Style_Divisions
    workSheet_CommonData['I1'].s=header_Style_Divisions
    workSheet_CommonData['J1'].s=header_Style_Divisions
    workSheet_CommonData['K1'].s=header_Style_Divisions
    workSheet_CommonData['L1'].s=header_Style_Divisions
    workSheet_CommonData['M1'].s=header_Style_Divisions
    workSheet_CommonData['N1'].s=header_Style_Divisions
    workSheet_CommonData['O1'].s=header_Style_Divisions
   

    if (typeof workSheet_CommonData[i] != 'object') continue;
    
    let cell = XLSX.utils.decode_cell(i);
    workSheet_CommonData[i].s = {
      wch: 50,
      // styling for all cells
      font: {
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    };
    
    if (cell.c == 6) {
      // first column
      workSheet_CommonData[i].s.numFmt = 'DD-MM-YYYY'; // for dates
      workSheet_CommonData[i].z = 'DD-MM-YYYY';
    } else {
      workSheet_CommonData[i].s.numFmt = '00'; // other numbers
    }

    if (cell.r == 0) {
      // first row
      workSheet_CommonData[i].s.border.bottom = {
        // bottom border
        style: 'thin',
        color: '000000',
      };
    }
  }
 this.applyBgColorFontColorFontweightForColumns(workSheet_CommonData, this.workSheetTables[w].dataSource.data);
    workSheet_CommonData['M1'].s=header_Style_Divisions;
    workSheet_CommonData['N1'].s=header_Style_Divisions;
    workSheet_CommonData['O1'].s=header_Style_Divisions;
    
    if(this.workSheetTables[w].dataSource.data.length == 0){
      XLSX.utils.sheet_add_aoa(workSheet_CommonData,[['No records found']], {origin : 'A2'});
      workSheet_CommonData['A2'].s= {
        font:{
          bold: true,
          color:{rgb:"000000"},
          name: 'ABBvoice',
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
      }
    }


  XLSX.utils.book_append_sheet(combined_wb, workSheet_CommonData, this.workSheetTables[w].tableId);
    }
    const now = new Date();
     XLSX.writeFile(combined_wb, `TOA_Draft_`+now.toLocaleString('en-US', {year: 'numeric',
    month: '2-digit',day: '2-digit',hour: '2-digit',minute: '2-digit',second: '2-digit',hour12: false })+`.xlsx`);
  }
exportExcel(){

  var busIDs="0"
     var divIDs="0"
     var designationIDs="0"
     var activityIDs="0"
  if(this.formInstance.value.businessControl!="")
      busIDs= this.businessList.map(item=>item.id).join(',').toString();
     const myData = { busIDs: busIDs, 
       divIDs: divIDs, 
       designationIDs: designationIDs, 
       activityIDs: activityIDs };
  this.activitymasterSer.getActivityDetailsDraftData(myData).subscribe((data1: any[])=>{
      
    this.dummyDataSource= new MatTableDataSource<ActivityListDetails>(data1);
    console.log(data1);
    //Start/Added for getting Business area list 

this.workSheetTables=[];
for(let i = 0; i < this.businessList.length; i++)
{
/*   if(this.formInstance.value.businessControl.includes(this.businessList[i].id)){ */
  this.workSheetTables.push({
    displayedColumns: ['BgColor','FontColor','FontWeight','SourceOfActivity','CatBatReference','CPReference','MIReference','BRReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat','BuisnessArea','BusinessAreaCode'],
    dataSource: new MatTableDataSource(this.dummyDataSource.filteredData.filter(row=>row.businessAreaCode===this.businessList[i].code)),
    tableId: this.businessList[i].code
  });
/*  } */
}

console.log(this.workSheetTables);
//END/Added for getting Business area list

setTimeout(()=>{
  this.executeafterDelay();
},5000);
   });
  
}



applyBgColorFontColorFontweightForColumns(worksheet: XLSX.WorkSheet, data: any[]):void{
  if(data.length >0){
// Assuming your data starts at row 2 because row 1 is headers
const range: XLSX.Range = XLSX.utils.decode_range(worksheet['!ref']);
for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
const cellRef_bgColor = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
const cellRef_fontColor = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
const cellRef_bold = XLSX.utils.encode_cell({ r: rowNum, c: 2 });

const colorCell: XLSX.CellObject = worksheet[cellRef_bgColor];
const cell_fontColor : XLSX.CellObject = worksheet[cellRef_fontColor];
const cell_bold : XLSX.CellObject = worksheet[cellRef_bold];
let fontWeight: boolean;
let fontColor:string;
if(cell_bold != undefined){
if(cell_bold.v.toString().toLowerCase() === 'bold'){
  fontWeight= true
}else{
  fontWeight=false
}
}

if(cell_fontColor){
 let fontColor_Name= cell_fontColor.v;
  if(fontColor_Name== 'red'){
    fontColor='#FF0000';
  }else if(fontColor_Name =='black'){
    fontColor='#000000';
  }
}
fontColor= fontColor.toString().substring(1);
if (colorCell) {
  let color = colorCell.v; // Get the value
  if(color== 'White'){
    color='#FFFFFF';
  }
  color= color.toString().substring(1);
  // Apply the color to each cell in the row
  for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
      
      if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
      worksheet[cellAddress].s = {
        fill:{
          fgColor:{rgb:color}
        },
        font:{
          bold: fontWeight,
          color:{rgb:fontColor},
          name: 'ABBvoice',
        },
        alignment: {
          vertical: colNum ==11 ? 'left' :'center',
          horizontal: colNum ==11 ? 'left' :'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          },
          top: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };
      if(worksheet[cellAddress].l != undefined){
        worksheet[cellAddress].s = {
          fill:{
            fgColor:{rgb:color}
          },
          font:{
            bold: fontWeight,
            color:{rgb:"0000FF"},
            name: 'ABBvoice',
            underline:true
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1', // any truthy value here
          },
          border: {
            right: {
              style: 'thin',
              color: '000000',
            },
            bottom: {
              style: 'thin',
              color: '000000',
            },
            top: {
              style: 'thin',
              color: '000000',
            },
            left: {
              style: 'thin',
              color: '000000',
            },
          },
        }
      }
  }
}
}
  }
let dataLength : number;
if(data.length==0){ 
dataLength = 3;
}else{
dataLength = data.length;
}

for(let i = 0; i < dataLength; i++) {
for(let j = 0; j < 3; j++) {
delete worksheet[XLSX.utils.encode_cell({r: i,c: j })]; } }

for (let i = 0; i < dataLength + 1; i++) {
const cellD = XLSX.utils.encode_cell({ r: i, c: 3 }); // Cell in column D
const cellA = XLSX.utils.encode_cell({ r: i, c: 0 }); // Corresponding cell in column A
worksheet[cellA] = worksheet[cellD]; // Move data
delete worksheet[cellD]; // Delete original data in column D

const cellE = XLSX.utils.encode_cell({ r: i, c: 4 }); // Cell in column D
const cellB = XLSX.utils.encode_cell({ r: i, c: 1 }); // Corresponding cell in column A
worksheet[cellB] = worksheet[cellE]; // Move data
delete worksheet[cellE]; // Delete original data in column D

const cellF = XLSX.utils.encode_cell({ r: i, c: 5 }); // Cell in column D
const cellC = XLSX.utils.encode_cell({ r: i, c: 2 }); // Corresponding cell in column A
worksheet[cellC] = worksheet[cellF]; // Move data
delete worksheet[cellF]; // Delete original data in column D

const cellG = XLSX.utils.encode_cell({ r: i, c: 6 }); // Cell in column D
const cell_D = XLSX.utils.encode_cell({ r: i, c: 3 }); // Corresponding cell in column A
worksheet[cell_D] = worksheet[cellG]; // Move data
delete worksheet[cellG]; // Delete original data in column D

const cellH = XLSX.utils.encode_cell({ r: i, c: 7 }); // Cell in column D
const cell_E = XLSX.utils.encode_cell({ r: i, c: 4 }); // Corresponding cell in column A
worksheet[cell_E] = worksheet[cellH]; // Move data
delete worksheet[cellH]; // Delete original data in column D

const cellI = XLSX.utils.encode_cell({ r: i, c: 8 }); // Cell in column D
const cell_F = XLSX.utils.encode_cell({ r: i, c: 5 }); // Corresponding cell in column A
worksheet[cell_F] = worksheet[cellI]; // Move data
delete worksheet[cellI]; // Delete original data in column D

const cellJ = XLSX.utils.encode_cell({ r: i, c: 9 }); // Cell in column D
const cell_G = XLSX.utils.encode_cell({ r: i, c: 6 }); // Corresponding cell in column A
worksheet[cell_G] = worksheet[cellJ]; // Move data
delete worksheet[cellJ]; // Delete original data in column D

const cellK = XLSX.utils.encode_cell({ r: i, c: 10 }); // Cell in column D
const cell_H = XLSX.utils.encode_cell({ r: i, c: 7 }); // Corresponding cell in column A
worksheet[cell_H] = worksheet[cellK]; // Move data
delete worksheet[cellK]; // Delete original data in column D

const cellL = XLSX.utils.encode_cell({ r: i, c: 11 }); // Cell in column D
const cell_I = XLSX.utils.encode_cell({ r: i, c: 8 }); // Corresponding cell in column A
worksheet[cell_I] = worksheet[cellL]; // Move data
delete worksheet[cellL]; // Delete original data in column D

const cellM = XLSX.utils.encode_cell({ r: i, c: 12 }); // Cell in column D
const cell_J = XLSX.utils.encode_cell({ r: i, c: 9 }); // Corresponding cell in column A
worksheet[cell_J] = worksheet[cellM]; // Move data
delete worksheet[cellM]; // Delete original data in column D

const cellN = XLSX.utils.encode_cell({ r: i, c: 13 }); // Cell in column D
const cell_K = XLSX.utils.encode_cell({ r: i, c: 10 }); // Corresponding cell in column A
worksheet[cell_K] = worksheet[cellN]; // Move data
delete worksheet[cellN]; // Delete original data in column D

const cellO = XLSX.utils.encode_cell({ r: i, c: 14 }); // Cell in column D
const cell_L = XLSX.utils.encode_cell({ r: i, c: 11 }); // Corresponding cell in column A
worksheet[cell_L] = worksheet[cellO]; // Move data
delete worksheet[cellO]; // Delete original data in column D

const cellP = XLSX.utils.encode_cell({ r: i, c: 15 }); // Cell in column D
const cell_M = XLSX.utils.encode_cell({ r: i, c: 12 }); // Corresponding cell in column A
worksheet[cell_M] = worksheet[cellP]; // Move data
delete worksheet[cellP]; // Delete original data in column D

const cellQ = XLSX.utils.encode_cell({ r: i, c: 16 }); // Cell in column D
const cell_N = XLSX.utils.encode_cell({ r: i, c: 13 }); // Corresponding cell in column A
worksheet[cell_N] = worksheet[cellQ]; // Move data
delete worksheet[cellQ]; // Delete original data in column D

const cellR = XLSX.utils.encode_cell({ r: i, c: 17 }); // Cell in column D
const cell_O = XLSX.utils.encode_cell({ r: i, c: 14 }); // Corresponding cell in column A
worksheet[cell_O] = worksheet[cellR]; // Move data
delete worksheet[cellR]; // Delete original data in column D

const cellS = XLSX.utils.encode_cell({ r: i, c: 18 });
const cell_P = XLSX.utils.encode_cell({ r: i, c: 15 }); // Corresponding cell in column A
worksheet[cell_P] = worksheet[cellS]; // Move data
delete worksheet[cellS];
  }
    }
  getToolTip() : string {
    return this.editDisable ? this.showToolTip : '';
  }
  

// filter recursively on a text string using property object value
filterRecursive(filterText: string, array: any[], property: string) {
  let filteredData;
 
  //make a copy of the data so we don't mutate the original
  function copy(o: any) {
    return Object.assign({}, o);
  }
 
  // has string
  if (filterText) {
    // need the string to match the property value
    filterText = filterText.toLowerCase();
    // copy obj so we don't mutate it and filter
    filteredData = array.map(copy).filter(function x(y) {
      if (y[property].toLowerCase().includes(filterText)) {
        return true;
      }
      // if children match
      if (y.children) {
        return (y.children = y.children.map(copy).filter(x)).length;
      }
    });
    // no string, return whole array
  } else {
    filteredData = array;
  }
 
  return filteredData;
 }

 // pass mat input string to recursive function and return data
 filterTree(filterText: string) {
  // use filter input text, return filtered TREE_DATA, use the 'name' object value
  this.dataSource_Tree.data = this.filterRecursive(filterText, this.data, 'text');
  this.treeControl.dataNodes = this.data;
}

  applyFilterForTree(event: Event) {
    const filterText = (event.target as HTMLInputElement).value;
    this.filterTree(filterText);
    // show / hide based on state of filter string
    if (filterText) {
     this.tree.treeControl.collapseAll();
    } else {
     
     this.tree.treeControl.expandAll();
    }
  }

  ClearSelection()
  {
    this.searchValue = '';
    /*  this.txtTreeViewSelected.nativeElement.value='' ;  */
    this.getSelectedItemsList   = "Select Activity!";
    this.checklistSelection.clear();
    this.activitymasterSer.getSearchActivityList(this.formInstance.value.businessControl.join(",")).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);

      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
       this.setParent(this.dataSource_Tree.data[x], null);
     });

    this.dataChange.next(data);
    }); 
  }

  DeleteNode(node: TodoItemFlatNode)
  {

    var busIDs="0"
    var divIDs="0"
    var designationIDs="0"
    var activityIDs="0";
  
    if(this.formInstance.value.businessControl!="")
      busIDs=this.formInstance.value.businessControl.toString();
    
     activityIDs= node.id.toString();
  
  if(activityIDs!="0")
    {
      var emailId = sessionStorage.getItem('LoginEmail');
      var myData = { busIDs: busIDs, 
        divIDs: divIDs, 
        designationIDs: designationIDs, 
        activityIDs: activityIDs,
        emailId:emailId };

        this.activitymasterSer.deleteActivityNode(myData).subscribe((data1: any[])=>{
      
           
            this.activitymasterSer.getActivityList(this.formInstance.value.businessControl).subscribe((data1: any[])=>{
              console.log(data1);
              const data = this.treeConstruct(data1);
              this.dataSource_Tree.data = data;
              this.treeControl.dataNodes = data;
              Object.keys(this.dataSource_Tree.data).forEach(x => {
                this.setParent(this.dataSource_Tree.data[x], null);
              });
              this.dataChange.next(data);
            })  
        
            this.activitymasterSer.getSearchActivityList(this.formInstance.value.businessControl).subscribe((data1: any[])=>{
              console.log(data1);
              const data = this.treeConstruct(data1);
              this.dataSource_Tree_new.data = data;
              this.treeControl_new.dataNodes = data;
              for (let i = 0; i < this.treeControl_new.dataNodes.length; i++) {
                if (this.treeControl_new.dataNodes[i].isChecked == true) {
                  this.treeControl_new.expand(this.treeControl_new.dataNodes[i])
                }
              }
              
              Object.keys(this.dataSource_Tree_new.data).forEach(x => {
                this.setParent(this.dataSource_Tree_new.data[x], null);
              });
              this.dataChange_new.next(data);
            })


            this.checklistSelection.clear();

        });
  
    }
  }

addActivityToBusinessArea(){
  var busIDs="0"
  var divIDs="0"
  var designationIDs="0"
  var activityIDs="0";
  this.formInstance.value.searchString_new='';
  if(this.businessFormControl.value!="")
    //busIDs=this.formInstance.value.businessControl.toString()
  busIDs=this.businessFormControl.value.toString();

  if(this.checklistSelection.selected.map(s => s.id).join(",") !="")
   activityIDs=this.checklistSelection.selected.map(s => s.id).join(",")  

if(activityIDs!="0")
  {
    var emailId = sessionStorage.getItem('LoginEmail');
    var myData = { busIDs: busIDs, 
      divIDs: divIDs, 
      designationIDs: designationIDs, 
      activityIDs: activityIDs,
      emailId:emailId };

  this.activitymasterSer.addActivityToBusinessArea(myData).subscribe((data1: any[])=>{
    /* console.log(data1);
    const data = this.treeConstruct(data1);
    this.dataSource_Tree_new.data = data;
    this.treeControl_new.dataNodes = data; */
    this.activitymasterSer.getActivityList(this.businessFormControl.value).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
      this.dataSource_Tree.data = data;
      this.treeControl.dataNodes = data;
      Object.keys(this.dataSource_Tree.data).forEach(x => {
        this.setParent(this.dataSource_Tree.data[x], null);
      });
      this.dataChange.next(data);
    })  

    this.activitymasterSer.getSearchActivityList(this.businessFormControl.value).subscribe((data1: any[])=>{
      console.log(data1);
      const data = this.treeConstruct(data1);
      this.dataSource_Tree_new.data = data;
      this.treeControl_new.dataNodes = data;
      for (let i = 0; i < this.treeControl_new.dataNodes.length; i++) {
        if (this.treeControl_new.dataNodes[i].isChecked == true) {
          this.treeControl_new.expand(this.treeControl_new.dataNodes[i])
        }
      }
      
      Object.keys(this.dataSource_Tree_new.data).forEach(x => {
        this.setParent(this.dataSource_Tree_new.data[x], null);
      });
      this.dataChange_new.next(data);
    })

    this.checklistSelection.clear();
  }) 
}else
{
  if(this.formInstance.value.businessControl!="")
  this.snackBarLoader("Please select activity to map with selected division.",'Done','1000', 'red-snackbar')
        
}

  
}

snackBarLoader(content, action, duration, color){
  this.snackBar.open(content, action, {
    duration: 2000,
    verticalPosition: "top", 
    horizontalPosition: "center", 
    panelClass: [color]
  });
}
openActivityDataMapping(data : any){
   
  const dialogRef = this.dialog.open(ActivityDataMappComponent,{
    height: '600px',
    width: '1200px',
    data: {
      businessUnit: this.selectedData.value,
      activityIDs : data.id,
      businessName:this.selectedData.text,
      activityName:data.text,
    }, 
  });
  dialogRef.afterClosed().subscribe({
    next: (val) => {
      this.activitymasterSer.getActivityHistoryTempTRNCount().subscribe((data:any)=>{
        if(data >0){
         this.showHistory= true;
        }
       });
    }
  })
}
createHistoryClick(){
  this.historyServ.currentActivitySaveToHistoryTemp(this.formInstance.value.businessControl.toString(), Number(sessionStorage.getItem('userId')) ).subscribe((data1: any)=>{
    console.log(data1);
    this.snackBarLoader("History Created Successfully with History Code: "+data1[0].historyCode,'Done','1000', 'red-snackbar')
    this.editDisable=false;
    this.showHistory=false;
  });
}
}



