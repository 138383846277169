import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule ,ReactiveFormsModule} from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/* import {CdkTreeModule} from '@angular/cdk/tree'; */
/* import { MatTreeNestedDataSource } from "@angular/material/tree/data-source/nested-data-source"; */
import {MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './shared/app-material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDialog} from '@angular/material/dialog';
import { LoadingComponentComponent } from './loading/loading-component/loading-component.component';
import { LoadingInterceptor} from './Services/loading.interceptor';
import { LoadingService} from './Services/loading.service';
import { from } from 'rxjs';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SideNavComponent, ReportIssueDialog, ProcessFlowDialog } from './sidenav/side-nav.component';
import { AvatarModule } from 'ngx-avatar';
import { FlatpickrModule } from 'angularx-flatpickr';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ApiservicesService } from './Services/apiservices.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 /* import { TreeviewModule } from 'ngx-treeview';  */

 import { HttpClient } from '@angular/common/http';



import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 
import * as moment from 'moment';
//  import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
// import { MatFileUploadModule } from 'angular-material-fileupload';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';   
import { MatCheckboxModule } from '@angular/material/checkbox';

// import {MatDatetimepickerModule} from '@mat-datetimepicker/core';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {MatBadgeModule} from '@angular/material/badge';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { BnNgIdleService } from 'bn-ng-idle';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

import { ReportComponent } from './admin/report/report.component';

import { TreeviewComponent } from './admin/treeview/treeview.component';
 import { PopupComponent } from './admin/popup/popup.component';
import { SearchComponent } from './admin/search/search.component'; 

import {cloneDeep}  from 'lodash/cloneDeep';

import { AddEntityDialog, EntityComponent } from './admin/entity/entity.component';
import { AddBusinessAreaDialog, BusinessAreaComponent } from './admin/business-area/business-area.component';
import { AddDivisionDialog, DivisionComponent } from './admin/division/division.component';
import { AddDesignationDialog, DesignationComponent } from './admin/designation/designation.component';
import { AddPreambleDialog, PreambleComponent } from './admin/preamble/preamble.component';
import { AddReadingDocumentDialog, ReadingThisDocumentComponent } from './admin/reading-this-document/reading-this-document.component';

import { MatSelectFilterModule } from 'mat-select-filter';
import { ActivityMasterComponent,AddActivityDialog } from './admin/activity-master/activity-master.component';
import { ActivityMappingComponent } from './admin/activitymapping/activitymapping.component';
import { ActivityDataComponent, CPMIBRReference } from './admin/activity-data/activity-data.component';
import { ActivityDataMappComponent } from './admin/activity-data-mapp/activity-data-mapp.component';
import { NgxEditorModule } from 'ngx-editor';
import { HistoryComponent } from './admin/history/history.component'; 
import { AddDesignationMappingDialog, DesignationMappingComponent } from './admin/designation-mapping/designation-mapping.component';
import { environment } from '../environments/environment';
import { AddUserRoleMappingDialogComponent, UserRoleMappingComponent } from './admin/user-role-mapping/user-role-mapping.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
 
  console.log(message);

}

const avatarColors = ["#e7cb25", "#95a5a6", "#f39c12", "#ffd100", "#5bbb4c","#a9a9a9", "#262626"];
 
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({

    auth: {
      //Local and Dev ClientId
     //clientId: '94929b7a-6c22-46d7-97e7-8d616d5185e6',

       //QA  and UAT ClientId
      // clientId: '1c26ffc4-7bc8-4b38-a669-4129bab6543f', 

      //authority: 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd',
      
     /*  redirectUri: 'http://localhost:4200/',  */  
 
    //redirectUri : 'https://dev.table-of-authority.in.abb.com/',   
   //   redirectUri : 'https://qa.table-of-authority.in.abb.com/',  
   // redirectUri : 'https://stage.table-of-authority.in.abb.com/',  
   redirectUri: environment.redirectUri ,
   clientId: environment.clientId,
   authority: environment.authority,
    },
  

    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },

    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}


 
@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    LoadingComponentComponent,
    DashboardComponent,
    ReportComponent,
    TreeviewComponent ,
    PopupComponent,
    SearchComponent ,
    ReportIssueDialog,
    ProcessFlowDialog,
    EntityComponent,
    AddEntityDialog,
    AddBusinessAreaDialog,
    AddDesignationDialog,
    AddDivisionDialog,
    BusinessAreaComponent,
    DivisionComponent,
    DesignationComponent,
    ActivityMasterComponent,
    AddActivityDialog,
    ActivityMappingComponent,
   PreambleComponent,
  AddPreambleDialog, 
  ReadingThisDocumentComponent,
  AddReadingDocumentDialog,
    ActivityDataComponent,CPMIBRReference, ActivityDataMappComponent,
    HistoryComponent,
    DesignationMappingComponent,
    AddDesignationMappingDialog,
    UserRoleMappingComponent,
    AddUserRoleMappingDialogComponent
  ],
  imports: [
    BrowserModule, MatNativeDateModule,
    AppRoutingModule,
    NgxEditorModule,
    BrowserAnimationsModule,NgbModalModule,FlatpickrModule.forRoot(),
    AppMaterialModule,FlexLayoutModule, MatDialogModule,   FormsModule, MatFormFieldModule,
    NgxIntlTelInputModule,NgxMaterialTimepickerModule,MatSelectModule, MatInputModule,
    MatTreeModule,MatIconModule,MatCheckboxModule,MatSelectFilterModule,
    HttpClientModule,ReactiveFormsModule, NgbModule, MatBadgeModule, AvatarModule.forRoot({
      colors: avatarColors
    }), CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    
  ], 
  providers: [
    ApiservicesService,  {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {

      provide: MSAL_INSTANCE,
  
      useFactory: MSALInstanceFactory
  
    },
  
    {
  
      provide: MSAL_GUARD_CONFIG,
  
      useFactory: MSALGuardConfigFactory
  
    },
  
    {
  
      provide: MSAL_INTERCEPTOR_CONFIG,
  
      useFactory: MSALInterceptorConfigFactory
  
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  
    MsalService,
  
    MsalGuard,
  
    MsalBroadcastService,
    LoadingService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
 
  
   
  ],

  
  entryComponents: [ 
    // AddDataDialogue,
    // AddAssetDetailsDialogue, 
    // AssignAssetDialog,  
    // AddSupplierDialog,
    // AssignBreakFixDialog,
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
 