import { Component, OnInit,Inject } from '@angular/core';
import { ActivityDataService } from 'src/app/Services/activitydata.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ViewChild,ElementRef, HostListener } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tr } from 'date-fns/locale';
import { newArray } from '@angular/compiler/src/util';
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { Editor, Toolbar, toDoc } from 'ngx-editor';

export interface BusinessAreaMST{
  id:number;
  name: string;
}

export interface MasterDataMST{
  id:number;
  shortName: string;
  name: string;
  status:boolean;
}

export class ActivityDataDetails {
  Id:number;
  CategoryId : number;
  CategoryName: string
  IsLink: boolean;
  RefText: string;
  RefLink: string;
  IsDesignation: boolean;
  DesignationMapId: number;
  DesignationName: string;
  BusinessUnit: number;
  BusinessUnitName: string;
  IsShowDelete: boolean;
}
export interface ActivityMST_Delete
{
  Id:number;
  CategoryId : number;
  IsLink: boolean;
  RefText: string;
  RefLink: string;
  IsDesignation: boolean;
  DesignationMapId: number;
  Status: boolean;
  BuisnessUnit: number;
  CreatedBy: number;
  CreatedDate: Date;
  UpdatedBy: number;
  UpdatedDate: Date;
  IsDeleted:boolean;
  IsShowDelete: boolean;
}
@Component({
  selector: 'app-activity-data',
  templateUrl: './activity-data.component.html',
  styleUrls: ['./activity-data.component.scss']
})

export class ActivityDataComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  businessList: BusinessAreaMST[];
  masterDataList: MasterDataMST[];
  formInstance: any;
  selectedBusinessAreaControl = new FormControl();
  selectedCategoryControl = new FormControl();
  showAddButton:boolean=false;
  selectedBusinessArea:string;
  selectedCategory:string;
  dataSource_ActivityData : MatTableDataSource<ActivityDataDetails>;
  displayedColumns_ActivityData: string[] = ['BusinessUnitName','spacerColumn','CategoryName','spacerColumn','IsLink','spacerColumn','RefText','spacerColumn','RefLink','spacerColumn','IsDesignation','spacerColumn','Status','spacerColumn','action'];
  constructor(private activityData: ActivityDataService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getBuisnessArea();
    this.getMasterData();
  }

  deleteSend : ActivityMST_Delete={
    Id:0, CategoryId: 0, IsLink: false, RefText: "", RefLink: "", IsDesignation: false, DesignationMapId:0, Status:false, BuisnessUnit:0, CreatedBy:Number(sessionStorage.getItem('userId')),CreatedDate: new Date(), UpdatedBy:Number(sessionStorage.getItem('userId')),UpdatedDate: new Date(), IsDeleted:true, IsShowDelete: true
  }

  getBuisnessArea(){
    this.activityData.getBuisnessAreaList().subscribe((data:any)=>{
      this.businessList = data,
      () => console.log('Get all complete'); 
    });
  }
  getMasterData(){
    this.activityData.getCategoryList().subscribe((data:any)=>{
      this.masterDataList = data,
      this.masterDataList.shift(),
      () => console.log('Get all complete'); 
    });
  }
  businessAreaSelection(){
   if(this.selectedBusinessAreaControl.value != null && this.selectedCategoryControl.value != null){
    this.showAddButton= true;
   }else{
    this.showAddButton= false;
   }
  }
  categorySelection(){
    if(this.selectedBusinessAreaControl.value !=null && this.selectedCategoryControl.value !=null){
      this.showAddButton= true;
     }else{
      this.showAddButton= false;
     }
  }
  retrieve(){
    if(this.selectedBusinessAreaControl.value ==null ){
      this.activityData.ShowSnackBar("Please Choose Business Area",'rd-snackbar');
      return;
    }
    if(this.selectedCategoryControl.value ==null ){
      this.activityData.ShowSnackBar("Please Choose Category",'rd-snackbar');
      return;
    }
    else{
      this.activityData.getActivityMasterDataListFromBusinessIDandCategoryID(this.selectedBusinessAreaControl.value,this.selectedCategoryControl.value['id']).subscribe((data: any[])=>
        {
        this.dataSource_ActivityData= new MatTableDataSource<ActivityDataDetails>(data);
        console.log(this.dataSource_ActivityData);
        this.dataSource_ActivityData.paginator = this.paginator;
        this.dataSource_ActivityData.sort = this.sort;
      });
    }
   
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_ActivityData.filter = filterValue.trim().toLowerCase();
    if (this.dataSource_ActivityData.paginator) {
      this.dataSource_ActivityData.paginator.firstPage();
    }

  }
  openDialogBox_CPMIBRReference(){
    const dialogRef = this.dialog.open(CPMIBRReference,{
      height: '400px',
      width: '600px',
      data: {
        isSave: true,
        id:0,
        businessUnit: this.selectedBusinessAreaControl.value,
        categoryId : this.selectedCategoryControl.value.id,
        categoryName: this.selectedCategoryControl.value.name
      }, 
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.retrieve();
      }
    })
  }
  loadResourceToEdit(data : any){
    const dialogRef = this.dialog.open(CPMIBRReference,{
      height: '400px',
      width: '600px',
     data,
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
      this.retrieve();
     }
   })
 }
  delete(data: any) {
    
    if(data.status=='Active'){
      data.status= true;
    }else{
      data.status= false;
    }
    this.deleteSend.Id=data.id;
    this.deleteSend.CategoryId= data.categoryId;
    this.deleteSend.IsLink= data.isLink;
    this.deleteSend.RefText= data.refText;
    this.deleteSend.RefLink= data.refLink;
    this.deleteSend.IsDesignation= data.isDesignation;
    this.deleteSend.DesignationMapId= data.designationMapId;
    this.deleteSend.Status= data.status;
    this.deleteSend.BuisnessUnit= data.businessUnit;
    this.deleteSend.IsDeleted= true;
    if (confirm("Are you sure you want to delete this")) {  
      this.activityData.deleteActivityData(this.deleteSend).subscribe(() => {  
        this.retrieve();
      });  
     } 
  }  
}


export interface ActivityMST_Insert_Update
{
  Id:number;
  CategoryId : number;
  IsLink: boolean;
  RefText: string;
  RefLink: string;
  IsDesignation: boolean;
  DesignationMapId: number;
  Status: boolean;
  BuisnessUnit: number;
  CreatedBy: number;
  CreatedDate: Date;
  UpdatedBy: number;
  UpdatedDate: Date;
  IsDeleted: boolean;
}

export interface ActivityMST_Insert_Update_ValuesToSend{
  Id:number;
  CategoryId : number;
  IsLink: boolean;
  RefText: string;
  RefLink: string;
  IsDesignation: boolean;
  DesignationMapId: number;
  Status: boolean;
  BuisnessUnit: number;
  CreatedBy: number;
  CreatedDate: Date;
  UpdatedBy: number;
  UpdatedDate: Date;
  IsDeleted: boolean;
}
export interface DesignationMST{
  id:number;
  name: string;
}
@Component ({
  selector: 'app-activity-data',
  templateUrl: './CPMIBRReference.html',
  styleUrls: ['./activity-data.component.scss']})
 
  export class CPMIBRReference implements OnInit {

    searchValueText: string = '';
    formInstance: FormGroup;
    businessUnitId: number;
    categoryId: number;
    categoryName: string;
    designationList: DesignationMST[]=[];
    refText:string;
    radiobutton:string = "isLink";
    refLink:string;
    plainText: string
    designationMapId: number;
    designationName: string;
    isCPMIBRReference:boolean= false;
    isRACI: boolean= false;
    isRemark: boolean=false;
    isOther:boolean=false;
    enableLink: boolean=true;
    enablePlainText:boolean=false;
    enableDesignation:boolean=false;
    designationID: number;
    links: string[]=[];
    isSave: boolean=false;
    statusCheck: boolean=false;
    disableRadioButton: boolean=false;
    showReset: boolean=false;
    activityMST_Insert_Update_ValuesToSend: ActivityMST_Insert_Update_ValuesToSend[] = [];

    @ViewChild('searchDesignation') searchDesignationTextBox: ElementRef;
  selectedDesignationId= new FormControl([]);
  selectedDesignationValue= new FormControl([]);
  selectDesignationFormControl = new FormControl([]);
  searchDesignationTextboxControl = new FormControl();
  selectedDesignationValues = [];
  designationFilteredOptions: Observable<DesignationMST[]>= of(this.designationList);
  allSelectedDesignation=false;
  isMultipleSelectedDesignation: boolean = false;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild('selectDesignation') selectDesignation: MatSelect;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<CPMIBRReference>,
    private activityData: ActivityDataService,private _formBuilder: FormBuilder)
    {
  };


  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline'],
    ['ordered_list', 'bullet_list','link']
  ];
  topicEditor: Editor;
  remarksEditor: Editor;


    ngOnInit(): void {
      this.topicEditor = new Editor();
    this.remarksEditor = new Editor();

      this.businessUnitId=  this.data.businessUnit;
      this.categoryId= this.data.categoryId;
      this.categoryName= this.data.categoryName;
      this.isSave= this.data.isSave;
      this.getDesignationList();
      console.log(this.data);
      this.refText= this.data.refText;  
      this.plainText="";

      if(this.data.isLink== true)
      {
        this.radiobutton= "isLink";
        this.refLink= this.data.refLink;
        this.enableLink= true;
        this.enablePlainText= false;
        this.enableDesignation=false;
      }
      else if(this.data.isLink== false && this.data.refText !=""){
        this.radiobutton="isPlainText";
        this.plainText= this.data.refText;
        this.enablePlainText= true;
        this.enableLink= false;
        this.enableDesignation=false;
      }
      if(this.data.isDesignation== true)
        {
          this.radiobutton="isDesignation";
          this.designationMapId= this.data.designationMapId;
          this.designationName= this.data.refText;
          this.enableDesignation=true;
          this.enableLink= false;
          this.enablePlainText= false;
          this.refText=undefined;
          
        }
        if(this.isSave == true){
          this.statusCheck= true;
          this.disableRadioButton= true;
          this.showReset=true;
        }
      else if(this.data.status=='Active'){
        this.statusCheck= true;
      }else{
        this.statusCheck=false;
      }
     
      
      if(this.categoryName=='CPReference' || this.categoryName=='MIReference' || this.categoryName=='BRReference'){
        this.isCPMIBRReference=true;
      }
      else if(this.categoryName=='Responsible' || this.categoryName=='Accountable'|| this.categoryName=='Consult'|| this.categoryName=='Inform'){
        this.isRACI= true;
      }
      else if(this.categoryName=='Remark'){
       
        this.isRemark = true;
      }
      else{
        this.isOther= true;
      }
    }

    activityMST_Insert_Update : ActivityMST_Insert_Update={
      Id:0, CategoryId: 0, IsLink: false, RefText: "", RefLink: "", IsDesignation: false, DesignationMapId:0, Status:false, BuisnessUnit:0, CreatedBy:Number(sessionStorage.getItem('userId')),CreatedDate: new Date(), UpdatedBy:Number(sessionStorage.getItem('userId')),UpdatedDate: new Date(), IsDeleted:false
    }
    addItem(id:number, name: string){
      const newItem: DesignationMST={id,name};
      this.designationList.push(newItem);
    }
    cancel(){
this.refText='';
this.refLink='';
this.designationName='';
this.radiobutton='isLink';
this.plainText='';
this.enableLink=true;
this.enablePlainText=false;
this.enableDesignation= false;
    }

  getDesignationList(){

    this.activityData.getDesignationList(this.businessUnitId, this.categoryId).subscribe((data: any[])=>
        {
           
       this.designationList= data,
       this.designationFilteredOptions = this.searchDesignationTextboxControl.valueChanges
       .pipe(
         startWith<string>(''),
         map(name => this.designationFilter(name))
       ); 
       () => console.log('Get all complete'); 
       if(this.isSave == undefined){
        this.addItem(this.data.designationMapId, this.data.refText);
      }
      });
  }
  private designationFilter(name: string) {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setDesignationSelectedValues();
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);
    this.selectDesignationFormControl.patchValue(this.selectedDesignationValues);
    this.selectDesignationFormControl.value.forEach(item=> {
      this.selectedDesignationId.value.push(item.id);
      this.selectedDesignationValue.value.push(item.name);
    });
    let filteredList = this.designationList.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  designationSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedDesignationValues.indexOf(event.source.value);
      this.selectedDesignationValues.splice(index, 1)
    }
  }
  openedDesignationChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchDesignationTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchDesignationTextBox.nativeElement.focus();
    }
  }

  clearDesignationSearch(event) {
    event.stopPropagation();
    this.searchDesignationTextboxControl.patchValue('');
  }

  setDesignationSelectedValues() {
    console.log('selectDesignationFormControl', this.selectDesignationFormControl.value);
    if (this.selectDesignationFormControl.value && this.selectDesignationFormControl.value.length > 0) {
      this.selectDesignationFormControl.value.forEach((e) => {
        if (this.selectedDesignationValues.indexOf(e) == -1) {
          this.selectedDesignationValues.push(e);
        }
      });
    }
  }

  selectedDesignationIdExists(id: number):boolean{
    return this.selectedDesignationId.value.some(item=>item === id);
  }
  selectedDesignationValueExists(value: string):boolean{
    return this.selectedDesignationValue.value.some(item=>item === value);
  }
  toggleAllSelectionDesignation() {
    if (this.allSelectedDesignation) {
      this.selectDesignation.options.forEach((item: MatOption) => item.select());
      this.isMultipleSelectedDesignation = true;
      this.selectedDesignationValue= new FormControl([]);
       this.selectedDesignationValue= new FormControl([]);
      
       this.selectDesignationFormControl.value.forEach(item=> {
        this.selectedDesignationId.value.push(item.id);
        this.selectedDesignationValue.value.push(item.name);
      });
    
    } else {
       this.selectDesignation.options.forEach((item: MatOption) => item.deselect()); 
       this.isMultipleSelectedDesignation = false;
       this.selectedDesignationId= new FormControl([]);
       this.selectedDesignationValue= new FormControl([]);
    }
  }
  designationSelection(){
    this.selectedDesignationId= new FormControl([]);
    this.selectedDesignationValue= new FormControl([]);
    if(this.selectedDesignationValues.length !=0){
      this.selectedDesignationValues.forEach(item=> {
        this.selectedDesignationId.value.push(item.id);
        this.selectedDesignationValue.value.push(item.name);
      });
     }
    this.selectDesignationFormControl.value.forEach(item=> {
      if(!this.selectedDesignationIdExists(item.id)){
        this.selectedDesignationId.value.push(item.id);
      }
      if(!this.selectedDesignationValueExists(item.name)){
        this.selectedDesignationValue.value.push(item.name);
      }
     
    });

    if(this.selectedDesignationId.value.length == this.designationList.length){
      this.allSelectedDesignation = true;
    }else{
      this.allSelectedDesignation = false;
    }
  }
    close()
  {
    this.dialogRef.close();
  }
  onStatusCheckboxChange(isChecked: boolean){
    this.statusCheck= isChecked;
  }
  isLinkRadioChange_Remarks(){
    if(this.radiobutton== "isLink"){
       this.enableLink= true;
       this.enablePlainText=false;
    }else if(this.radiobutton== "isPlainText"){
      this.enableLink= false;
       this.enablePlainText=true;
    }
  }
  isLinkRadioChange_RACI(){
    if(this.radiobutton== "isLink"){
      this.enableLink= true;
      this.enablePlainText=false;
      this.enableDesignation=false;
   }else if(this.radiobutton== "isPlainText"){
     this.enableLink= false;
      this.enablePlainText=true;
      this.enableDesignation=false;
   }else if(this.radiobutton =="isDesignation"){
    this.enableLink= false;
    this.enablePlainText=false;
    this.enableDesignation=true;
   }
  }
  designationSelectionSave(desID: number, desName: string){
    this.designationID= desID;
    this.designationName= desName;
  }
  isValidUrl(url:string):boolean{
    try{
      new URL(url);
      return true;
    }
    catch{
      return false;
    }
  }
  save()
  {
    this.activityMST_Insert_Update_ValuesToSend=[];
    let isValidUrl : boolean=false;
    var datatoCheck="";
   
    if(this.isCPMIBRReference== true)
      {
        
        if(this.isCPMIBRReference== true && (this.refText=="NA" || this.refText=="-"))
          {

          }
          else if((this.refText== undefined && this.isCPMIBRReference== true) || (this.refText== '' && this.isCPMIBRReference== true)){
            this.activityData.ShowSnackBar("Please enter Text for link",'rd-snackbar');
            return;
          }
          else if((this.refLink== undefined && this.isCPMIBRReference==true ) || (this.refLink== '' && this.isCPMIBRReference==true)){
            this.activityData.ShowSnackBar("Please enter RefLink",'red-snackbar');
            return;
          }
         /*  && this.refText!="NA" || this.refText!="-" */
      }
      else if(this.isRACI ==true)
      {
            if((this.radiobutton == "isLink" && this.refText == undefined) || (this.radiobutton == "isLink" && this.refText == '')){
              this.activityData.ShowSnackBar("Please enter Text for link",'red-snackbar');
              return;
            }
            else if(( this.radiobutton == "isLink" && this.refLink== undefined) || (this.radiobutton == "isLink" && this.refLink== '')){
              this.activityData.ShowSnackBar("Please enter RefLink",'red-snackbar');
              return;
            }
            else
            /* Plain Text */
            if((this.radiobutton=="isPlainText" && this.plainText== undefined) || (this.radiobutton=="isPlainText" &&this.plainText== '')){
              this.activityData.ShowSnackBar("Please enter PlainText",'red-snackbar');
              return;
            }
            /* else if(this.plainText != undefined && this.plainText.trim()==''){
              this.activityData.ShowSnackBar("Please enter valid PlainText",'red-snackbar');
                return;
            } */
            else if(this.isSave== true && this.radiobutton=="isDesignation" && this.selectedDesignationId.value.length== 0){
                this.activityData.ShowSnackBar("Please choose Designation",'red-snackbar');
                return;
            }
            
            
      }
      else if(this.isRemark ==true){
              /* if((this.radiobutton == "isLink" && this.refText == undefined) || (this.radiobutton == "isLink" && this.refText == '')){
                this.activityData.ShowSnackBar("Please enter Text for link",'red-snackbar');
                return;
              }
              else if(( this.radiobutton == "isLink" && this.refLink== undefined) || (this.radiobutton == "isLink" && this.refLink== '')){
                this.activityData.ShowSnackBar("Please enter RefLink",'red-snackbar');
                return;
              }
              
              if((this.radiobutton=="isPlainText" && this.plainText== undefined) || (this.radiobutton=="isPlainText" &&this.plainText== '')){
                this.activityData.ShowSnackBar("Please enter PlainText",'red-snackbar');
                return;
              }
              if(this.plainText != undefined && this.plainText.trim()==''){
                this.activityData.ShowSnackBar("Please enter valid PlainText",'red-snackbar');
                  return;
              } */

              if(this.plainText != undefined && (this.plainText.trim()=='<p></p>' || this.plainText.trim()=="") ){
                this.activityData.ShowSnackBar("Please enter valid PlainText",'red-snackbar');
                  return;
              }

      }
      else if(this.isOther==true)
      {
          if((this.isOther== true && this.refText== undefined) || (this.isOther== true && this.refText== '')){
            this.activityData.ShowSnackBar("Please enter Text",'red-snackbar');
            return;
          }
      }

    /* else{ */

    if(this.isCPMIBRReference== true)
         datatoCheck=this.refText;
        else if(this.isRACI== true)
          {
            if(this.radiobutton == "isLink")
              datatoCheck=this.refText;
            else  if(this.radiobutton == "isPlainText")
            datatoCheck=this.plainText;
            else  if(this.radiobutton == "isDesignation")
              datatoCheck="$$$$-$$$$-$$$";//Some dummy text
          }
          else if(this.isRemark== true)
            {
              /* if(this.radiobutton == "isLink")
                datatoCheck=this.refText;
              else  if(this.radiobutton == "isPlainText") */
              datatoCheck=this.plainText;
            }
            else if(this.isOther==true)
              {
                datatoCheck=this.refText;
              }

      this.activityData.CheckIfRefTextExists(this.businessUnitId,this.categoryId,datatoCheck).subscribe((dataCount:any)=>{
        isValidUrl= this.isValidUrl(this.refLink);
        if(dataCount >0 && this.isSave == true){
          this.activityData.ShowSnackBar("Entered text already exists under respective BusinessArea and Category",'red-snackbar');
          return; 
        }
        var isTextSave=false;
        if(((this.refLink == undefined && isValidUrl == false)||(this.refLink == "" && isValidUrl == false))&& this.isCPMIBRReference== true && (datatoCheck=="NA" || datatoCheck=="-"))
          {
            if(this.refLink== undefined || this.refLink == "")
              {
                this.refLink="";
                isTextSave=true;
              }
              
          }


            if(this.refLink != undefined && isValidUrl == false && isTextSave==false){
          
            
                this.activityData.ShowSnackBar("Please enter valid Link",'red-snackbar');
                return; 
            
        }
        else{
          if(this.categoryName=='CPReference' || this.categoryName=='MIReference' || this.categoryName=='BRReference'){
              this.activityMST_Insert_Update.CategoryId= this.categoryId;
              
              if(this.refText=="NA" || this.refText=="-")
              this.activityMST_Insert_Update.IsLink= false;
              else
              this.activityMST_Insert_Update.IsLink= true;

              this.activityMST_Insert_Update.RefText= this.refText;
              this.activityMST_Insert_Update.RefLink= this.refLink;
              this.activityMST_Insert_Update.IsDesignation= false;
              this.activityMST_Insert_Update.DesignationMapId=0;
              this.activityMST_Insert_Update.Status=this.statusCheck;
              this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
              this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
              this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
              this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
              this.activityMST_Insert_Update.UpdatedDate= new Date();
              this.activityMST_Insert_Update.IsDeleted= false;
              this.activityMST_Insert_Update_ValuesToSend.push({
                Id: this.data.id,
                CategoryId: this.categoryId,
                IsLink: this.activityMST_Insert_Update.IsLink,
                RefText: this.activityMST_Insert_Update.RefText,
                RefLink: this.activityMST_Insert_Update.RefLink,
                IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                Status: this.activityMST_Insert_Update.Status,
                BuisnessUnit:this.businessUnitId,
                CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                UpdatedDate: new Date(),
                IsDeleted: this.activityMST_Insert_Update.IsDeleted
              });
           }
           else if(this.categoryName=='Responsible' || this.categoryName=='Accountable'|| this.categoryName=='Consult'|| this.categoryName=='Inform'){
             if(this.radiobutton== "isLink"){
               this.activityMST_Insert_Update.RefText= this.refText;
               this.activityMST_Insert_Update.IsLink= true;
               this.activityMST_Insert_Update.RefLink= this.refLink;
               this.activityMST_Insert_Update.IsDesignation= false;
               this.activityMST_Insert_Update.DesignationMapId=0;
               this.activityMST_Insert_Update.Status=this.statusCheck;
             this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
             this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
             this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
             this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
             this.activityMST_Insert_Update.UpdatedDate= new Date();
             this.activityMST_Insert_Update.IsDeleted= false;
               this.activityMST_Insert_Update_ValuesToSend.push({
                Id: this.data.id,
                 CategoryId: this.categoryId,
                 IsLink: this.activityMST_Insert_Update.IsLink,
                 RefText: this.activityMST_Insert_Update.RefText,
                 RefLink: this.activityMST_Insert_Update.RefLink,
                 IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                 DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                 Status: this.activityMST_Insert_Update.Status,
                 BuisnessUnit:this.businessUnitId,
                 CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                 CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                 UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                 UpdatedDate: new Date(),
                 IsDeleted: this.activityMST_Insert_Update.IsDeleted
               });
             } 
             else if(this.radiobutton== "isPlainText"){
               this.activityMST_Insert_Update.RefText=this.plainText;
               this.activityMST_Insert_Update.IsLink= false;
               this.activityMST_Insert_Update.RefLink= '';
               this.activityMST_Insert_Update.IsDesignation= false;
               this.activityMST_Insert_Update.DesignationMapId=0;
               this.activityMST_Insert_Update.Status=this.statusCheck;
             this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
             this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
             this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
             this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
             this.activityMST_Insert_Update.UpdatedDate= new Date();
             this.activityMST_Insert_Update.IsDeleted= false;
               this.activityMST_Insert_Update_ValuesToSend.push({
                Id: this.data.id,
                 CategoryId: this.categoryId,
                 IsLink: this.activityMST_Insert_Update.IsLink,
                 RefText: this.activityMST_Insert_Update.RefText,
                 RefLink: this.activityMST_Insert_Update.RefLink,
                 IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                 DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                 Status: this.activityMST_Insert_Update.Status,
                 BuisnessUnit:this.businessUnitId,
                 CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                 CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                 UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                 UpdatedDate: new Date(),
                 IsDeleted: this.activityMST_Insert_Update.IsDeleted
               });
             }
             else if(this.radiobutton== "isDesignation"){
              if(this.isSave){
                 if(this.selectedDesignationId.value.length >0)
                  {
                  for(let i=0; i< this.selectedDesignationId.value.length;i++){
                    this.activityMST_Insert_Update.RefText= this.selectedDesignationValue.value[i];
                this.activityMST_Insert_Update.IsLink= false;
                this.activityMST_Insert_Update.RefLink= '';
                this.activityMST_Insert_Update.IsDesignation= true;
                this.activityMST_Insert_Update.DesignationMapId=this.selectedDesignationId.value[i];
                this.activityMST_Insert_Update.Status=this.statusCheck;
              this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
              this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
              this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
              this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
              this.activityMST_Insert_Update.UpdatedDate= new Date();
              this.activityMST_Insert_Update.IsDeleted= false;
                this.activityMST_Insert_Update_ValuesToSend.push({
                 Id: this.data.id,
                  CategoryId:this.categoryId,
                  IsLink: this.activityMST_Insert_Update.IsLink,
                  RefText: this.activityMST_Insert_Update.RefText,
                  RefLink: this.activityMST_Insert_Update.RefLink,
                  IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                  DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                  Status: this.activityMST_Insert_Update.Status,
                  BuisnessUnit:this.businessUnitId,
                  CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                  CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                  UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                  UpdatedDate: new Date(),
                  IsDeleted: this.activityMST_Insert_Update.IsDeleted
                });
                  }
                 }
              }
              else{
                this.activityMST_Insert_Update.RefText= this.designationName;
                this.activityMST_Insert_Update.IsLink= false;
                this.activityMST_Insert_Update.RefLink= '';
                this.activityMST_Insert_Update.IsDesignation= true;
                this.activityMST_Insert_Update.DesignationMapId=this.designationID;
                this.activityMST_Insert_Update.Status=this.statusCheck;
              this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
              this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
              this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
              this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
              this.activityMST_Insert_Update.UpdatedDate= new Date();
              this.activityMST_Insert_Update.IsDeleted= false;
                this.activityMST_Insert_Update_ValuesToSend.push({
                 Id: this.data.id,
                  CategoryId:this.categoryId,
                  IsLink: this.activityMST_Insert_Update.IsLink,
                  RefText: this.activityMST_Insert_Update.RefText,
                  RefLink: this.activityMST_Insert_Update.RefLink,
                  IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                  DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                  Status: this.activityMST_Insert_Update.Status,
                  BuisnessUnit:this.businessUnitId,
                  CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                  CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                  UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                  UpdatedDate: new Date(),
                  IsDeleted: this.activityMST_Insert_Update.IsDeleted
                });
              }
               
             }
           }
           else if(this.categoryName=='Remark'){
            /*  if(this.radiobutton== "isLink"){
               this.activityMST_Insert_Update.RefText= this.refText;
               this.activityMST_Insert_Update.IsLink= true;
               this.activityMST_Insert_Update.RefLink= this.refLink;
               this.activityMST_Insert_Update.IsDesignation= false;
               this.activityMST_Insert_Update.DesignationMapId=0;
               this.activityMST_Insert_Update.Status=this.statusCheck;
             this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
             this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
             this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
             this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
             this.activityMST_Insert_Update.UpdatedDate= new Date();
             this.activityMST_Insert_Update.IsDeleted= false;
               this.activityMST_Insert_Update_ValuesToSend.push({
                Id: this.data.id,
                 CategoryId: this.categoryId,
                 IsLink: this.activityMST_Insert_Update.IsLink,
                 RefText: this.activityMST_Insert_Update.RefText,
                 RefLink: this.activityMST_Insert_Update.RefLink,
                 IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                 DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                 Status: this.activityMST_Insert_Update.Status,
                 BuisnessUnit:this.businessUnitId,
                 CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                 CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                 UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                 UpdatedDate: new Date(),
                 IsDeleted: this.activityMST_Insert_Update.IsDeleted
               });
             } 
             else if(this.radiobutton== "isPlainText"){ */
               this.activityMST_Insert_Update.RefText=this.plainText;
               this.activityMST_Insert_Update.IsLink= false;
               this.activityMST_Insert_Update.RefLink= '';
               this.activityMST_Insert_Update.IsDesignation= false;
               this.activityMST_Insert_Update.DesignationMapId=0;
               this.activityMST_Insert_Update.Status=this.statusCheck;
             this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
             this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
             this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
             this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
             this.activityMST_Insert_Update.UpdatedDate= new Date();
             this.activityMST_Insert_Update.IsDeleted= false;
               this.activityMST_Insert_Update_ValuesToSend.push({
                Id: this.data.id,
                 CategoryId: this.categoryId,
                 IsLink: this.activityMST_Insert_Update.IsLink,
                 RefText: this.activityMST_Insert_Update.RefText,
                 RefLink: this.activityMST_Insert_Update.RefLink,
                 IsDesignation: this.activityMST_Insert_Update.IsDesignation,
                 DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
                 Status: this.activityMST_Insert_Update.Status,
                 BuisnessUnit:this.businessUnitId,
                 CreatedBy: this.activityMST_Insert_Update.CreatedBy,
                 CreatedDate:this.activityMST_Insert_Update.CreatedDate,
                 UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
                 UpdatedDate: new Date(),
                 IsDeleted: this.activityMST_Insert_Update.IsDeleted
               });
             //}
           }
           else if(this.isOther== true){
             this.activityMST_Insert_Update.RefText= this.refText;
             this.activityMST_Insert_Update.IsLink= false;
               this.activityMST_Insert_Update.RefLink= '';
               this.activityMST_Insert_Update.IsDesignation= false;
               this.activityMST_Insert_Update.DesignationMapId=0;
             this.activityMST_Insert_Update.Status=this.statusCheck;
             this.activityMST_Insert_Update.BuisnessUnit= this.businessUnitId;
             this.activityMST_Insert_Update.CreatedBy= this.activityMST_Insert_Update.CreatedBy;
             this.activityMST_Insert_Update.CreatedDate= this.activityMST_Insert_Update.CreatedDate;
             this.activityMST_Insert_Update.UpdatedBy= this.activityMST_Insert_Update.UpdatedBy;
             this.activityMST_Insert_Update.UpdatedDate= new Date();
             this.activityMST_Insert_Update.IsDeleted= false;
             this.activityMST_Insert_Update_ValuesToSend.push({
              Id: this.data.id,
               CategoryId: this.categoryId,
               IsLink: this.activityMST_Insert_Update.IsLink,
               RefText: this.activityMST_Insert_Update.RefText,
               RefLink: this.activityMST_Insert_Update.RefLink,
               IsDesignation: this.activityMST_Insert_Update.IsDesignation,
               DesignationMapId: this.activityMST_Insert_Update.DesignationMapId,
               Status: this.activityMST_Insert_Update.Status,
               BuisnessUnit:this.businessUnitId,
               CreatedBy: this.activityMST_Insert_Update.CreatedBy,
               CreatedDate:this.activityMST_Insert_Update.CreatedDate,
               UpdatedBy: this.activityMST_Insert_Update.UpdatedBy,
               UpdatedDate: new Date(),
               IsDeleted: this.activityMST_Insert_Update.IsDeleted
             });
           }
           this.activityData.ActivityDataSave(this.activityMST_Insert_Update_ValuesToSend).subscribe((data:any)=>{
             if(data !=""){
               this.activityData.ShowSnackBar("Data Saved Successfully",'red-snackbar'); 
               this.dialogRef.close(true);

             }
           });
        }
      });    
    /* } */
  }
}
