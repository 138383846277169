import { Component, OnInit,Inject } from '@angular/core';
import { ActivityDataMappingService } from 'src/app/Services/activitydatamapping.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ViewChild,ElementRef, HostListener } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SearchService } from "src/app/Services/search.service";
/* import { ActivatedRoute } from '@angular/router'; */
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { HistoryService } from 'src/app/Services/history.service';
import { Editor, Toolbar, toDoc } from 'ngx-editor';

export class ActivityDetails {
  ActivityId : number;
  SourceOfActivity: string;
  CatBatReference: number;
  CPReference: JSON;
  MIReference: JSON;
  BRReference: JSON;
  Tool: string;
  ApplicableToBusiness: string;
  Activity: string;
  Responsible: JSON;
  accountable: JSON;  
  Consult: JSON;
  Inform: JSON;
  Remark: JSON;
  ActivityFormingPartOfLat: string;
  BusinessArea: string;
  BgColor:string;
  FontColor:string;
  FontWeight:string;
}
export interface SrNumber{
  id:number;
  refText: string;
  isChecked: boolean;
}
export interface SourceOfActivity{
  id:number;
  refText: string;
  isChecked: boolean;
}

export interface CatBatReference{
  id:number;
  refText: string;
  isChecked: boolean;
}
export interface Tool{
  id:number;
  refText: string;
  isChecked: boolean;
}
export interface ApplicableToBusiness{
  id:number;
  refText: string;
  isChecked: boolean;
}
export interface ActivityFormingPartOfLat{
  id:number;
  refText: string;
  isChecked: boolean;
}
export interface CPReference{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface MIReference{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface BRReference{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface Remark{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface Responsible{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface Accountable{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface Consult{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
export interface Inform{
  id:number;
  refText: string;
  refLink: string;
  isChecked: boolean;
}
@Component({
  selector: 'app-activity-data-mapp',
  templateUrl: './activity-data-mapp.component.html',
  styleUrls: ['./activity-data-mapp.component.scss']
})
export class ActivityDataMappComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  dataSource1 : MatTableDataSource<ActivityDetails>;
  displayedColumns: string[] = ['CatBatReference','CPReference','MIReference','Tool','ApplicableToBusiness','Activity','Responsible', 'Accountable' , 'Consult', 'Inform','Remark','ActivityFormingPartOfLat','BusinessArea'];
  formInstance: FormGroup;
  businessUnitId: number;
  activityId: number;
  businessName:string;
  activityName:string;

  SrNumberList:SrNumber[]=[]
  sourceOfActivityLst: SourceOfActivity[]=[];
  catBatReferenceLst: CatBatReference[]=[];
  toolLst: Tool[]=[];
  applicableToBusinessLst: ApplicableToBusiness[]=[];
  activityFormingPartOfLatLst: ActivityFormingPartOfLat[]=[];
  CPReferenceLst: CPReference[]=[];
  MIReferenceLst:MIReference[]=[];
  BRReferenceLst: BRReference[]=[];
  remarkWithLinksLst: Remark[]=[];
  remarkWithPlainTextLst: Remark[]=[];
  responsibleWithLinksLst: Responsible[]=[];
  responsibleWithPlainTextLst: Responsible[]=[];
  responsibleWithDesignationLst: Responsible[]=[];
  accountableWithLinksLst: Accountable[]=[];
  accountableWithPlainTextLst: Accountable[]=[];
  accountableWithDesignationLst: Accountable[]=[];
  consultWithLinksLst: Consult[]=[];
  consultWithPlainTextLst: Consult[]=[];
  consultWithDesignationLst: Consult[]=[];
  informWithLinksLst: Inform[]=[];
  informWithPlainTextLst: Inform[]=[];
  informWithDesignationLst: Inform[]=[];
  
  DisplayText:string;
  selectedSrNumberOfActivityControl = new FormControl();
  selectedSourceOfActivityControl = new FormControl();
  selectedCatBatReferenceControl = new FormControl();
  selectedToolControl = new FormControl();
  selectedApplicableToBusinessControl = new FormControl();
  selectedActivityFormingPartControl = new FormControl();

  existingSrNumberOfActivity = [];
  existingSourceOfActivity = [];
  existingCatBatReference = [];
  existingTool = [];
  existingApplicableToBusiness = [];
  existingActivityFormingPart = [];

  @ViewChild('searchCPReference') searchCPReferenceTextBox: ElementRef;
  selectCPReferenceFormControl = new FormControl();
  searchCPReferenceTextboxControl = new FormControl();
  selectedCPReferenceValues = [];
  CPReferenceFilteredOptions: Observable<CPReference[]>= of(this.CPReferenceLst);
  selectedCPReferenceValue= new FormControl([]);

  @ViewChild('searchMIReference') searchMIReferenceTextBox: ElementRef;
  selectMIReferenceFormControl = new FormControl();
  searchMIReferenceTextboxControl = new FormControl();
  selectedMIReferenceValues = [];
  MIReferenceFilteredOptions: Observable<MIReference[]>= of(this.MIReferenceLst);
  selectedMIReferenceValue= new FormControl([]);

  @ViewChild('searchBRReference') searchBRReferenceTextBox: ElementRef;
  selectBRReferenceFormControl = new FormControl();
  searchBRReferenceTextboxControl = new FormControl();
  selectedBRReferenceValues = [];
  BRReferenceFilteredOptions: Observable<BRReference[]>= of(this.BRReferenceLst);
  selectedBRReferenceValue= new FormControl([]);

  @ViewChild('searchremarkWithLinks') searchremarkWithLinksTextBox: ElementRef;
  selectremarkWithLinksFormControl = new FormControl();
  searchremarkWithLinksTextboxControl = new FormControl();
  selectedremarkWithLinksValues = [];
  remarkWithLinksFilteredOptions: Observable<Remark[]>= of(this.remarkWithLinksLst);
  selectedremarkWithLinksValue= new FormControl([]);

  @ViewChild('searchremarkWithPlainText') searchremarkWithPlainTextTextBox: ElementRef;
  selectremarkWithPlainTextFormControl = new FormControl();
  searchremarkWithPlainTextTextboxControl = new FormControl();
  selectedremarkWithPlainTextValues = [];
  remarkWithPlainTextFilteredOptions: Observable<Remark[]>= of(this.remarkWithPlainTextLst);
  selectedremarkWithPlainTextValue= new FormControl([]);

  @ViewChild('searchresponsibleWithLinks') searchresponsibleWithLinksTextBox: ElementRef;
  selectresponsibleWithLinksFormControl = new FormControl();
  searchresponsibleWithLinksTextboxControl = new FormControl();
  selectedresponsibleWithLinksValues = [];
  responsibleWithLinksFilteredOptions: Observable<Responsible[]>= of(this.responsibleWithLinksLst);
  selectedresponsibleWithLinksValue= new FormControl([]);

  @ViewChild('searchresponsibleWithPlainText') searchresponsibleWithPlainTextTextBox: ElementRef;
  selectresponsibleWithPlainTextFormControl = new FormControl();
  searchresponsibleWithPlainTextTextboxControl = new FormControl();
  selectedresponsibleWithPlainTextValues = [];
  responsibleWithPlainTextFilteredOptions: Observable<Responsible[]>= of(this.responsibleWithPlainTextLst);
  selectedresponsibleWithPlainTextValue= new FormControl([]);

  @ViewChild('searchresponsibleWithDesignation') searchresponsibleWithDesignationTextBox: ElementRef;
  selectresponsibleWithDesignationFormControl = new FormControl();
  searchresponsibleWithDesignationTextboxControl = new FormControl();
  selectedresponsibleWithDesignationValues = [];
  responsibleWithDesignationFilteredOptions: Observable<Responsible[]>= of(this.responsibleWithDesignationLst);
  selectedresponsibleWithDesignationValue= new FormControl([]);

  @ViewChild('searchaccountableWithLinks') searchaccountableWithLinksTextBox: ElementRef;
  selectaccountableWithLinksFormControl = new FormControl();
  searchaccountableWithLinksTextboxControl = new FormControl();
  selectedaccountableWithLinksValues = [];
  accountableWithLinksFilteredOptions: Observable<Accountable[]>= of(this.accountableWithLinksLst);
  selectedaccountableWithLinksValue= new FormControl([]);

  @ViewChild('searchaccountableWithPlainText') searchaccountableWithPlainTextTextBox: ElementRef;
  selectaccountableWithPlainTextFormControl = new FormControl();
  searchaccountableWithPlainTextTextboxControl = new FormControl();
  selectedaccountableWithPlainTextValues = [];
  accountableWithPlainTextFilteredOptions: Observable<Accountable[]>= of(this.accountableWithPlainTextLst);
  selectedaccountableWithPlainTextValue= new FormControl([]);

  @ViewChild('searchaccountableWithDesignation') searchaccountableWithDesignationTextBox: ElementRef;
  selectaccountableWithDesignationFormControl = new FormControl();
  searchaccountableWithDesignationTextboxControl = new FormControl();
  selectedaccountableWithDesignationValues = [];
  accountableWithDesignationFilteredOptions: Observable<Accountable[]>= of(this.accountableWithDesignationLst);
  selectedaccountableWithDesignationValue= new FormControl([]);

  @ViewChild('searchconsultWithLinks') searchconsultWithLinksTextBox: ElementRef;
  selectconsultWithLinksFormControl = new FormControl();
  searchconsultWithLinksTextboxControl = new FormControl();
  selectedconsultWithLinksValues = [];
  consultWithLinksFilteredOptions: Observable<Consult[]>= of(this.consultWithLinksLst);
  selectedconsultWithLinksValue= new FormControl([]);

  @ViewChild('searchconsultWithPlainText') searchconsultWithPlainTextTextBox: ElementRef;
  selectconsultWithPlainTextFormControl = new FormControl();
  searchconsultWithPlainTextTextboxControl = new FormControl();
  selectedconsultWithPlainTextValues = [];
  consultWithPlainTextFilteredOptions: Observable<Consult[]>= of(this.consultWithPlainTextLst);
  selectedconsultWithPlainTextValue= new FormControl([]);

  @ViewChild('searchconsultWithDesignation') searchconsultWithDesignationTextBox: ElementRef;
  selectconsultWithDesignationFormControl = new FormControl();
  searchconsultWithDesignationTextboxControl = new FormControl();
  selectedconsultWithDesignationValues = [];
  consultWithDesignationFilteredOptions: Observable<Consult[]>= of(this.consultWithDesignationLst);
  selectedconsultWithDesignationValue= new FormControl([]);

  @ViewChild('searchinformWithLinks') searchinformWithLinksTextBox: ElementRef;
  selectinformWithLinksFormControl = new FormControl();
  searchinformWithLinksTextboxControl = new FormControl();
  selectedinformWithLinksValues = [];
  informWithLinksFilteredOptions: Observable<Inform[]>= of(this.informWithLinksLst);
  selectedinformWithLinksValue= new FormControl([]);

  @ViewChild('searchinformWithPlainText') searchinformWithPlainTextTextBox: ElementRef;
  selectinformWithPlainTextFormControl = new FormControl();
  searchinformWithPlainTextTextboxControl = new FormControl();
  selectedinformWithPlainTextValues = [];
  informWithPlainTextFilteredOptions: Observable<Inform[]>= of(this.informWithPlainTextLst);
  selectedinformWithPlainTextValue= new FormControl([]);

  @ViewChild('searchinformWithDesignation') searchinformWithDesignationTextBox: ElementRef;
  selectinformWithDesignationFormControl = new FormControl();
  searchinformWithDesignationTextboxControl = new FormControl();
  selectedinformWithDesignationValues = [];
  informWithDesignationFilteredOptions: Observable<Inform[]>= of(this.informWithDesignationLst);
  selectedinformWithDesignationValue= new FormControl([]);


  remarkText = new FormControl();
  remarkId= new FormControl();
/*   private route: ActivatedRoute, */

  constructor(private activityDataMapping: ActivityDataMappingService,private search: SearchService
    , @Inject(MAT_DIALOG_DATA) public data: any  ,public dialogRef: MatDialogRef<ActivityDataMappComponent>
  ,private historyServ: HistoryService) {


   
   }

   toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline'],
    ['ordered_list', 'bullet_list','link']
  ];
  topicEditor: Editor;
  remarksEditor: Editor;
 

  ngOnInit(): void {

    this.topicEditor = new Editor();
    this.remarksEditor = new Editor();

this.remarkText.setValue('')
this.remarkId.setValue('')
    console.log(this.data);
    this.businessUnitId=this.data.businessUnit;
    this.activityId=this.data.activityIDs;
    this.businessName=this.data.businessName;
    this.activityName=this.data.activityName;
     
 /*    this.route.params.subscribe(params => {
      let param1 = params['buid'];
      let param2 = params['id'];
      console.log('Received parameters:', param1, param2);
    }); */
   /*  this.businessUnitId=2;
    this.activityId=382; */
    this.loadGrid("0");
    this.getSrNumberForActivity();
    this.getSourceOfActivity();
    this.getcatBatReference();
    this.gettool();
    this.getapplicableToBusiness();
    this.getactivityFormingPartOfLat();
    this.getCPReference();
    this.getMIReference();
    this.getBRReference();
   // this.getremarkWithLinks();
    //this.getremarkWithPlainText();
    this.getremark()
    this.getresponsibleWithLinks();
    this.getresponsibleWithPlainText();
    this.getresponsibleWithDesignation();
    this.getaccountableWithLinks();
    this.getaccountableWithPlainText();
    this.getaccountableWithDesignation();
    this.getconsultWithLinks();
    this.getconsultWithPlainText();
    this.getconsultWithDesignation();
    this.getinformWithLinks();
    this.getinformWithPlainText();
    this.getinformWithDesignation();
  }
  loadGrid(MappingValues){
     
    var busIDs="0"
    var divIDs="0"
    var designationIDs="0"
    var activityIDs="0"
    var dataList="0"
    var Remark=this.remarkText.value
    var emailId = sessionStorage.getItem('LoginEmail');
    var srNo= "1";

    if(this.selectedSrNumberOfActivityControl.value!="" && this.selectedSrNumberOfActivityControl.value!=null)
       srNo= this.selectedSrNumberOfActivityControl.value.toString();

    if (MappingValues!=0)
    dataList=MappingValues.join(",");

    const myData = { busIDs: this.businessUnitId.toString(), 
      divIDs: divIDs, 
      designationIDs: designationIDs, 
      activityIDs: this.activityId.toString(),
      dataList:dataList,
      remark:Remark,
      email:emailId,
      srNo:srNo };

      this.activityDataMapping.getActivityDetailsForMapping(myData).subscribe((data1: any[])=>{
        this.dataSource1= new MatTableDataSource<ActivityDetails>(data1);
        this.dataSource1.paginator = this.paginator;
        this.dataSource1.sort = this.sort;
        if(dataList!="0")
        this.activityDataMapping.ShowSnackBar("Data Submitted Successfully",'red-snackbar'); 
      });

      

      this.businessName=this.data.businessName;
      this.activityName=this.data.activityName;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }

  }
  
  addHyperlink(Data: string) {
        
    const jsonObject = JSON.parse(Data);
    var retValText = ""; 

    var retVal = "";
    for(var key in jsonObject) {    
        retValText=jsonObject[key].Text.replace('; ', ';<br/>'); 
                  
    }
    
    return retValText;
  }

  getSrNumberForActivity() {
    
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'SrNumber',false,false).subscribe((data: any)=>{
      this.SrNumberList=data;
      this.SrNumberList= this.SrNumberList.filter(item=>item.refText !== null);
      if (this.SrNumberList && this.SrNumberList.length > 0) {
        this.SrNumberList.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingSrNumberOfActivity.indexOf(e) == -1) {
              this.existingSrNumberOfActivity.push(e.id);
            }
          }
        });
      }
      this.selectedSrNumberOfActivityControl.patchValue(this.existingSrNumberOfActivity[0]);
  })
}


  getSourceOfActivity() {
    
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'SourceOfActivity',false,false).subscribe((data: any)=>{
      this.sourceOfActivityLst=data;
      this.sourceOfActivityLst= this.sourceOfActivityLst.filter(item=>item.refText !== null);
      if (this.sourceOfActivityLst && this.sourceOfActivityLst.length > 0) {
        this.sourceOfActivityLst.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingSourceOfActivity.indexOf(e) == -1) {
              this.existingSourceOfActivity.push(e.id);
            }
          }
        });
      }
      this.selectedSourceOfActivityControl.patchValue(this.existingSourceOfActivity[0]);
  })
}

  getcatBatReference() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'CatBatReference',false,false).subscribe((data: any)=>{
      this.catBatReferenceLst=data;
      this.catBatReferenceLst= this.catBatReferenceLst.filter(item=>item.refText !== null);
      if (this.catBatReferenceLst && this.catBatReferenceLst.length > 0) {
        this.catBatReferenceLst.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingCatBatReference.indexOf(e) == -1) {
              this.existingCatBatReference.push(e.id);
            }
          }
        });
      }
      this.selectedCatBatReferenceControl.patchValue(this.existingCatBatReference[0]);
    })
  }
  gettool() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Tool',false,false).subscribe((data: any)=>{
      this.toolLst=data;
      this.toolLst= this.toolLst.filter(item=>item.refText !== null);
      if (this.toolLst && this.toolLst.length > 0) {
        this.toolLst.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingTool.indexOf(e) == -1) {
              this.existingTool.push(e.id);
            }
          }
        });
      }
      this.selectedToolControl.patchValue(this.existingTool[0]);
    })
  }
  getapplicableToBusiness() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'ApplicableToBusiness',false,false).subscribe((data: any)=>{
      this.applicableToBusinessLst=data;
      this.applicableToBusinessLst= this.applicableToBusinessLst.filter(item=>item.refText !== null);
      if (this.applicableToBusinessLst && this.applicableToBusinessLst.length > 0) {
        this.applicableToBusinessLst.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingApplicableToBusiness.indexOf(e) == -1) {
              this.existingApplicableToBusiness.push(e.id);
            }
          }
        });
      }
      this.selectedApplicableToBusinessControl.patchValue(this.existingApplicableToBusiness[0]);
    });
  }
  getactivityFormingPartOfLat() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'ActivityFormingPartOfLat',false,false).subscribe((data: any)=>{
      this.activityFormingPartOfLatLst=data;
      this.activityFormingPartOfLatLst= this.activityFormingPartOfLatLst.filter(item=>item.refText !== null);
      if (this.activityFormingPartOfLatLst && this.activityFormingPartOfLatLst.length > 0) {
        this.activityFormingPartOfLatLst.forEach((e) => {
          if(e.isChecked== true){
            if (this.existingActivityFormingPart.indexOf(e) == -1) {
              this.existingActivityFormingPart.push(e.id);
            }
          }
        });
      }
      this.selectedActivityFormingPartControl.patchValue(this.existingActivityFormingPart[0]);
    });
  }
  getCPReference() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'CPReference',true,false).subscribe((data: any)=>{
      this.CPReferenceLst=data,
      this.CPReferenceLst= this.CPReferenceLst.filter(item=>item.refText !== null);
      this.setExistingCPReferenceAsSelected();
      this.CPReferenceFilteredOptions = this.searchCPReferenceTextboxControl.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this.CPReferenceFilter(name))
    );
       () => console.log('Get all complete'); 
    });
  }
  getMIReference() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'MIReference',true,false).subscribe((data: any)=>{
      this.MIReferenceLst=data,
      this.MIReferenceLst= this.MIReferenceLst.filter(item=>item.refText !== null);
      this.setExistingMIReferenceAsSelected();
      this.MIReferenceFilteredOptions = this.searchMIReferenceTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.MIReferenceFilter(name))
      );
         () => console.log('Get all complete'); 
      });
  }
  getBRReference() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'BRReference',true,false).subscribe((data: any)=>{
      this.BRReferenceLst=data,
      this.BRReferenceLst= this.BRReferenceLst.filter(item=>item.refText !== null);
      this.setExistingBRReferenceAsSelected();
      this.BRReferenceFilteredOptions = this.searchBRReferenceTextboxControl.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this.BRReferenceFilter(name))
    );
       () => console.log('Get all complete'); 
    });
  }
  /* getremarkWithLinks() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Remark',true,false).subscribe((data: any)=>{
      this.remarkWithLinksLst=data,
      this.remarkWithLinksLst= this.remarkWithLinksLst.filter(item=>item.refText !== null);
      this.setExistingRemarkWithLinksAsSelected();
      this.remarkWithLinksFilteredOptions = this.searchremarkWithLinksTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.remarkWithLinksFilter(name))
      );
         () => console.log('Get all complete'); 
      });
  } */
  /* getremarkWithPlainText() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Remark',false,false).subscribe((data: any)=>{
      this.remarkWithPlainTextLst=data,
      this.remarkWithPlainTextLst= this.remarkWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingRemarkWithPlainTextAsSelected();
      this.remarkWithPlainTextFilteredOptions = this.searchremarkWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.remarkWithPlainTextFilter(name))
      );
         () => console.log('Get all complete'); 
      });
  } */
      getremark() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Remark',false,false).subscribe((data: any)=>{
  /*     this.remarkWithPlainTextLst=data,
      this.remarkWithPlainTextLst= this.remarkWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingRemarkWithPlainTextAsSelected();
      this.remarkWithPlainTextFilteredOptions = this.searchremarkWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.remarkWithPlainTextFilter(name))
      );
         () => console.log('Get all complete'); 
 */
          console.debug(data);
        // this.remarkWithPlainTextLst=data,
        /*  this.remarkText= data[0]['refText']; */
         this.remarkText.setValue(data[0]['refText'])
         this.remarkId.setValue(data[0]['id'])

      });
  }
  getresponsibleWithLinks() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Responsible',true,false).subscribe((data: any)=>{
      this.responsibleWithLinksLst=data,
      this.responsibleWithLinksLst= this.responsibleWithLinksLst.filter(item=>item.refText !== null);
      this.setExistingResponsibleWithLinksAsSelected();
      this.responsibleWithLinksFilteredOptions = this.searchresponsibleWithLinksTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.responsibleWithLinksFilter(name))
      );
         () => console.log('Get all complete'); 
      });
  }
  getresponsibleWithPlainText() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Responsible',false,false).subscribe((data: any)=>{
      this.responsibleWithPlainTextLst=data,
      this.responsibleWithPlainTextLst= this.responsibleWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingResponsibleWithPlainTextAsSelected();
      this.responsibleWithPlainTextFilteredOptions = this.searchresponsibleWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.responsibleWithPlainTextFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }
  getresponsibleWithDesignation() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Responsible',false,true).subscribe((data: any)=>{
      this.responsibleWithDesignationLst=data,
      this.responsibleWithDesignationLst= this.responsibleWithDesignationLst.filter(item=>item.refText !== null);
      this.setExistingResponsibleWithDesignationAsSelected();
      this.responsibleWithDesignationFilteredOptions = this.searchresponsibleWithDesignationTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.responsibleWithDesignationFilter(name))
      );
         () => console.log('Get all complete'); 
     
    });
  }
  getaccountableWithLinks() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Accountable',true,false).subscribe((data: any)=>{
      this.accountableWithLinksLst=data,
      this.accountableWithLinksLst= this.accountableWithLinksLst.filter(item=>item.refText !== null);
      this.setExistingAccountableWithLinksAsSelected();
      this.accountableWithLinksFilteredOptions = this.searchaccountableWithLinksTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.accountableWithLinksFilter(name))
      );
         () => console.log('Get all complete');

    });
  }
  getaccountableWithPlainText() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Accountable',false,false).subscribe((data: any)=>{
      this.accountableWithPlainTextLst=data,
      this.accountableWithPlainTextLst= this.accountableWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingAccountableWithPlainTextAsSelected();
      this.accountableWithPlainTextFilteredOptions = this.searchaccountableWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.accountableWithPlainTextFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }
  getaccountableWithDesignation() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Accountable',false,true).subscribe((data: any)=>{
      this.accountableWithDesignationLst=data,
      this.accountableWithDesignationLst= this.accountableWithDesignationLst.filter(item=>item.refText !== null);
      this.setExistingAccountableWithDesignationAsSelected();
      this.accountableWithDesignationFilteredOptions = this.searchaccountableWithDesignationTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.accountableWithDesignationFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }
  getconsultWithLinks() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Consult',true,false).subscribe((data: any)=>{
      this.consultWithLinksLst=data,
      this.consultWithLinksLst= this.consultWithLinksLst.filter(item=>item.refText !== null);
      this.setExistingConsultWithLinksAsSelected();
      this.consultWithLinksFilteredOptions = this.searchconsultWithLinksTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.consultWithLinksFilter(name))
      );
         () => console.log('Get all complete');

    });
  }
  getconsultWithPlainText() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Consult',false,false).subscribe((data: any)=>{
      this.consultWithPlainTextLst=data,
      this.consultWithPlainTextLst= this.consultWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingConsultWithPlainTextAsSelected();
      this.consultWithPlainTextFilteredOptions = this.searchconsultWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.consultWithPlainTextFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }
  getconsultWithDesignation() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Consult',false,true).subscribe((data: any)=>{
      this.consultWithDesignationLst=data,
      this.consultWithDesignationLst= this.consultWithDesignationLst.filter(item=>item.refText !== null);
      this.setExistingConsultWithDesignationAsSelected();
      this.consultWithDesignationFilteredOptions = this.searchconsultWithDesignationTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.consultWithDesignationFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }
  getinformWithLinks() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Inform',true,false).subscribe((data: any)=>{
      this.informWithLinksLst=data,
      this.informWithLinksLst= this.informWithLinksLst.filter(item=>item.refText !== null);
      this.setExistingInformWithLinksAsSelected();
      this.informWithLinksFilteredOptions = this.searchinformWithLinksTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.informWithLinksFilter(name))
      );
         () => console.log('Get all complete');

    });
  }
  getinformWithPlainText() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Inform',false,false).subscribe((data: any)=>{
      this.informWithPlainTextLst=data,
      this.informWithPlainTextLst= this.informWithPlainTextLst.filter(item=>item.refText !== null);
      this.setExistingInformWithPlainTextAsSelected();
      this.informWithPlainTextFilteredOptions = this.searchinformWithPlainTextTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.informWithPlainTextFilter(name))
      );
         () => console.log('Get all complete');
    });
  }
  getinformWithDesignation() {
    this.activityDataMapping.getCategoryWithActivityMasterData(this.businessUnitId, this.activityId,'Inform',false,true).subscribe((data: any)=>{
      this.informWithDesignationLst=data,
      this.informWithDesignationLst= this.informWithDesignationLst.filter(item=>item.refText !== null);
      this.setExistingInformWithDesignationAsSelected();
      this.informWithDesignationFilteredOptions = this.searchinformWithDesignationTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.informWithDesignationFilter(name))
      );
         () => console.log('Get all complete'); 

    });
  }

  private CPReferenceFilter(name: string)   {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setCPReferenceSelectedValues();
    this.selectCPReferenceFormControl.patchValue(this.selectedCPReferenceValues);
    let filteredList = this.CPReferenceLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  CPReferenceSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedCPReferenceValues.indexOf(event.source.value.refText);
      this.selectedCPReferenceValues.splice(index, 1)
    }
  }
  openedCPReferenceChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchCPReferenceTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchCPReferenceTextBox.nativeElement.focus();
    }
  }

  clearCPReferenceSearch(event) {
    event.stopPropagation();
    this.searchCPReferenceTextboxControl.patchValue('');
  }

  setCPReferenceSelectedValues() {
    if (this.selectCPReferenceFormControl.value && this.selectCPReferenceFormControl.value.length > 0) {
      this.selectCPReferenceFormControl.value.forEach((e) => {
        if (this.selectedCPReferenceValues.indexOf(e) == -1) {
          this.selectedCPReferenceValues.push(e);
        }
      });
    }
  }
  setExistingCPReferenceAsSelected(){
    if (this.CPReferenceLst && this.CPReferenceLst.length > 0) {
      this.CPReferenceLst.forEach((e) => {
        if(e.isChecked == true){
          if (this.selectedCPReferenceValues.indexOf(e) == -1) {
            this.selectedCPReferenceValues.push(e);
          }
        }  
      });
    }
  }
  getSelectedCPReference(){
    const selectedOptions= this.selectCPReferenceFormControl.value;
    if(!selectedOptions || selectedOptions.length ==0){
      return '';
    }
    return selectedOptions.map(option=>option.refText).join(', ');
  }
  
  private MIReferenceFilter(name: string)   {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setMIReferenceSelectedValues();
    this.selectMIReferenceFormControl.patchValue(this.selectedMIReferenceValues);
    let filteredList = this.MIReferenceLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  MIReferenceSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedMIReferenceValues.indexOf(event.source.value.refText);
      this.selectedMIReferenceValues.splice(index, 1)
    }
  }
  openedMIReferenceChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchMIReferenceTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchMIReferenceTextBox.nativeElement.focus();
    }
  }

  clearMIReferenceSearch(event) {
    event.stopPropagation();
    this.searchMIReferenceTextboxControl.patchValue('');
  }

  setMIReferenceSelectedValues() {
    if (this.selectMIReferenceFormControl.value && this.selectMIReferenceFormControl.value.length > 0) {
      this.selectMIReferenceFormControl.value.forEach((e) => {
        if (this.selectedMIReferenceValues.indexOf(e) == -1) {
          this.selectedMIReferenceValues.push(e);
        }
      });
    }
  }
  setExistingMIReferenceAsSelected(){
    if (this.MIReferenceLst && this.MIReferenceLst.length > 0) {
      this.MIReferenceLst.forEach((e) => {
        if(e.isChecked == true){
          if (this.selectedMIReferenceValues.indexOf(e) == -1) {
            this.selectedMIReferenceValues.push(e);
          }
        }  
      });
    }
  }
  getSelectedMIReference(){
    const selectedOptions= this.selectMIReferenceFormControl.value;
    if(!selectedOptions || selectedOptions.length ==0){
      return '';
    }
    return selectedOptions.map(option=>option.refText).join(', ');
  }

  private BRReferenceFilter(name: string)   {
    const filterValue = name.toLowerCase();
    
    // Set selected values to retain the selected checkbox state 
    this.setBRReferenceSelectedValues();
    this.selectBRReferenceFormControl.patchValue(this.selectedBRReferenceValues);
    let filteredList = this.BRReferenceLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  BRReferenceSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedBRReferenceValues.indexOf(event.source.value.refText);
      this.selectedBRReferenceValues.splice(index, 1)
    }
  }
  openedBRReferenceChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchBRReferenceTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchBRReferenceTextBox.nativeElement.focus();
    }
  }

  clearBRReferenceSearch(event) {
    event.stopPropagation();
    this.searchBRReferenceTextboxControl.patchValue('');
  }

  setBRReferenceSelectedValues() {
    if (this.selectBRReferenceFormControl.value && this.selectBRReferenceFormControl.value.length > 0) {
      this.selectBRReferenceFormControl.value.forEach((e) => {
        if (this.selectedBRReferenceValues.indexOf(e) == -1) {
          this.selectedBRReferenceValues.push(e);
        }
      });
    }
  }
  setExistingBRReferenceAsSelected(){
    if (this.BRReferenceLst && this.BRReferenceLst.length > 0) {
      this.BRReferenceLst.forEach((e) => {
        if(e.isChecked == true){
          if (this.selectedBRReferenceValues.indexOf(e) == -1) {
            this.selectedBRReferenceValues.push(e);
          }
        }  
      });
    }
  }
  getSelectedBRReference(){
    const selectedOptions= this.selectBRReferenceFormControl.value;
    if(!selectedOptions || selectedOptions.length ==0){
      return '';
    }
    return selectedOptions.map(option=>option.refText).join(', ');
  }
  private remarkWithLinksFilter(name: string)   {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setremarkWithLinksSelectedValues();
    this.selectremarkWithLinksFormControl.patchValue(this.selectedremarkWithLinksValues);
    let filteredList = this.remarkWithLinksLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
    return filteredList;
  }

  remarkWithLinksSelectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedremarkWithLinksValues.indexOf(event.source.value.refText);
      this.selectedremarkWithLinksValues.splice(index, 1)
    }
  }
  openedremarkWithLinksChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchremarkWithLinksTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchremarkWithLinksTextBox.nativeElement.focus();
    }
  }

  clearremarkWithLinksSearch(event) {
    event.stopPropagation();
    this.searchremarkWithLinksTextboxControl.patchValue('');
  }

  setremarkWithLinksSelectedValues() {
    if (this.selectremarkWithLinksFormControl.value && this.selectremarkWithLinksFormControl.value.length > 0) {
      this.selectremarkWithLinksFormControl.value.forEach((e) => {
        if (this.selectedremarkWithLinksValues.indexOf(e) == -1) {
          this.selectedremarkWithLinksValues.push(e);
        }
      });
    }
  }
  setExistingRemarkWithLinksAsSelected(){
    if (this.remarkWithLinksLst && this.remarkWithLinksLst.length > 0) {
      this.remarkWithLinksLst.forEach((e) => {
        if(e.isChecked == true){
          if (this.selectedremarkWithLinksValues.indexOf(e) == -1) {
            this.selectedremarkWithLinksValues.push(e);
          }
        }  
      });
    }

  }
  getSelectedremarkWithLinks(){
    const selectedOptions= this.selectremarkWithLinksFormControl.value;
    if(!selectedOptions || selectedOptions.length ==0){
      return '';
    }
    return selectedOptions.map(option=>option.refText).join(', ');
  }

  
private remarkWithPlainTextFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setremarkWithPlainTextSelectedValues();
  this.selectremarkWithPlainTextFormControl.patchValue(this.selectedremarkWithPlainTextValues);
  let filteredList = this.remarkWithPlainTextLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

remarkWithPlainTextSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedremarkWithPlainTextValues.indexOf(event.source.value.refText);
    this.selectedremarkWithPlainTextValues.splice(index, 1)
  }
}
openedremarkWithPlainTextChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchremarkWithPlainTextTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchremarkWithPlainTextTextBox.nativeElement.focus();
  }
}

clearremarkWithPlainTextSearch(event) {
  event.stopPropagation(); this.searchremarkWithPlainTextTextboxControl.patchValue('');
}

setremarkWithPlainTextSelectedValues() {
  if (this.selectremarkWithPlainTextFormControl.value && this.selectremarkWithPlainTextFormControl.value.length > 0) {
    this.selectremarkWithPlainTextFormControl.value.forEach((e) => {
      if (this.selectedremarkWithPlainTextValues.indexOf(e) == -1) { this.selectedremarkWithPlainTextValues.push(e); }
    });
  }
}
setExistingRemarkWithPlainTextAsSelected(){
  if (this.remarkWithPlainTextLst && this.remarkWithPlainTextLst.length > 0) {
    this.remarkWithPlainTextLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedremarkWithPlainTextValues.indexOf(e) == -1) {
          this.selectedremarkWithPlainTextValues.push(e);
        }
      }  
    });
  }

}
getSelectedremarkWithPlainText(){
  const selectedOptions= this.selectremarkWithPlainTextFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private responsibleWithLinksFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setresponsibleWithLinksSelectedValues();
  this.selectresponsibleWithLinksFormControl.patchValue(this.selectedresponsibleWithLinksValues);
  let filteredList = this.responsibleWithLinksLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

responsibleWithLinksSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedresponsibleWithLinksValues.indexOf(event.source.value.refText);
    this.selectedresponsibleWithLinksValues.splice(index, 1)
  }
}
openedresponsibleWithLinksChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchresponsibleWithLinksTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchresponsibleWithLinksTextBox.nativeElement.focus();
  }
}

clearresponsibleWithLinksSearch(event) {
  event.stopPropagation(); this.searchresponsibleWithLinksTextboxControl.patchValue('');
}

setresponsibleWithLinksSelectedValues() {
  if (this.selectresponsibleWithLinksFormControl.value && this.selectresponsibleWithLinksFormControl.value.length > 0) {
    this.selectresponsibleWithLinksFormControl.value.forEach((e) => {
      if (this.selectedresponsibleWithLinksValues.indexOf(e) == -1) { this.selectedresponsibleWithLinksValues.push(e); }
    });
  }
}
setExistingResponsibleWithLinksAsSelected(){
  if (this.responsibleWithLinksLst && this.responsibleWithLinksLst.length > 0) {
    this.responsibleWithLinksLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedresponsibleWithLinksValues.indexOf(e) == -1) {
          this.selectedresponsibleWithLinksValues.push(e);
        }
      }  
    });
  }

}
getSelectedresponsibleWithLinks(){
  const selectedOptions= this.selectresponsibleWithLinksFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}

private responsibleWithPlainTextFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setresponsibleWithPlainTextSelectedValues();
  this.selectresponsibleWithPlainTextFormControl.patchValue(this.selectedresponsibleWithPlainTextValues);
  let filteredList = this.responsibleWithPlainTextLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

responsibleWithPlainTextSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedresponsibleWithPlainTextValues.indexOf(event.source.value.refText);
    this.selectedresponsibleWithPlainTextValues.splice(index, 1)
  }
}
openedresponsibleWithPlainTextChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchresponsibleWithPlainTextTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchresponsibleWithPlainTextTextBox.nativeElement.focus();
  }
}

clearresponsibleWithPlainTextSearch(event) {
  event.stopPropagation(); this.searchresponsibleWithPlainTextTextboxControl.patchValue('');
}

setresponsibleWithPlainTextSelectedValues() {
  if (this.selectresponsibleWithPlainTextFormControl.value && this.selectresponsibleWithPlainTextFormControl.value.length > 0) {
    this.selectresponsibleWithPlainTextFormControl.value.forEach((e) => {
      if (this.selectedresponsibleWithPlainTextValues.indexOf(e) == -1) { this.selectedresponsibleWithPlainTextValues.push(e); }
    });
  }
}
setExistingResponsibleWithPlainTextAsSelected(){
  if (this.responsibleWithPlainTextLst && this.responsibleWithPlainTextLst.length > 0) {
    this.responsibleWithPlainTextLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedresponsibleWithPlainTextValues.indexOf(e) == -1) {
          this.selectedresponsibleWithPlainTextValues.push(e);
        }
      }  
    });
  }

}
getSelectedresponsibleWithPlainText(){
  const selectedOptions= this.selectresponsibleWithPlainTextFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private responsibleWithDesignationFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setresponsibleWithDesignationSelectedValues();
  this.selectresponsibleWithDesignationFormControl.patchValue(this.selectedresponsibleWithDesignationValues);
  let filteredList = this.responsibleWithDesignationLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

responsibleWithDesignationSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedresponsibleWithDesignationValues.indexOf(event.source.value.refText);
    this.selectedresponsibleWithDesignationValues.splice(index, 1)
  }
}
openedresponsibleWithDesignationChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchresponsibleWithDesignationTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchresponsibleWithDesignationTextBox.nativeElement.focus();
  }
}

clearresponsibleWithDesignationSearch(event) {
  event.stopPropagation(); this.searchresponsibleWithDesignationTextboxControl.patchValue('');
}

setresponsibleWithDesignationSelectedValues() {
  if (this.selectresponsibleWithDesignationFormControl.value && this.selectresponsibleWithDesignationFormControl.value.length > 0) {
    this.selectresponsibleWithDesignationFormControl.value.forEach((e) => {
      if (this.selectedresponsibleWithDesignationValues.indexOf(e) == -1) { this.selectedresponsibleWithDesignationValues.push(e); }
    });
  }
}

setExistingResponsibleWithDesignationAsSelected(){
  if (this.responsibleWithDesignationLst && this.responsibleWithDesignationLst.length > 0) {
    this.responsibleWithDesignationLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedresponsibleWithDesignationValues.indexOf(e) == -1) {
          this.selectedresponsibleWithDesignationValues.push(e);
        }
      }  
    });
  }

}
getSelectedresponsibleWithDesignation(){
  const selectedOptions= this.selectresponsibleWithDesignationFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private accountableWithLinksFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setaccountableWithLinksSelectedValues();
  this.selectaccountableWithLinksFormControl.patchValue(this.selectedaccountableWithLinksValues);
  let filteredList = this.accountableWithLinksLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

accountableWithLinksSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedaccountableWithLinksValues.indexOf(event.source.value.refText);
    this.selectedaccountableWithLinksValues.splice(index, 1)
  }
}
openedaccountableWithLinksChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchaccountableWithLinksTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchaccountableWithLinksTextBox.nativeElement.focus();
  }
}

clearaccountableWithLinksSearch(event) {
  event.stopPropagation(); this.searchaccountableWithLinksTextboxControl.patchValue('');
}

setaccountableWithLinksSelectedValues() {
  if (this.selectaccountableWithLinksFormControl.value && this.selectaccountableWithLinksFormControl.value.length > 0) {
    this.selectaccountableWithLinksFormControl.value.forEach((e) => {
      if (this.selectedaccountableWithLinksValues.indexOf(e) == -1) { this.selectedaccountableWithLinksValues.push(e); }
    });
  }
}
setExistingAccountableWithLinksAsSelected(){
  if (this.accountableWithLinksLst && this.accountableWithLinksLst.length > 0) {
    this.accountableWithLinksLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedaccountableWithLinksValues.indexOf(e) == -1) {
          this.selectedaccountableWithLinksValues.push(e);
        }
      }  
    });
  }

}

getSelectedaccountableWithLinks(){
  const selectedOptions= this.selectaccountableWithLinksFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}

private accountableWithPlainTextFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setaccountableWithPlainTextSelectedValues();
  this.selectaccountableWithPlainTextFormControl.patchValue(this.selectedaccountableWithPlainTextValues);
  let filteredList = this.accountableWithPlainTextLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

accountableWithPlainTextSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedaccountableWithPlainTextValues.indexOf(event.source.value.refText);
    this.selectedaccountableWithPlainTextValues.splice(index, 1)
  }
}
openedaccountableWithPlainTextChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchaccountableWithPlainTextTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchaccountableWithPlainTextTextBox.nativeElement.focus();
  }
}

clearaccountableWithPlainTextSearch(event) {
  event.stopPropagation(); this.searchaccountableWithPlainTextTextboxControl.patchValue('');
}

setaccountableWithPlainTextSelectedValues() {
  if (this.selectaccountableWithPlainTextFormControl.value && this.selectaccountableWithPlainTextFormControl.value.length > 0) {
    this.selectaccountableWithPlainTextFormControl.value.forEach((e) => {
      if (this.selectedaccountableWithPlainTextValues.indexOf(e) == -1) { this.selectedaccountableWithPlainTextValues.push(e); }
    });
  }
}

setExistingAccountableWithPlainTextAsSelected(){
  if (this.accountableWithPlainTextLst && this.accountableWithPlainTextLst.length > 0) {
    this.accountableWithPlainTextLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedaccountableWithPlainTextValues.indexOf(e) == -1) {
          this.selectedaccountableWithPlainTextValues.push(e);
        }
      }  
    });
  }

}

getSelectedaccountableWithPlainText(){
  const selectedOptions= this.selectaccountableWithPlainTextFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private accountableWithDesignationFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setaccountableWithDesignationSelectedValues();
  this.selectaccountableWithDesignationFormControl.patchValue(this.selectedaccountableWithDesignationValues);
  let filteredList = this.accountableWithDesignationLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

accountableWithDesignationSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedaccountableWithDesignationValues.indexOf(event.source.value.refText);
    this.selectedaccountableWithDesignationValues.splice(index, 1)
  }
}
openedaccountableWithDesignationChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchaccountableWithDesignationTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchaccountableWithDesignationTextBox.nativeElement.focus();
  }
}

clearaccountableWithDesignationSearch(event) {
  event.stopPropagation(); this.searchaccountableWithDesignationTextboxControl.patchValue('');
}

setaccountableWithDesignationSelectedValues() {
  if (this.selectaccountableWithDesignationFormControl.value && this.selectaccountableWithDesignationFormControl.value.length > 0) {
    this.selectaccountableWithDesignationFormControl.value.forEach((e) => {
      if (this.selectedaccountableWithDesignationValues.indexOf(e) == -1) { this.selectedaccountableWithDesignationValues.push(e); }
    });
  }
}
setExistingAccountableWithDesignationAsSelected(){
  if (this.accountableWithDesignationLst && this.accountableWithDesignationLst.length > 0) {
    this.accountableWithDesignationLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedaccountableWithDesignationValues.indexOf(e) == -1) {
          this.selectedaccountableWithDesignationValues.push(e);
        }
      }  
    });
  }

}
getSelectedaccountableWithDesignation(){
  const selectedOptions= this.selectaccountableWithDesignationFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private consultWithLinksFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setconsultWithLinksSelectedValues();
  this.selectconsultWithLinksFormControl.patchValue(this.selectedconsultWithLinksValues);
  let filteredList = this.consultWithLinksLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}
consultWithLinksSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedconsultWithLinksValues.indexOf(event.source.value.refText);
    this.selectedconsultWithLinksValues.splice(index, 1)
  }
}
openedconsultWithLinksChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchconsultWithLinksTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchconsultWithLinksTextBox.nativeElement.focus();
  }
}

clearconsultWithLinksSearch(event) {
  event.stopPropagation(); this.searchconsultWithLinksTextboxControl.patchValue('');
}

setconsultWithLinksSelectedValues() {
  if (this.selectconsultWithLinksFormControl.value && this.selectconsultWithLinksFormControl.value.length > 0) {
    this.selectconsultWithLinksFormControl.value.forEach((e) => {
      if (this.selectedconsultWithLinksValues.indexOf(e) == -1) { this.selectedconsultWithLinksValues.push(e); }
    });
  }
}
setExistingConsultWithLinksAsSelected(){
  if (this.consultWithLinksLst && this.consultWithLinksLst.length > 0) {
    this.consultWithLinksLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedconsultWithLinksValues.indexOf(e) == -1) {
          this.selectedconsultWithLinksValues.push(e);
        }
      }  
    });
  }

}

getSelectedconsultWithLinks(){
  const selectedOptions= this.selectconsultWithLinksFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}

private consultWithPlainTextFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setconsultWithPlainTextSelectedValues();
  this.selectconsultWithPlainTextFormControl.patchValue(this.selectedconsultWithPlainTextValues);
  let filteredList = this.consultWithPlainTextLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

consultWithPlainTextSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedconsultWithPlainTextValues.indexOf(event.source.value.refText);
    this.selectedconsultWithPlainTextValues.splice(index, 1)
  }
}
openedconsultWithPlainTextChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchconsultWithPlainTextTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchconsultWithPlainTextTextBox.nativeElement.focus();
  }
}

clearconsultWithPlainTextSearch(event) {
  event.stopPropagation(); this.searchconsultWithPlainTextTextboxControl.patchValue('');
}

setconsultWithPlainTextSelectedValues() {
  if (this.selectconsultWithPlainTextFormControl.value && this.selectconsultWithPlainTextFormControl.value.length > 0) {
    this.selectconsultWithPlainTextFormControl.value.forEach((e) => {
      if (this.selectedconsultWithPlainTextValues.indexOf(e) == -1) { this.selectedconsultWithPlainTextValues.push(e); }
    });
  }
}
setExistingConsultWithPlainTextAsSelected(){
  if (this.consultWithPlainTextLst && this.consultWithPlainTextLst.length > 0) {
    this.consultWithPlainTextLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedconsultWithPlainTextValues.indexOf(e) == -1) {
          this.selectedconsultWithPlainTextValues.push(e);
        }
      }  
    });
  }

}

getSelectedconsultWithPlainText(){
  const selectedOptions= this.selectconsultWithPlainTextFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private consultWithDesignationFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setconsultWithDesignationSelectedValues();
  this.selectconsultWithDesignationFormControl.patchValue(this.selectedconsultWithDesignationValues);
  let filteredList = this.consultWithDesignationLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

consultWithDesignationSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedconsultWithDesignationValues.indexOf(event.source.value.refText);
    this.selectedconsultWithDesignationValues.splice(index, 1)
  }
}
openedconsultWithDesignationChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchconsultWithDesignationTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchconsultWithDesignationTextBox.nativeElement.focus();
  }
}

clearconsultWithDesignationSearch(event) {
  event.stopPropagation(); this.searchconsultWithDesignationTextboxControl.patchValue('');
}

setconsultWithDesignationSelectedValues() {
  if (this.selectconsultWithDesignationFormControl.value && this.selectconsultWithDesignationFormControl.value.length > 0) {
    this.selectconsultWithDesignationFormControl.value.forEach((e) => {
      if (this.selectedconsultWithDesignationValues.indexOf(e) == -1) { this.selectedconsultWithDesignationValues.push(e); }
    });
  }
}
setExistingConsultWithDesignationAsSelected(){
  if (this.consultWithDesignationLst && this.consultWithDesignationLst.length > 0) {
    this.consultWithDesignationLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedconsultWithDesignationValues.indexOf(e) == -1) {
          this.selectedconsultWithDesignationValues.push(e);
        }
      }  
    });
  }

}
getSelectedconsultWithDesignation(){
  const selectedOptions= this.selectconsultWithDesignationFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private informWithLinksFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setinformWithLinksSelectedValues();
  this.selectinformWithLinksFormControl.patchValue(this.selectedinformWithLinksValues);
  let filteredList = this.informWithLinksLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}
informWithLinksSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedinformWithLinksValues.indexOf(event.source.value.refText);
    this.selectedinformWithLinksValues.splice(index, 1)
  }
}
openedinformWithLinksChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchinformWithLinksTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchinformWithLinksTextBox.nativeElement.focus();
  }
}

clearinformWithLinksSearch(event) {
  event.stopPropagation(); this.searchinformWithLinksTextboxControl.patchValue('');
}

setinformWithLinksSelectedValues() {
  if (this.selectinformWithLinksFormControl.value && this.selectinformWithLinksFormControl.value.length > 0) {
    this.selectinformWithLinksFormControl.value.forEach((e) => {
      if (this.selectedinformWithLinksValues.indexOf(e) == -1) { this.selectedinformWithLinksValues.push(e); }
    });
  }
}
setExistingInformWithLinksAsSelected(){
  if (this.informWithLinksLst && this.informWithLinksLst.length > 0) {
    this.informWithLinksLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedinformWithLinksValues.indexOf(e) == -1) {
          this.selectedinformWithLinksValues.push(e);
        }
      }  
    });
  }

}

getSelectedinformWithLinks(){
  const selectedOptions= this.selectinformWithLinksFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}

private informWithPlainTextFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setinformWithPlainTextSelectedValues();
  this.selectinformWithPlainTextFormControl.patchValue(this.selectedinformWithPlainTextValues);
  let filteredList = this.informWithPlainTextLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

informWithPlainTextSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedinformWithPlainTextValues.indexOf(event.source.value.refText);
    this.selectedinformWithPlainTextValues.splice(index, 1)
  }
}
openedinformWithPlainTextChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchinformWithPlainTextTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchinformWithPlainTextTextBox.nativeElement.focus();
  }
}

clearinformWithPlainTextSearch(event) {
  event.stopPropagation(); this.searchinformWithPlainTextTextboxControl.patchValue('');
}

setinformWithPlainTextSelectedValues() {
  if (this.selectinformWithPlainTextFormControl.value && this.selectinformWithPlainTextFormControl.value.length > 0) {
    this.selectinformWithPlainTextFormControl.value.forEach((e) => {
      if (this.selectedinformWithPlainTextValues.indexOf(e) == -1) { this.selectedinformWithPlainTextValues.push(e); }
    });
  }
}
setExistingInformWithPlainTextAsSelected(){
  if (this.informWithPlainTextLst && this.informWithPlainTextLst.length > 0) {
    this.informWithPlainTextLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedinformWithPlainTextValues.indexOf(e) == -1) {
          this.selectedinformWithPlainTextValues.push(e);
        }
      }  
    });
  }

}

getSelectedinformWithPlainText(){
  const selectedOptions= this.selectinformWithPlainTextFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
private informWithDesignationFilter(name: string)   {
  const filterValue = name.toLowerCase();
  // Set selected values to retain the selected checkbox state 
  this.setinformWithDesignationSelectedValues();
  this.selectinformWithDesignationFormControl.patchValue(this.selectedinformWithDesignationValues);
  let filteredList = this.informWithDesignationLst.filter(option => option.refText.toLowerCase().includes(filterValue.toLowerCase()));
  return filteredList;
}

informWithDesignationSelectionChange(event) {
  if (event.isUserInput && event.source.selected == false) {
    let index = this.selectedinformWithDesignationValues.indexOf(event.source.value.refText);
    this.selectedinformWithDesignationValues.splice(index, 1)
  }
}
openedinformWithDesignationChange(e) {
  // Set search textbox value as empty while opening selectbox 
  this.searchinformWithDesignationTextboxControl.patchValue('');
  // Focus to search textbox while clicking on selectbox
  if (e == true) { this.searchinformWithDesignationTextBox.nativeElement.focus();
  }
}

clearinformWithDesignationSearch(event) {
  event.stopPropagation(); this.searchinformWithDesignationTextboxControl.patchValue('');
}

setinformWithDesignationSelectedValues() {
  if (this.selectinformWithDesignationFormControl.value && this.selectinformWithDesignationFormControl.value.length > 0) {
    this.selectinformWithDesignationFormControl.value.forEach((e) => {
      if (this.selectedinformWithDesignationValues.indexOf(e) == -1) { this.selectedinformWithDesignationValues.push(e); }
    });
  }
}
setExistingInformWithDesignationAsSelected(){
  if (this.informWithDesignationLst && this.informWithDesignationLst.length > 0) {
    this.informWithDesignationLst.forEach((e) => {
      if(e.isChecked == true){
        if (this.selectedinformWithDesignationValues.indexOf(e) == -1) {
          this.selectedinformWithDesignationValues.push(e);
        }
      }  
    });
  }

}
getSelectedinformWithDesignation(){
  const selectedOptions= this.selectinformWithDesignationFormControl.value;
  if(!selectedOptions || selectedOptions.length ==0){return '';}
  return selectedOptions.map(option=>option.refText).join(', ');
}
close()
{
  this.dialogRef.close();
}
resetForm(){
  if (this.sourceOfActivityLst && this.sourceOfActivityLst.length > 0) {
    this.sourceOfActivityLst.forEach((e) => {
      if(e.isChecked== true){
        if (this.existingSourceOfActivity.indexOf(e) == -1) {
          this.existingSourceOfActivity.push(e.id);
        }
      }
    });
  }
  this.selectedSourceOfActivityControl.patchValue(this.existingSourceOfActivity[0]);
}
Submit(){
  if(this.selectedSrNumberOfActivityControl.value == undefined){
    this.activityDataMapping.ShowSnackBar("Please Select Sr Number for Activity",'rd-snackbar');
    return;
  }

  if(this.selectedSourceOfActivityControl.value == undefined){
    this.activityDataMapping.ShowSnackBar("Please Select Source Of Activity",'rd-snackbar');
    return;
  }
  if(this.selectedCatBatReferenceControl.value ==undefined){
    this.activityDataMapping.ShowSnackBar("Please Select CAT/BAT Reference",'rd-snackbar');
    return;
  }
  if(this.selectedToolControl.value ==undefined){
    this.activityDataMapping.ShowSnackBar("Please Select Tool / System / Portal",'rd-snackbar');
    return;
  }
  if(this.selectedApplicableToBusinessControl.value ==undefined){
    this.activityDataMapping.ShowSnackBar("Please Select Applicable to business",'rd-snackbar');
    return;
  }
  if(this.selectedActivityFormingPartControl.value ==undefined){
    this.activityDataMapping.ShowSnackBar("Please Select Activity forming part of LAT?",'rd-snackbar');
    return;
  }
  if(this.selectCPReferenceFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select CP Reference",'rd-snackbar');
    return;
  }
  if(this.selectMIReferenceFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select MI Reference",'rd-snackbar');
    return;
  }
  if(this.selectBRReferenceFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select BR Reference",'rd-snackbar');
    return;
  }
  if( (this.remarkText.value=="<p></p>" || this.remarkText.value.trim()=="") ){
    this.activityDataMapping.ShowSnackBar("Please enter valid Remark",'red-snackbar');
      return;
  }


/*   if(this.selectremarkWithLinksFormControl.value =="" && this.selectremarkWithPlainTextFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select Either Remark(Links) or Remarks(PlainText)",'rd-snackbar');
    return;
  } */
  if(this.selectresponsibleWithLinksFormControl.value =="" && this.selectresponsibleWithPlainTextFormControl.value =="" && this.selectresponsibleWithDesignationFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select Either Responsible(Links) or Responsible(PlainText) or Responsible(Designation)",'rd-snackbar');
    return;
  }
  if(this.selectaccountableWithLinksFormControl.value =="" && this.selectaccountableWithPlainTextFormControl.value =="" && this.selectaccountableWithDesignationFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select Either Accountable(Links) or Accountable(PlainText) or Accountable(Designation)",'rd-snackbar');
    return;
  }
  if(this.selectconsultWithLinksFormControl.value =="" && this.selectconsultWithPlainTextFormControl.value =="" && this.selectconsultWithDesignationFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select Either Consult(Links) or Consult(PlainText) or Consult(Designation)",'rd-snackbar');
    return;
  }
  if(this.selectinformWithLinksFormControl.value =="" && this.selectinformWithPlainTextFormControl.value =="" && this.selectinformWithDesignationFormControl.value ==""){
    this.activityDataMapping.ShowSnackBar("Please Select Either Inform(Links) or Inform(PlainText) or Inform(Designation)",'rd-snackbar');
    return;
  }
  
    var busID=this.businessUnitId.toString()
    var activityID=this.activityId.toString()
    var sourceOfActivityID="0"
    var CATBATReferenceID="0"
    var ToolSystemPortalID="0"
    var applicableToBusinessID="0"
    var activityFormingPartID="0";
    var CPReferenceIDs="0";
    var MIReferenceIDs="0";
    var BRReferenceIDs="0";
    var remarksWithLinksIDs="0";
    var remarksWithPlainTextIDs="0";
    var responsibleWithLinksIDs="0";
    var responsibleWithPlainTextIDs="0";
    var responsibleWithDesignationIDs="0";
    var accountableWithLinksIDs="0";
    var accountableWithPlainTextIDs="0";
    var accountableWithDesignationIDs="0";
    var consultWithLinksIDs="0";
    var consultWithPlainTextIDs="0";
    var consultWithDesignationIDs="0";
    var informWithLinksIDs="0";
    var informWithPlainTextIDs="0";
    var informWithDesignationIDs="0";
    var MappingValues=[];
    var Remark="";


    if(this.selectedSourceOfActivityControl.value !=""){
      sourceOfActivityID= this.selectedSourceOfActivityControl.value;
      MappingValues.push(sourceOfActivityID);
    }
    if(this.selectedCatBatReferenceControl.value !=""){
      CATBATReferenceID= this.selectedCatBatReferenceControl.value;
      MappingValues.push(CATBATReferenceID);
    }
    if(this.selectedToolControl.value !=""){
      ToolSystemPortalID= this.selectedToolControl.value;
      MappingValues.push(ToolSystemPortalID);
    }
    if(this.selectedApplicableToBusinessControl.value !=""){
      applicableToBusinessID= this.selectedApplicableToBusinessControl.value;
      MappingValues.push(applicableToBusinessID);
    }
    if(this.selectedActivityFormingPartControl.value !=""){
      activityFormingPartID= this.selectedActivityFormingPartControl.value;
      MappingValues.push(activityFormingPartID);
    }
    if(this.selectCPReferenceFormControl.value !=""){
      CPReferenceIDs= this.selectCPReferenceFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(CPReferenceIDs);
    }
    if(this.selectMIReferenceFormControl.value !=""){
      MIReferenceIDs= this.selectMIReferenceFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(MIReferenceIDs);
    }
    if(this.selectBRReferenceFormControl.value !=""){
      BRReferenceIDs= this.selectBRReferenceFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(BRReferenceIDs);
    }
    /* if(this.selectremarkWithLinksFormControl.value !=""){
      remarksWithLinksIDs= this.selectremarkWithLinksFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(remarksWithLinksIDs);
    }
    if(this.selectremarkWithPlainTextFormControl.value !=""){
      remarksWithPlainTextIDs= this.selectremarkWithPlainTextFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(remarksWithPlainTextIDs);
    } */
      if(this.remarkText.value !="<p></p>" && this.remarkText.value !="")
      {
        MappingValues.push(this.remarkId.value);

      }


    if(this.selectresponsibleWithLinksFormControl.value !=""){
      responsibleWithLinksIDs= this.selectresponsibleWithLinksFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(responsibleWithLinksIDs);
    }
    if(this.selectresponsibleWithPlainTextFormControl.value !=""){
      responsibleWithPlainTextIDs= this.selectresponsibleWithPlainTextFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(responsibleWithPlainTextIDs);
    }
    if(this.selectresponsibleWithDesignationFormControl.value !=""){
      responsibleWithDesignationIDs= this.selectresponsibleWithDesignationFormControl.value.map(option=>option.id).join(', ')
     MappingValues.push(responsibleWithDesignationIDs);
    }
    if(this.selectaccountableWithLinksFormControl.value !=""){
      accountableWithLinksIDs= this.selectaccountableWithLinksFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(accountableWithLinksIDs);
    }
    if(this.selectaccountableWithPlainTextFormControl.value !=""){
      accountableWithPlainTextIDs= this.selectaccountableWithPlainTextFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(accountableWithPlainTextIDs);
    }
    if(this.selectaccountableWithDesignationFormControl.value !=""){
      accountableWithDesignationIDs= this.selectaccountableWithDesignationFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(accountableWithDesignationIDs);
    }
    if(this.selectconsultWithLinksFormControl.value !=""){
      consultWithLinksIDs= this.selectconsultWithLinksFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(consultWithLinksIDs);
    }
    if(this.selectconsultWithPlainTextFormControl.value !=""){
      consultWithPlainTextIDs= this.selectconsultWithPlainTextFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(consultWithPlainTextIDs);
    }
    if(this.selectconsultWithDesignationFormControl.value !=""){
      consultWithDesignationIDs= this.selectconsultWithDesignationFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(consultWithDesignationIDs);
    }
    if(this.selectinformWithLinksFormControl.value !=""){
      informWithLinksIDs= this.selectinformWithLinksFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(informWithLinksIDs);
    }
    if(this.selectinformWithPlainTextFormControl.value !=""){
      informWithPlainTextIDs= this.selectinformWithPlainTextFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(informWithPlainTextIDs);
    }
    if(this.selectinformWithDesignationFormControl.value !=""){
      informWithDesignationIDs= this.selectinformWithDesignationFormControl.value.map(option=>option.id).join(', ')
      MappingValues.push(informWithDesignationIDs);
    }
    //const activityMappingData = { busID: busID, activityID: activityID, MappingValues: MappingValues.join(",")};
    
    this.loadGrid(MappingValues);
/*     this.activityDataMapping.saveActivityTransactionData(activityMappingData).subscribe((data: any)=>{
      if(data !=""){
        this.activityDataMapping.ShowSnackBar("Data Submitted Successfully",'red-snackbar'); 
        this.loadGrid();
      
      }
    }); */
  }
}
