import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {  MasterService } from 'src/app/Services/master.service';
import * as XLSX from 'xlsx-js-style';


export interface DesignationMaster{
  id: number;
  code  : string;
  name  : string;
  description  : string;
  designationName  : string;
  designationId  : number;
  status : boolean;
  isActive : boolean;
  isMapped : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
}
declare interface BulkDesignationMaster{
  code  : string;
  name  : string;
  description  : string;
  status : boolean;
  isActive : boolean;
  createdBy : number; 
  createdDate : Date;
  updatedBy : number; 
  updatedDate : Date;
}
@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {

  isIdVisible: boolean = false;
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['code','name','description','status','action'];
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, private DesignationServ : MasterService) { }
  
  ngOnInit(): void {
    this.getDesignationMaster();
  }
  openDialogBox(){
    this.isIdVisible = false;
    const dialogRef = this.dialog.open(AddDesignationDialog,{
      height: '500px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getDesignationMaster();
        }
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  getDesignationMaster(){
    this.DesignationServ.getDesignationMaster().subscribe((data:any)=>{
      this.dataSource= new MatTableDataSource<DesignationMaster>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddDesignationDialog,{
      data,
    }) 
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if(val){
          this.getDesignationMaster();
        }
      }
    })
  }
  
  delete(id: any) {  
    console.log('dataaa',id)
    if (confirm("Are you sure you want to delete this")) {  
    this.DesignationServ.deleteDesignationMaster(id).subscribe(() => {  
      this.snackBarLoader('Deleted Successfully','Done','1000', 'red-snackbar')
      this.getDesignationMaster();
    });  
   }  
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  exportExcel(){
    const header_Style= {
      font:{
        bold: true,
        color:{rgb:"FFFFFF"},
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      },
      fill:{
        fgColor:{rgb:"ff000f"}
      }
    }
   const transformed_tabledata=  this.dataSource.filteredData.map(row=>{
      return {
        DesignationCode: row.code,
        DesignationName: row.designationName,
        Description: row.description,
        Status: row.status=== true? 'Active' : 'InActive'
      };
    });
    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformed_tabledata);
  var range = XLSX.utils.decode_range(workSheet['!ref']);
  for (var C = range.s.r; C <= range.e.c; ++C) {
    var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
    if (!workSheet[address]) continue;
    //workSheet[address].v = workSheet[address].v.toUpperCase();
  }
  const workbook: XLSX.WorkBook= XLSX.utils.book_new();
  workSheet['!cols']=[{width:30},{width:30},{width:30},{width:15}];
  
  for (var i in workSheet) {
   workSheet['A1'].s=header_Style;
  workSheet['B1'].s=header_Style;
  workSheet['C1'].s=header_Style;
  workSheet['D1'].s=header_Style;
    if (typeof workSheet[i] != 'object') continue;
      
      let cell = XLSX.utils.decode_cell(i);
    workSheet[i].s = {
      wch: 50,
      // styling for all cells
      font: {
        name: 'ABBvoice',
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: '1', // any truthy value here
      }
    }
  }
 
    XLSX.utils.book_append_sheet(workbook, workSheet, 'DesignationMST');
    XLSX.writeFile(workbook, 'DesignationMaster.xlsx');
  }
}




@Component ({
  selector: 'app-dialog-Designation',
  templateUrl: './addDesignationDialog.html',
  styleUrls: ['./designation.component.scss']
})

  export class AddDesignationDialog implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    createdByUserId : number;
    createdBy : number;
    createdDate : Date;
    isCodeDisabled :boolean =false;
    designationModel: DesignationMaster= {} as DesignationMaster;
    excelDesignationMasterData: any[]=[];
    bulkUpload:boolean=false;
    bulkDesignationMasterValuesToSend: DesignationMaster[] = [];
    designationModelBulkUpload: DesignationMaster= {} as DesignationMaster;
    isMapped: boolean=false;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddDesignationDialog>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private designationServ : MasterService) 
    {
      if(this.data !=null && this.data !=undefined)
      this.isCodeDisabled = true;
      this.formInstance = _formBuilder.group({
        // "id":0,
       "name": ['', Validators.required], 
       // "code":['', Validators.required],
        "code": [{value:'', disabled : this.isCodeDisabled}, Validators.required],
        "description": [''],
        "status": true
      });
    }

  ngOnInit(): void {
    this.designationModel.status = true;
    if(this.data !=null && this.data !=undefined)
    {
      this.isCodeDisabled = true;
      this.designationModel.id = this.data.designationId;
      this.designationModel.name = this.data.designationName;
      this.designationModel.code = this.data.code;
      this.designationModel.description = this.data.description;
      this.designationModel.status = this.data.status;
      this.createdBy = this.data.createdBy;
      this.createdDate = this.data.createdDate;
      this.isIdVisible = true;
      this.isMapped= this.data.isMapped;
    }
    var emailId = sessionStorage.getItem('LoginEmail');
    this.getUserId(emailId);
  }
 
  noSpecialCharacters(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/;
      if (specialCharacters.test(control.value)) {
        return { 'specialCharacters': true };
      }
      return null;
    };
  }
  closepopup(){
    if(confirm("Are you sure to close this")) {
      this.dialogRef.close();
    }
  }
  save(){
    if (this.formInstance.invalid) {
      return;
    }
    this.designationModel.createdBy = this.createdByUserId;
    this.designationServ.saveDesignationData(this.designationModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  

  update(){
    if (this.formInstance.invalid) {
      return;
    }
    this.designationModel.updatedBy = this.createdByUserId;
    this.designationModel.createdBy = this.createdBy;
    this.designationModel.createdDate = this.createdDate;
    this.designationModel.isActive = true;
    console.log('the',this.designationModel)
    this.designationServ.saveDesignationData(this.designationModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
       // this.getBusinessLineType();
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  resetForm() {  
    this.formInstance.reset(); 
    this.designationModel.id = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
  
  getUserId(emailId : string){
    this.designationServ.getUserId(emailId).subscribe((data:any)=>{
      this.createdByUserId = data[0].id;
    });
  }

  customErrorStateMatcher: ErrorStateMatcher = {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }
  onCheckboxChange(event)
  {
    if(event.checked==true){
      this.bulkUpload=true;
    }
    else{
      this.bulkUpload=false;
    }
  }
  exportExcel(){
    this.designationServ.downloadExcel().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'DesignationMasterSample.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => {
      console.error('Download error:', error);
    });
  }
  onFileChange(event: any) {
    this.excelDesignationMasterData=[];
    const target: DataTransfer = <DataTransfer>(event.target);
    const file: File= event.target.files[0];
    
    if(file){
      const fileSizeInBytes= file.size;
      const fileSizeInKB= fileSizeInBytes/1024;
      const fileSizeInMB= fileSizeInKB/1024;
      console.log(fileSizeInMB);
      if(fileSizeInMB > 1){
        this.snackBarLoader("Uploaded file size exceeded",'Done','1000', 'red-snackbar');
        const inputElements= document.querySelectorAll('input[type="file"]');
      inputElements.forEach((element:any)=>{
      element.value='';
    })
      }
    }
    
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
 
    const reader: FileReader = new FileReader();
    
    reader.onload = (e: any) => {
      const binaryStr: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryStr, { type: 'binary' });
 
      const firstSheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[firstSheetName];
      this.excelDesignationMasterData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); 
    };
    
    reader.readAsBinaryString(target.files[0]);
  }
  cancelForm(){
    const inputElements= document.querySelectorAll('input[type="file"]');
      inputElements.forEach((element:any)=>{
      element.value='';
      });
    this.dialogRef.close(true);
  }
  uploadDesignationMaster(){
    this.bulkDesignationMasterValuesToSend = [];
    if(this.excelDesignationMasterData.length ==0){
      this.snackBarLoader("Please Choose file",'Done','1000', 'red-snackbar');
    }
    else if(this.excelDesignationMasterData[0][0] !='DesignationCode' && this.excelDesignationMasterData[0][1]!='DesignationName' && this.excelDesignationMasterData[0][2]!='Description'){
      this.snackBarLoader("Please Upload Correct format. Refer sample by clicking the download icon",'Done','1000', 'red-snackbar')
    }
    else if(this.excelDesignationMasterData.length ==1){
      this.snackBarLoader("Please Upload Valid file with Data",'Done','1000', 'red-snackbar');
    }
    else{
      this.excelDesignationMasterData.shift();
      console.log(this.excelDesignationMasterData);
      for(let b=0;b< this.excelDesignationMasterData.length; b++){
      this.designationModelBulkUpload.code= this.excelDesignationMasterData[b][0];
      this.designationModelBulkUpload.name= this.excelDesignationMasterData[b][1];
      this.designationModelBulkUpload.description=this.excelDesignationMasterData[b][2];
      this.designationModelBulkUpload.status=true;
      this.designationModelBulkUpload.createdBy = this.createdByUserId;
      this.designationModel.createdDate = this.createdDate;
      this.designationModelBulkUpload.updatedBy = null;
      this.designationModelBulkUpload.updatedDate =null;
      this.designationModelBulkUpload.isActive = true;
      this.bulkDesignationMasterValuesToSend.push({
        id:0,
        code: this.designationModelBulkUpload.code,
        name: this.designationModelBulkUpload.name,
        description: this.designationModelBulkUpload.description,
        designationName:'',
        designationId:0,
        status: this.designationModelBulkUpload.status,
        createdBy: this.designationModelBulkUpload.createdBy,
        createdDate: new Date(),
        updatedBy: this.designationModelBulkUpload.updatedBy,
        updatedDate: this.designationModelBulkUpload.updatedDate,
        isActive: this.designationModelBulkUpload.isActive,
        isMapped : false
      });
     }
     this.designationServ.SaveDesignationMasterBulkData(this.bulkDesignationMasterValuesToSend).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })  
    }
  }
  }