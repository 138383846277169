import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { UserRoleMapService } from 'src/app/Services/user-role-map.service';

export interface UserRoleMappingModel{
  userId: number;
  firstName: string;
  lastName: string;
  email : string;
  mobile : string;
  isActive : boolean;
}


@Component({
  selector: 'app-user-role-mapping',
  templateUrl: './user-role-mapping.component.html',
  styleUrls: ['./user-role-mapping.component.scss']
})

export class UserRoleMappingComponent implements OnInit {
  
  isIdVisible: boolean = false;
  dataSource : any;
  show: boolean = true;
  displayedColumns: string[] = ['firstName','lastName','email','isActive','action'];
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private snackBar: MatSnackBar,public dialog: MatDialog,
              public userRoleServ: UserRoleMapService) {}

  ngOnInit(): void {
   this.getAllUserDetails();
  }

  // retrieve(){
  //   let data=this.authService.instance.getAllAccounts();
  //   console.log('da',data);
    // console.log('this.formInstance.value.email',this.formInstance.value.email)
    // this.userRoleServ.getUserDetails(this.formInstance.value.email).subscribe((data:any)=>{
    //  this.userRoleMappingModel = data[0];
    //  console.log('this.userRoleMappingModel',this.userRoleMappingModel)
    // });
 // }

 openDialogBox(){
  this.isIdVisible = false;
  const dialogRef = this.dialog.open(AddUserRoleMappingDialogComponent,{
    height: '500px',
    width: '550px'
  });
  dialogRef.afterClosed().subscribe({
    next: (val) => {
      if(val){
        this.getAllUserDetails();
      }
    }
  })
}

snackBarLoader(content, action, duration, color){
  this.snackBar.open(content, action, {
    duration: 2000,
    verticalPosition: "top", 
    horizontalPosition: "center", 
    panelClass: [color]
  });
}

  loadResourceToEdit(data : any){
    this.isIdVisible = true;
    const dialogRef = this.dialog.open(AddUserRoleMappingDialogComponent,{
     data,
   }) 
   dialogRef.afterClosed().subscribe({
     next: (val) => {
       if(val){
         this.getAllUserDetails();
       }
     }
   })
  }

  getAllUserDetails(){
    this.userRoleServ.getAllUserDetails().subscribe((data:any)=>{
      console.log('dataaa',data)
      this.dataSource= new MatTableDataSource<UserRoleMappingModel>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}



@Component ({
  selector: 'app-dialog-userRole',
  templateUrl: './add-user-role-mapping-dialog.html',
  styleUrls: ['./user-role-mapping.component.scss']
})

  export class AddUserRoleMappingDialogComponent implements OnInit {
    formInstance: FormGroup;
    isIdVisible :boolean =false;
    ischecked :boolean =true;
    isEditable: boolean = false;
    isDisabled: boolean = true;
    isEmailDisabled: boolean = false;
    userRoleMappingModel: UserRoleMappingModel= {} as UserRoleMappingModel;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddUserRoleMappingDialogComponent>,
    private snackBar: MatSnackBar,private _formBuilder: FormBuilder, private userRoleServ: UserRoleMapService,
    private authService: MsalService) 
    {
      if(this.data !=null && this.data !=undefined)
      this.isEmailDisabled = true;
      this.formInstance = _formBuilder.group({
        "userId": 0,
        "email": [{value: '', disabled: this.isEmailDisabled}, Validators.required], 
        "firstName": [{value: '', disabled: true}],
        "lastName": [{value: '', disabled: true}],
        "isActive": true,
        "mobile": '', 
      });
    }

  ngOnInit(): void {
    console.log('this.data',this.data)
    if(this.data !=null && this.data !=undefined)
    {
      console.log('this.data',this.data)
      this.isDisabled = false;
      this.isEditable = true;
      this.userRoleMappingModel.userId = this.data.userId;
      this.userRoleMappingModel.firstName = this.data.firstName;
      this.userRoleMappingModel.lastName = this.data.lastName;
      this.userRoleMappingModel.email = this.data.email;
      this.userRoleMappingModel.mobile = this.data.mobile;
      this.userRoleMappingModel.isActive = this.data.isActive;
      this.isIdVisible = true
    }
    var emailId = sessionStorage.getItem('LoginEmail');
  }

  retrieve() {
    if(this.formInstance.value.email==undefined){
      this.snackBarLoader('Please enter user email', 'Error', 3000, 'red-snackbar');
      return
    }
    if(this.formInstance.value.email.trim()==''){
      this.snackBarLoader('Please enter user email', 'Error', 3000, 'red-snackbar');
      return
    }
    this.isEditable = true;
    this.userRoleServ.getUserDetailsByEmail(this.formInstance.value.email).subscribe({
      next: (data: any) => {

        console.log(data);

        if (data) {

          this.userRoleMappingModel = data;

        } else {

          this.snackBarLoader('Data not found', 'Error', 3000, 'red-snackbar');

        }

      },

      error: (err: any) => {

        console.log(err);
    
        this.snackBarLoader(err.error.text, 'Error', 3000, 'red-snackbar');

        this.isEditable = false;

      }
    });
  }

  
  snackBarLoader(content, action, duration, color){
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", 
      horizontalPosition: "center", 
      panelClass: [color]
    });
  }
 
  closepopup(){
    this.dialogRef.close();
  }

  save(){
    if (this.formInstance.invalid) {
      return;
    }
    //this.divisionModel.createdBy = this.createdByUserId;
    this.userRoleServ.save(this.userRoleMappingModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Submitted Successfully','Done','1000', 'red-snackbar')
        //this.resetForm();
        this.dialogRef.close(true);
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    })
  }
  
  update(){
    console.log('this.userRoleMappingModel',this.userRoleMappingModel)
    console.log('this.formInstance.invalid',this.formInstance.value)
    // if (this.formInstance.value.firstName == '' &&this.formInstance.value.lastName == '' &&
    //     this.formInstance.value.mobile == '' && this.formInstance.value.userId == '' ) {
    //   return;
    // }
    console.log('this.userRoleMappingModel',this.userRoleMappingModel)
    this.userRoleServ.save(this.userRoleMappingModel).subscribe({
      next: (val :any) => {
        this.snackBarLoader('Updated Successfully','Done','1000', 'red-snackbar')
        this.resetForm();
        this.dialogRef.close(true);
      },
      error: (err: any) =>{
        this.snackBarLoader(err.error,'Done','1000', 'red-snackbar')
        this.resetForm();
      }
    }) 
      
  }

  
  resetForm() {  
    this.isEditable = false;
    this.formInstance.reset(); 
    this.userRoleMappingModel.userId = 0;
    Object.keys(this.formInstance.controls).forEach(key => {
      this.formInstance.get(key).setErrors(null) ;
    });
    this.isIdVisible = false;
    this.dialogRef.close(true);
  }
}