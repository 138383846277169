
import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Injectable, OnInit } from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../Services/data.service';
//import { SearchService } from  "src/app/Services/activity-master.service";
import { ActivityMasterService } from "src/app/Services/activity-master.service";
interface FoodNodeFlat {
  text: string;
  id: Number;
  parentId: Number;
  children?: FoodNodeFlat[];
 }
 /* const TREE_DATA: FoodNodeFlat[] = [
  { name: 'Fruit', Id: 1, parentId:null },
  { name: 'Apple', Id: 2, parentId: 1 },
  { name: 'Banana', Id: 3, parentId: 1 },
  { name: 'Fruit loops', Id: 4, parentId: 1 }, 
  { name: 'Vegetables', Id: 5, parentId:null },
  { name: 'Green', Id: 6, parentId: 5 },
  { name: 'Broccoli', Id: 7, parentId: 6 },
  { name: 'Brussels sprouts', Id: 8, parentId: 6 }, 
  { name: 'Orange', Id: 9, parentId: 5 },
  { name: 'Pumpkins', Id: 10, parentId: 9 },
  { name: 'Carrots', Id: 11, parentId: 9 },
  { name: 'India', Id: 12, parentId: null },
  { name: 'Maharashtra', Id: 13, parentId: 12 },
  { name: 'Mumbai', Id: 14, parentId: 13 },
  { name: 'Karnataka', Id: 15, parentId: 12 },
  { name: 'Bangalore', Id: 16, parentId: 15 },
  { name: 'Satara', Id: 17, parentId: 14 },
  { name: 'Karad', Id: 18, parentId: 17 },] */
 
 interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
 }
 @Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
 export class TreeviewComponent implements OnInit {

   

  


 private _transformer = (node: FoodNodeFlat , level: number) => {
  return {
  expandable: !!node.children && node.children.length > 0,
  name: node.text,
  id:node.id,
  level: level,
  };
  }
 treeControl = new FlatTreeControl<ExampleFlatNode>(
  node => node.level, node => node.expandable);
 treeFlattener = new MatTreeFlattener(
  this._transformer, node => node.level, node => node.expandable, node => node.children);
 dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

 

 constructor( public dialog: MatDialog,private dataService: DataService, private searchserv: ActivityMasterService) {
  //this.dataSource.data = TREE_DATA;
  }



 hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
 ngOnInit() {
  //this.dataSource.data =this.treeConstruct(TREE_DATA);
  this.searchserv.getActivityList(1).subscribe((data: any[])=>{
    console.log(data);
    this.dataSource.data  = this.treeConstruct(data);
    
  })  



  }


//constructTree recursively iterates through the tree to create nested tree structure.
  //We only need Id and parentId Columns in the flat data to construct this tree properly.
  treeConstruct(treeData) {
    let constructedTree = [];
    for (let i of treeData) {
      let treeObj = i;
      let assigned = false;
      this.constructTree(constructedTree, treeObj, assigned)
    }
    return constructedTree;
  }
constructTree(constructedTree, treeObj, assigned) {
if (treeObj.parentId == null) {
      treeObj.children = [];
      constructedTree.push(treeObj);
      return true;
    } else if (treeObj.parentId == constructedTree.id) {
      treeObj.children = [];
      constructedTree.children.push(treeObj);
      return true;
    }
    else {
      if (constructedTree.children != undefined) {
        for (let index = 0; index < constructedTree.children.length; index++) {
          let constructedObj = constructedTree.children[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedTree.length; index++) {
          let constructedObj = constructedTree[index];
          if (assigned == false) {
            assigned = this.constructTree(constructedObj, treeObj, assigned);
          }
        }
      }
      return false;
    }
  }

  openPopupDialog(node: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '1000px',
      data:  node.id 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        node.id = result;
      }
    });
  }


 } 






