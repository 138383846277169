<mat-card>
    <div > 
        <h4 class="col-md-8 Head" style="padding: 0px 0px 20px 20px;font-size: 30px;">
          Activity Data Master</h4>
      </div>
      <div>

     
      <form class="ex-form" [formGroup]="formInstance">
        <div   class="content">
            <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12">
                <mat-form-field appearance="outline">
                 <mat-label style="font-family: ABBvoice;">Select Business Area</mat-label>
                <mat-select #select required style=" font-family: ABBvoice;" [formControl]="selectedBusinessAreaControl">
                <mat-option *ngFor="let business of businessList" [value]="business.id" (click)="businessAreaSelection()">
                      {{business.name}}
                </mat-option>
                </mat-select>
                       
                </mat-form-field>   
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12">
                    <mat-form-field appearance="outline">
                     <mat-label style="font-family: ABBvoice;">Select Category</mat-label>
                    <mat-select #select required style=" font-family: ABBvoice;" [formControl]="selectedCategoryControl">
                    <mat-option *ngFor="let masterData of masterDataList" [value]="masterData" (click)="categorySelection()">
                          {{masterData.name}}
                    </mat-option>
                    </mat-select>
                           
                    </mat-form-field>   
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12">
                      <button mat-raised-button class="buttonABB" color="primary" style="margin-right:10px" (click)="retrieve()">Retrieve</button> 
                      </div>  
            </div>
        </div>
      </form>
    </div>
</mat-card>
<mat-card>
    <div class="row">
        <div class="col-md-3 col-lg-3 col-sm-12">
          <div mat-dialog-actions >
              <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Search</mat-label>
                <input style="font-family: ABBvoice;" matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
              </mat-form-field>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12">
          <button mat-raised-button class="buttonABB" *ngIf="showAddButton" color="primary" style="margin-right:10px" (click)="openDialogBox_CPMIBRReference()">Add</button> 
        </div>
      </div>
      <div class="row"> 
        <div class="table-responsive">   
            <table mat-table [dataSource]="dataSource_ActivityData" class="mat-elevation-z8"  class="table tblabb" #empTbSort="matSort" matSort style="margin: 0px auto;">
      
                <ng-container matColumnDef="BusinessUnitName">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header="businessUnitName"  > BusinessUnit </th>
                  <td class="transactionBody" mat-cell *matCellDef="let element"> {{element.businessUnitName}} </td>
                </ng-container>
                <ng-container matColumnDef="CategoryName">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="categoryName"  > CategoryName </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element"> {{element.categoryName}} </td>
                </ng-container>
                <ng-container matColumnDef="IsLink">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="isLink"  > IsLink</th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element">  {{element.isLink}}</td>   
                </ng-container>
                <ng-container matColumnDef="RefText">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="refText"  > RefText </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element" [innerHTML]="element.refText">  </td>  
                </ng-container>
                
                <ng-container matColumnDef="RefLink"  >
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="refLink"  > RefLink </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element">  {{element.refLink}} </td>
                </ng-container>
                <ng-container matColumnDef="IsDesignation">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="isDesignation"  > IsDesignation </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element"> {{element.isDesignation}} </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="status"  > Status </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>
                 <!--<ng-container matColumnDef="DesignationName" >
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef   mat-sort-header="designationName"> DesignationName </th>
                  <td class="transactionBody"  mat-cell *matCellDef="let element" style="text-align: center"> {{element.designationName}} </td>
                </ng-container> -->
                <ng-container matColumnDef="action" >
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef mat-sort-header  >Action</th>
                  <td class="transactionBody" mat-cell *matCellDef="let element; let i = index" style="width: 10%;">
                    <button mat-icon-button >
                      <mat-icon mat-icon-button color='primary' (click)="loadResourceToEdit(element)" >edit</mat-icon>
                    </button>
                    <button mat-icon-button >
                      <mat-icon mat-icon-button color="warn" *ngIf='element.isShowDelete' (click)="delete(element)">delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="spacerColumn">
                  <th class="transactionHeader" mat-header-cell *matHeaderCellDef></th>
                  <td class="transactionBody" mat-cell *matCellDef="let element" class="column-spacer"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns_ActivityData"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns_ActivityData"></tr>
          
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Please select the filter for data. </td>
                </tr>
             </table> 
         </div>
         <mat-paginator style="font-family: ABBvoice;" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" aria-label="Select page of Activity Data"></mat-paginator>
      </div>
</mat-card>
