<p>popup works!</p>
<h1 mat-dialog-title>Edit Node</h1>
<div  >
  <mat-form-field>
    <input matInput [(ngModel)]="data.id" placeholder="Node Name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button style="font-family: ABBvoice;" (click)="closePopup()">Cancel</button>
  <button mat-button style="font-family: ABBvoice;" [mat-dialog-close]="data.id" cdkFocusInitial>Save</button>
</div>