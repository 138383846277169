<div class="row">
  <div class="col-10">
    <h4 class="Head" style="font-size: large; font-family: ABBvoice;">{{categoryName}}</h4>
  </div>
  
  <div class="col-2" style="text-align: right;">
    <mat-icon (click)="close()" class="closeBtn" matTooltip="Close" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </div>



    <div class="row" style="margin-top: 1%;" *ngIf="isCPMIBRReference">
        
            <div class="col-md-6 col-lg-6 col-sm-12" style="padding-top: 12px;">
                <mat-form-field appearance="outline">
                    <mat-label style="font-family: ABBvoice;">Enter RefText</mat-label>
                    <input style="font-family: ABBvoice;" matInput placeholder="" required [(ngModel)]="refText">
                    <mat-error>Value is mandatory</mat-error>
                  </mat-form-field>
                </div>
            <div class="col-md-12 col-lg-12 col-sm-12" style="padding-top: 12px;">
                <mat-form-field class="example-full-width" appearance="outline" style="display: block;">
                    <mat-label>Enter Link</mat-label>
                    <textarea matInput placeholder="" required [(ngModel)]="refLink" required></textarea>
                    <mat-error>Value is mandatory</mat-error>
                  </mat-form-field>
                </div>
        
    
        </div>
    <div class="row" style="margin-top: 1%;" *ngIf="isRACI">
      <div>
        <div class="col-md-12 col-lg-12 col-sm-12">
          <label style="font-family: ABBvoice;">Choose below:  </label>
          <mat-radio-group [(ngModel)]="radiobutton" (change)="isLinkRadioChange_RACI()" aria-label="Select an option" [disabled]=!disableRadioButton>
            <mat-radio-button value="isLink" style="font-family: ABBvoice;">Is Link</mat-radio-button>&nbsp;
            <mat-radio-button value="isPlainText" style="font-family: ABBvoice;">Is Plaintext</mat-radio-button>&nbsp;
            <mat-radio-button value="isDesignation" style="font-family: ABBvoice;">Is Designation</mat-radio-button>
          </mat-radio-group>
      </div>
      </div>
      <div>
        <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="enableLink" style="padding-top: 12px;">
          <mat-form-field appearance="outline">
              <mat-label style="font-family: ABBvoice;">Enter RefText</mat-label>
              <input style="font-family: ABBvoice;" matInput placeholder="" [(ngModel)]="refText">
            </mat-form-field>
          </div>
      </div>
        <div>
          <div class="col-md-12 col-lg-12 col-sm-12" *ngIf="enableLink" style="padding-top: 12px;">
            <mat-form-field class="example-full-width" appearance="outline" style="display: block;">
                <mat-label>Enter Link</mat-label>
                <textarea matInput placeholder="" [(ngModel)]="refLink"></textarea>
              </mat-form-field>
            </div>
        </div>
          
      
      <div>
        <div class="col-md-12 col-lg-12 col-sm-12" *ngIf="enablePlainText" style="padding-top: 12px;">
          <mat-form-field class="example-full-width" appearance="outline" style="display: block;">
              <mat-label>Enter Plaintext</mat-label>
              <textarea matInput placeholder="" [(ngModel)]="plainText"></textarea>
            </mat-form-field>
          </div>
      </div>
      <div *ngIf="isSave">
        <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="enableDesignation" style="padding-top: 12px;">
          <mat-form-field appearance="outline">
            <mat-label  style="font-family: ABBvoice;">Select Designation</mat-label>
            <mat-select style=" font-family: ABBvoice;" #selectDesignation (openedDesignationChange)="openedDesignationChange($event)" placeholder="Select Designation" [formControl]="selectDesignationFormControl" multiple>
              <mat-select-trigger>
                {{selectedDesignationValue.value? selectedDesignationValue.value.join(', '): ''}}
                            </mat-select-trigger>
              <div class="select-container">
              <mat-optgroup >
            <mat-form-field style="width:100%;">
              <input #searchDesignation autocomplete="off" placeholder="Type to Search..." aria-label="Search" matInput [formControl]="searchDesignationTextboxControl">
              <button [disableRipple]="true" *ngIf="searchDesignation.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearDesignationSearch($event)">
              <mat-icon >close</mat-icon>
            </button>
                   </mat-form-field>
              </mat-optgroup>
              <mat-optgroup *ngIf="(designationFilteredOptions | async).length == 0">
                <div>No results found!</div>
              </mat-optgroup>
              <mat-checkbox class="select-all" color="primary" [(ngModel)]="allSelectedDesignation" [ngModelOptions]="{ standalone: true }"
                 (change)="toggleAllSelectionDesignation()">Select All</mat-checkbox>
           <mat-option (onSelectionChange)="designationSelectionChange($event)" *ngFor="let designation of designationFilteredOptions | async" [value]="designation" (click)="designationSelection()">
                  {{designation.name}}
                </mat-option>
          </div>
          </mat-select>
          </mat-form-field>        
      </div>
      </div>
      <div *ngIf="!isSave">
        <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="enableDesignation" style="padding-top: 12px;">
          <mat-form-field appearance="outline">
           <mat-label style="font-family: ABBvoice;">Select Designation</mat-label>
          <mat-select #select required style=" font-family: ABBvoice;" [(ngModel)]='designationName' [disabled]="!isSave">
          <mat-option *ngFor="let designation of designationList"  [value]="designation.name" (click)="designationSelectionSave(designation.id, designation.name)" >
                {{designation.name}}
          </mat-option>
          </mat-select>
                 
          </mat-form-field>   
          </div>
      </div>
                </div>
    <div class="row" style="margin-top: 1%;" *ngIf="isRemark">
                    
                         <!-- <div style="display: none;">
                          <div class="col-md-12 col-lg-12 col-sm-12" >
                            <label style="font-family: ABBvoice;">Choose below:  </label>
                            <mat-radio-group [(ngModel)]="radiobutton" (change)="isLinkRadioChange_Remarks()" aria-label="Select an option" [disabled]=!disableRadioButton>
                              <mat-radio-button value="isLink" style="font-family: ABBvoice;">Is Link</mat-radio-button>&nbsp;
                              <mat-radio-button value="isPlainText" style="font-family: ABBvoice;">Is Plaintext</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        </div> -->
                       <!-- <div></div> 
                        <div>
                          <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="enableLink" style="padding-top: 12px;">
                            <mat-form-field appearance="outline">
                                <mat-label style="font-family: ABBvoice;">Enter RefText</mat-label>
                                <input style="font-family: ABBvoice;" matInput placeholder="" [(ngModel)]="refText">
                              </mat-form-field>
                            </div>
                        </div>
                        <div>
                          
                      <div class="col-md-12 col-lg-12 col-sm-12" *ngIf="enableLink" style="padding-top: 12px;">
                          <mat-form-field class="example-full-width" appearance="outline" style="display: block;">
                              <mat-label>Enter Link</mat-label>
                              <textarea #link matInput placeholder="" [(ngModel)]="refLink"></textarea>
                              
                            </mat-form-field>
                          </div>
                        </div>
                        <div>
                          <div class="col-md-12 col-lg-12 col-sm-12" *ngIf="enablePlainText" style="padding-top: 12px;">
                            <mat-form-field class="example-full-width" appearance="outline" style="display: block;">
                                <mat-label>Enter Plaintext</mat-label>
                                <textarea matInput placeholder="" [(ngModel)]="plainText"></textarea>
                              </mat-form-field>
                        </div>
                        </div>
                        -->

                        <div class="col-md-12 col-lg-12 col-sm-12"  style="padding-top: 12px;">
                       <!--    <label for="topic" class="Head1" style="font-family: ABBvoice;">Topic *</label> -->
                          <ngx-editor-menu [editor]="remarksEditor" [toolbar]="toolbar"></ngx-editor-menu>
                          <ngx-editor [editor]="remarksEditor" [(ngModel)]="plainText" 
                               ></ngx-editor>
                          <!--     <div *ngIf="((formInstance.get('plainText').invalid && !formInstance.get('plainText').value )|| (this.plainText=='<p></p>')) && 
                            (formInstance.get('plainText').dirty && formInstance.get('plainText').touched || isSubmitted)"
                            class="text-danger">
                                  Topic is required.
                              </div> -->
                        </div>

    </div>
    <div class="row" style="margin-top: 1%;" *ngIf="isOther">
        <div class="col-md-6 col-lg-6 col-sm-12">
            <mat-form-field appearance="outline">
                <mat-label style="font-family: ABBvoice;">Enter RefText</mat-label>
                <input style="font-family: ABBvoice;" matInput placeholder="" [(ngModel)]="refText">
              </mat-form-field>
            </div>
</div>   
<div class="row" style="margin-top: 5%;">
  <div class="col-md-6 col-lg-6 col-sm-12">
    <mat-label style="font-family: ABBvoice;">Active : </mat-label>&nbsp;
    <mat-checkbox [checked]="statusCheck" (change)="onStatusCheckboxChange($event.checked)">
    </mat-checkbox>
  </div>
</div> 
        <div class="row"  mat-dialog-actions align="center" class="action">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <button mat-raised-button class="buttonABB" *ngIf="isSave" color="primary" style="margin-right:12px" 
      (click)="save()">Save</button>
    <button mat-raised-button class="buttonABB" *ngIf="!isSave" color="primary" style="margin-right:12px" 
     (click)="save()">Update</button>
    <button mat-raised-button type="button" class="buttonABB" (click)="cancel()" *ngIf="showReset">Reset</button>
    
                </div>  
        </div>
      </div>