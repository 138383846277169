<div class="row">
  <div class="col-10">
    <h2 class="Head1">Process Flow</h2>
  </div>
  <div class="col-2" style="text-align: right;"> 
    <mat-icon (click)="close()" class="closeBtn" matTooltip="Close" aria-label="Example icon-button with a heart icon">close</mat-icon>
  </div> 
</div> 
  <div class="container1">
    <img
      src="./assets/documents/SPM_Flow_Diagram.png"
      style="max-width:100%;cursor:pointer"
      class="modal-hover-opacity"
      data-toggle="modal" data-target="#myModal"
    />
  </div>
