import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import{BehaviorSubject} from  'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService{
  private dataSubject= new BehaviorSubject<any>(null);
  data$= this.dataSubject.asObservable();

  setData(data:any):void{
      this.dataSubject.next(data);
  }
  resetData(){
      this.dataSubject.next("");
  }
}
