
<mat-card>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      {{node.name}}
      <button mat-icon-button (click)="openPopupDialog(node)">
        <mat-icon>Edit</mat-icon>
      </button>
      <!-- <button mat-icon-button (click)="addNewItem(node)">
        <mat-icon>add</mat-icon>
      </button> -->
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button
              [attr.aria-label]="'Toggle ' + node.text" matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      {{node.name}}
      <mat-progress-bar *ngIf="node.isLoading"
                        mode="indeterminate"
                        class="example-tree-progress-bar"></mat-progress-bar>
                                            

                        <button mat-icon-button (click)="openPopupDialog(node)">
                          <mat-icon>Edit</mat-icon>
                        </button>
                       <!--  <button mat-icon-button (click)="addNewItem(node)">
                          <mat-icon>add</mat-icon>
                        </button> -->
    </mat-tree-node>


<!--     <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon>{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
        </button>
        
        <span *ngIf="isLastChild(node)" (click)="openPopupDialog(node)">{{ node.item }}</span>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node> -->
  </mat-tree>
</mat-card>