import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataMappingService {
  rooturl= environment.rooturl ;
  // rooturl = 'https://localhost:7063/';  
   //rooturl = 'https://dev.table-of-authority.in.abb.com/api/';  
   // rooturl = 'https://qa.table-of-authority.in.abb.com/api/'; 
    // rooturl = 'https://stage.table-of-authority.in.abb.com/api/';  
    
  constructor(private http: HttpClient, private _snackBar:MatSnackBar) { }
  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
   
 }
  gettoken(userName:any,password:any){
    var data = JSON.stringify ( {
      "username": userName,
      "password": password
    });
    
    console.log(data);
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
   
    return this.http.post(this.rooturl + 'api/Account', data, { headers: reqHeader });
  }
  getActivityDetailsForMapping(myData :any): Observable<any> {
  
    return this.http.post(this.rooturl + 'api/ActivityDataMapping/GetActivityDetailsForMapping',myData);
     
  } 
  getCategoryWithActivityMasterData(Buid, ActivityId, name, isLink, isDesignation): Observable<any> {
    var headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' +  sessionStorage.getItem('userToken'));

    return this.http.get(this.rooturl + 'api/ActivityDataMapping/GetCategoryWithActivityMasterData?Buid='+Buid+"&ActivityId="+ActivityId+"&name="+name+ "&isLink="+isLink + "&isDesignation=" + isDesignation, {headers: headers});
  }

  saveActivityTransactionData(activityMappingData :any): Observable<any> {
  
    return this.http.post(this.rooturl + 'api/ActivityDataMapping/SaveActivityTransactionData',activityMappingData);
     
  } 
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ShowSnackBar(data: any,color: any)
  {
    this._snackBar.open(data,'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2 * 1000,
      panelClass: [color]
    });
  }
}
